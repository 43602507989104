import { useState } from "react";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useSelector } from "react-redux";
import { AddressData } from "../constant/address";
import { Form, Input } from "antd";

/**
 * Address component displays user's address information in the profile section.
 * Currently read-only as the address update API is not yet available.'*
 * State:
 * - isEditing: boolean - Controls the view/edit mode
 *
 * Dependencies:
 * - Requires AddressData from "../constant/address"
 * - Uses Redux auth state for user data
 *
 * Note: Edit functionality is currently implemented but non-functional
 * due to pending API implementation.
 */

export const Address = () => {
  const { user } = useSelector((state) => state.auth);
  const { translate } = useDictionary();
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className="bg-cardsBackground rounded-2xl p-5 border border-cardsStroke mt-4">
      <div className="flex justify-between">
        <p className="text-primary sub-heading">
          {translate("profile.address.title")}
        </p>
        {!isEditing && (
          <ThemeButton type={"edit"} onClick={() => setIsEditing(true)}>
            {translate("profile.main.edit")}
          </ThemeButton>
        )}
      </div>
      {!isEditing && (
        <div className="textfield text-cardsHeaders grid grid-cols-2 gap-y-7 mt-4">
          <div>
            <p>{translate("profile.address.country")}</p>
            <p className="pt-1">{AddressData.country}</p>
          </div>
          <div>
            <p>{translate("profile.address.city")}</p>
            <p className="pt-1">{AddressData.city}</p>
          </div>
          <div>
            <p>{translate("profile.address.zip")}</p>
            <p className="pt-1">{AddressData.zip}</p>
          </div>
        </div>
      )}

      {isEditing && (
        <Form
          layout="vertical"
          className="w-full mt-10 grid grid-cols-2 gap-5 textfield relative"
          onFinish={() => {}}
          initialValues={AddressData}
        >
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.address.country")}
              </p>
            }
            name="country"
            rules={[
              {
                required: true,
                message: translate("profile.errors.country"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.address.country")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.address.city")}
              </p>
            }
            name="city"
            rules={[
              {
                required: true,
                message: translate("profile.errors.city"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.address.city")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <p className="text-primary">{translate("profile.address.zip")}</p>
            }
            name="zip"
            rules={[
              {
                required: true,
                message: translate("profile.errors.zip"),
              },
              {
                pattern: /^[0-9]*$/,
                message: translate("profile.errors.zipvalid"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.address.zip")}
              className="input"
            />
          </Form.Item>
          <Form.Item className="col-span-2 mt-2 absolute right-0 -top-[80px]">
            <ThemeButton type={"active"} onClick={() => setIsEditing(false)}>
              {translate("profile.main.save")}
            </ThemeButton>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
