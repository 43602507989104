export const DashboardIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Dashboard Icon"
    >
      <path
        className={color}
        d="M2 8C1.45 8 0.979167 7.80417 0.5875 7.4125C0.195833 7.02083 0 6.55 0 6V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H6C6.55 0 7.02083 0.195833 7.4125 0.5875C7.80417 0.979167 8 1.45 8 2V6C8 6.55 7.80417 7.02083 7.4125 7.4125C7.02083 7.80417 6.55 8 6 8H2ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V12C0 11.45 0.195833 10.9792 0.5875 10.5875C0.979167 10.1958 1.45 10 2 10H6C6.55 10 7.02083 10.1958 7.4125 10.5875C7.80417 10.9792 8 11.45 8 12V16C8 16.55 7.80417 17.0208 7.4125 17.4125C7.02083 17.8042 6.55 18 6 18H2ZM12 8C11.45 8 10.9792 7.80417 10.5875 7.4125C10.1958 7.02083 10 6.55 10 6V2C10 1.45 10.1958 0.979167 10.5875 0.5875C10.9792 0.195833 11.45 0 12 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V6C18 6.55 17.8042 7.02083 17.4125 7.4125C17.0208 7.80417 16.55 8 16 8H12ZM12 18C11.45 18 10.9792 17.8042 10.5875 17.4125C10.1958 17.0208 10 16.55 10 16V12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10H16C16.55 10 17.0208 10.1958 17.4125 10.5875C17.8042 10.9792 18 11.45 18 12V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H12ZM2 6H6V2H2V6ZM12 6H16V2H12V6ZM12 16H16V12H12V16ZM2 16H6V12H2V16Z"
      />
    </svg>
  );
};
