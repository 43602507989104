import PropTypes from "prop-types";

export const MenuHorizontal = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Horizontal menu"
    >
      <path
        d="M1.5 3.5C1.0875 3.5 0.734375 3.35312 0.440625 3.05937C0.146875 2.76562 0 2.4125 0 2C0 1.5875 0.146875 1.23438 0.440625 0.940625C0.734375 0.646875 1.0875 0.5 1.5 0.5C1.9125 0.5 2.26562 0.646875 2.55937 0.940625C2.85312 1.23438 3 1.5875 3 2C3 2.4125 2.85312 2.76562 2.55937 3.05937C2.26562 3.35312 1.9125 3.5 1.5 3.5ZM6 3.5C5.5875 3.5 5.23437 3.35312 4.94062 3.05937C4.64687 2.76562 4.5 2.4125 4.5 2C4.5 1.5875 4.64687 1.23438 4.94062 0.940625C5.23437 0.646875 5.5875 0.5 6 0.5C6.4125 0.5 6.76562 0.646875 7.05937 0.940625C7.35312 1.23438 7.5 1.5875 7.5 2C7.5 2.4125 7.35312 2.76562 7.05937 3.05937C6.76562 3.35312 6.4125 3.5 6 3.5ZM10.5 3.5C10.0875 3.5 9.73438 3.35312 9.44062 3.05937C9.14687 2.76562 9 2.4125 9 2C9 1.5875 9.14687 1.23438 9.44062 0.940625C9.73438 0.646875 10.0875 0.5 10.5 0.5C10.9125 0.5 11.2656 0.646875 11.5594 0.940625C11.8531 1.23438 12 1.5875 12 2C12 2.4125 11.8531 2.76562 11.5594 3.05937C11.2656 3.35312 10.9125 3.5 10.5 3.5Z"
        className={color}
      />
    </svg>
  );
};

MenuHorizontal.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

MenuHorizontal.defaultProps = {
  color: "currentColor",
  size: 24,
};
