import { forgot_bg } from "assets/images/images";
import LottieLogo from "components/myLayout/lottieLogo";
import { useContext } from "react";
import Context from "context/context";
import { useDictionary } from "dictionary/dictionaryContext";
import { Form, Input, message, Spin } from "antd";
import { ThemeButton } from "components/components";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { HandleIcon } from "helpers/handleIcons";
import { LeftArrowIcon } from "assets/icons/icons";
import { useAuthRedirect } from "hooks/useAuthRedirect";

export const ChangePassword = () => {
  const { activeTheme } = useContext(Context);
  const { translate } = useDictionary();
  const navigate = useNavigate();
  useAuthRedirect("/");
  const loginStatus = useSelector((state) => state.auth.status);
  const handleChangePassword = async (values) => {
    message.success(translate("auth.change_password.success"));
    navigate("/login");
  };
  return (
    <div
      className="bg-background py-10 px-32"
      role="main"
      aria-label={translate("auth.change_password.title")}
    >
      <div className="bg-cardsBackground grid grid-cols-2 gap-10 p-8 rounded-3xl">
        <div className="flex flex-col items-start">
          <LottieLogo theme={activeTheme} />
          <NavLink
            className="textfield text-primary flex items-center gap-2"
            to={"/login"}
          >
            <HandleIcon
              IconComponent={LeftArrowIcon}
              iconColor={"off"}
              iconSize={"xSmall"}
              state="simple"
              stroke
            />
            {translate("auth.change_password.back")}
          </NavLink>
          <p className="h1 text-primary mt-2">
            {translate("auth.change_password.title")}
          </p>
          <p className="textfield text-primary mt-3">
            {translate("auth.change_password.subtitle")}
          </p>
          <Form
            layout="vertical"
            className="w-full mt-5 textfield"
            onFinish={handleChangePassword}
          >
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.change_password.form.password")}
                </p>
              }
              name="password"
              rules={[
                {
                  required: true,
                  message: translate("auth.change_password.errors.password"),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  message: translate(
                    "auth.change_password.errors.passwordvalid"
                  ),
                },
              ]}
            >
              <Input.Password
                placeholder={translate("auth.change_password.form.password")}
                className="input"
                aria-describedby="password-requirements"
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.change_password.form.confirmpassword")}
                </p>
              }
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  message: translate(
                    "auth.change_password.errors.confirmpassword"
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      translate("auth.change_password.errors.passmatch")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={translate("auth.change_password.form.password")}
                className="input"
              />
            </Form.Item>
            <Form.Item>
              <ThemeButton className="w-full white">
                {loginStatus === "loading" ? (
                  <Spin />
                ) : (
                  translate("auth.change_password.form.button")
                )}
              </ThemeButton>
            </Form.Item>
          </Form>
        </div>
        <div className="flex justify-end">
          <img src={forgot_bg} alt="login_bg" width={"100%"} loading="lazy" />
        </div>
      </div>
    </div>
  );
};
