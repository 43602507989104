const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && typeof onPerfEntry === 'function') { 
    /**
     * Reports web vitals metrics using the provided callback function.
     * @param {Function} onPerfEntry - Callback function to handle performance entries.
     */
    import('web-vitals').then((vitals) => {
      Object.values(vitals).forEach(metric => {
        metric(onPerfEntry);
      });
    }).catch(error => console.error('Failed to load web-vitals', error));;
  }
};

export default reportWebVitals;
