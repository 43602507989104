import { ConfigProvider } from "antd";
import AppRouter from "config/router";
import { DictionaryProvider } from "dictionary/dictionaryContext";
import Poppins from "assets/fonts/Poppins-Regular.ttf";
import ThemeProvider from "context/state";
import { Provider } from "react-redux";
import store from "app/store";

const theme = {
  token: {
    fontFamily: `${Poppins}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif`,
  },
  components: {
    Select: {
      selectorBg: "var(--cards-background)",
      colorBorder: "var(--textfield-stroke)",
    },
  },
};

// Main application component
function App() {
  return (
    // Redux store provider
    <Provider store={store}>
      {/* Theme provider for the application */}
      <ThemeProvider>
        <ConfigProvider theme={theme}>
          {/* Provide dictionary context to the application */}
          <DictionaryProvider>
            {/* Application routing */}
            <AppRouter />
          </DictionaryProvider>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
