import PropTypes from "prop-types";

export const Adult = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Adult icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path 
        d="M5.70215 5.15723C5.94229 5.15723 6.18007 5.10993 6.40193 5.01803C6.62379 4.92614 6.82537 4.79144 6.99517 4.62164C7.16498 4.45184 7.29967 4.25025 7.39157 4.02839C7.48346 3.80654 7.53076 3.56875 7.53076 3.32861C7.53076 3.08848 7.48346 2.85069 7.39157 2.62883C7.29967 2.40698 7.16498 2.20539 6.99517 2.03559C6.82537 1.86579 6.62379 1.73109 6.40193 1.63919C6.18007 1.5473 5.94229 1.5 5.70215 1.5C5.21717 1.5 4.75206 1.69266 4.40912 2.03559C4.06619 2.37852 3.87354 2.84363 3.87354 3.32861C3.87354 3.81359 4.06619 4.27871 4.40912 4.62164C4.75206 4.96457 5.21717 5.15723 5.70215 5.15723ZM1 10.5908V10.9043H10.4043V10.5908C10.4043 9.42051 10.4043 8.83535 10.1765 8.38813C9.97615 7.99491 9.65645 7.67522 9.26324 7.47486C8.81602 7.24707 8.23086 7.24707 7.06055 7.24707H4.34375C3.17344 7.24707 2.58828 7.24707 2.14105 7.47486C1.74784 7.67522 1.42815 7.99491 1.22779 8.38813C1 8.83535 1 9.42051 1 10.5908Z" 
        stroke={color}
        stroke-width="1.04492" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  );
};

Adult.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
