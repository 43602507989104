export const ProposalIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Proposal Icon"
    >
      <path
        d="M6 7V5H15V7H6ZM6 10V8H15V10H6ZM9 20H3C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V9.025C17.6667 8.99167 17.3292 9.00417 16.9875 9.0625C16.6458 9.12083 16.3167 9.225 16 9.375V2H5V14H11L9 16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18H9V20ZM11 20V16.925L16.525 11.425C16.675 11.275 16.8417 11.1667 17.025 11.1C17.2083 11.0333 17.3917 11 17.575 11C17.775 11 17.9667 11.0375 18.15 11.1125C18.3333 11.1875 18.5 11.3 18.65 11.45L19.575 12.375C19.7083 12.525 19.8125 12.6917 19.8875 12.875C19.9625 13.0583 20 13.2417 20 13.425C20 13.6083 19.9667 13.7958 19.9 13.9875C19.8333 14.1792 19.725 14.35 19.575 14.5L14.075 20H11ZM12.5 18.5H13.45L16.475 15.45L16.025 14.975L15.55 14.525L12.5 17.55V18.5ZM16.025 14.975L15.55 14.525L16.475 15.45L16.025 14.975Z"
        className={color}
      />
    </svg>
  );
};
