import React from "react";
import { Flex, Tag } from "antd";
import PropTypes from "prop-types";
import { ContentWithIcon } from "./contentWithIcon";

export const TravelerSection = React.memo(({ icon, label, count, price, priceStyle }) => (
    <Flex vertical align='center'>
      <Flex justify='space-between' gap={7}>
        <ContentWithIcon
          icon={icon}
          content={label}
          gap={4}
          textStyle={'text-cardsCaption5'}
          iconStyle={'text-cardsCaption5'}
        />
        <Tag role='status'>
           <div 
            className='text-cardsCaption5 card-caption' 
            aria-label={`${label} count`}
          >
             {count}
           </div>
         </Tag>
      </Flex>
      <div 
        className={`${priceStyle} card-caption`}
        aria-label={`${label} price: ${price} dollars`}
      >
        {`$${price}`}
      </div>
    </Flex>
  ));

  TravelerSection.propTypes = {
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    priceStyle: PropTypes.string,
  };