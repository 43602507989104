import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAuthRedirect = (redirectPath = "/") => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated) {
      if (mounted) {
        try {
          navigate(redirectPath);
        } catch (error) {
          console.error("Navigation failed:", error);
        }
      }
    }
    if (mounted) {
      setIsLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [navigate, redirectPath]);

  return { isLoading };
};
