import PropTypes from "prop-types";

export const ClockFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Clock icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
     <path d="M4.5 8.25C2.42888 8.25 0.75 6.57113 0.75 4.5C0.75 2.42888 2.42888 0.75 4.5 0.75C6.57113 0.75 8.25 2.42888 8.25 4.5C8.25 6.57113 6.57113 8.25 4.5 8.25ZM4.875 4.5V2.625H4.125V5.25H6.375V4.5H4.875Z" 
       fill={color}
     />
    </svg>
  );
};

ClockFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
