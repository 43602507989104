// Importing image assets
import english_flag from "./english_flag.png";
import spanish_flag from "./spanish_flag.png";
import agent from "./agent.png";
import login_bg from "./login_bg.png";
import signup_bg from "./signup_bg.png";
import forgot_bg from "./forgot_bg.png";

// Exporting image assets
export { english_flag, spanish_flag, agent, login_bg, signup_bg, forgot_bg };

// Exporting logo_light animation configuration
export const logo_light = {
  loop: true,
  autoplay: true,
  animationData: require("./logo_light.json"),
};

// Exporting logo_dark animation configuration
export const logo_dark = {
  loop: true,
  autoplay: true,
  animationData: require("./logo_dark.json"),
};

// Exporting logo_light_collapsed animation configuration
export const logo_light_collapsed = {
  loop: true,
  autoplay: true,
  animationData: require("./logo_light_collapsed.json"),
};

// Exporting logo_dark_collapsed animation configuration
export const logo_dark_collapsed = {
  loop: true,
  autoplay: true,
  animationData: require("./logo_dark_collapsed.json"),
};
