import { ThemeTable } from "components/components";
import PropTypes from "prop-types";
import { useDictionary } from "dictionary/dictionaryContext";
import { formatDateToISOString } from "helpers/formatDate";
import {
  Adults,
  bronze,
  ChevronDown,
  ChevronRight,
  Childs,
  ContractEditIcon,
  gold,
  MenuVerticalIcon,
  platinum,
  silver,
} from "assets/icons/icons";
import { handleStages } from "helpers/handleStages";
import { agent } from "assets/images/images";
import { HandleIcon } from "helpers/handleIcons";
import { ExpandableRow } from "./expandableRow";

const ratingImages = {
  gold,
  silver,
  bronze,
  platinum,
};

export const DashboardTable = ({ leads }) => {
  const { translate } = useDictionary();
  const columns = [
    {
      key: "lead_id",
      title: "#",
      dataIndex: "lead_id",
      sorter: (a, b) => a.lead_id - b.lead_id,
    },
    {
      key: "surname",
      title: translate("dashboard.table.surname"),
      dataIndex: "surname",
      sorter: (a, b) => a.surname.localeCompare(b.surname),
    },
    {
      key: "email",
      title: translate("dashboard.table.email"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      key: "destination_country",
      title: translate("dashboard.table.destination_country"),
      dataIndex: "destination_country",
      sorter: (a, b) =>
        a.destination_country.localeCompare(b.destination_country),
    },
    {
      key: "arrival_date",
      title: translate("dashboard.table.arrival_date"),
      dataIndex: "arrival_date",
      sorter: (a, b) => new Date(a.arrival_date) - new Date(b.arrival_date),
      render: (date) => formatDateToISOString(date),
    },
    {
      key: "created_at",
      title: translate("dashboard.table.proposal_date"),
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => formatDateToISOString(date),
    },
    {
      key: "stage_name",
      title: translate("dashboard.table.stage_name"),
      dataIndex: "stage_name",
      render: (text) => handleStages(text),
      sorter: (a, b) => a.stage_name.localeCompare(b.stage_name),
    },
    {
      key: "rating",
      title: translate("dashboard.table.packages"),
      dataIndex: "rating",
      render: (text) => (
        <div className="flex items-center gap-2">
          {ratingImages[text] && <img src={ratingImages[text]} alt={text} />}
          <p className="capitalize">{text}</p>
        </div>
      ),
      sorter: (a, b) => a.rating.localeCompare(b.rating),
    },
    {
      key: "agent",
      title: translate("dashboard.table.agent"),
      dataIndex: "agent",
      render: () => (
        <div className="flex items-center">
          <img src={agent} alt="agent" width={25} />
          <img
            src={agent}
            alt="agent"
            width={25}
            className="relative -left-2"
          />
        </div>
      ),
    },
    {
      key: "actions",
      render: () => (
        <div className="flex items-center gap-2">
          <HandleIcon
            IconComponent={ContractEditIcon}
            iconColor={"off"}
            iconSize={"small"}
            state="button"
            hoverable
          />
          <HandleIcon
            IconComponent={MenuVerticalIcon}
            iconColor={"off"}
            iconSize={"small"}
            state="button"
            hoverable
          />
        </div>
      ),
    },
  ];
  return (
    <ThemeTable
      data={leads}
      columns={columns}
      expandable={{
        expandedRowRender: (record, index) => (
          <div
            className={`${
              index % 2 === 0
                ? "!bg-cellBg1 !text-primary"
                : "!bg-cellBg2 !text-primary"
            } -m-4 p-7`}
          >
            <div className="bg-cardsBackground py-3 px-5 flex items-center justify-between rounded-xl">
              <ExpandableRow
                Icon={Adults}
                title={translate("dashboard.table.adults_numbers")}
                count={record.traveler_summary.adults}
              />
              <ExpandableRow
                Icon={Childs}
                title={translate("dashboard.table.childrens_numbers")}
                count={record.traveler_summary.children}
              />
              <ExpandableRow
                Icon={Childs}
                title={translate("dashboard.table.infant_numbers")}
                count={record.traveler_summary.infants}
              />
            </div>
          </div>
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <HandleIcon
              IconComponent={ChevronDown}
              iconColor={"off"}
              iconSize={"small"}
              state="simple"
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <HandleIcon
              IconComponent={ChevronRight}
              iconColor={"off"}
              iconSize={"small"}
              state="simple"
              onClick={(e) => onExpand(record, e)}
            />
          ),
      }}
    />
  );
};

DashboardTable.propTypes = {
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      lead_id: PropTypes.number.isRequired,
      surname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      destination_country: PropTypes.string.isRequired,
      arrival_date: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      stage_name: PropTypes.string.isRequired,
      rating: PropTypes.oneOf(["gold", "silver", "bronze", "platinum"])
        .isRequired,
      agent: PropTypes.string.isRequired,
      traveler_summary: PropTypes.shape({
        adults: PropTypes.number.isRequired,
        children: PropTypes.number.isRequired,
        infants: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
