import { CalendarOutlined } from "@ant-design/icons";
import { DatePicker, Dropdown, Form, Input, Menu, Select, Tag } from "antd";
import { SearchIcon } from "assets/icons/icons";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";
import { useState } from "react";
import { useSelector } from "react-redux";

export const DetailedSearchInput = () => {
  const { translate } = useDictionary();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { stages } = useSelector((state) => state.leads);
  const { tags } = useSelector((state) => state.tags);
  const [selectedFilters, setSelectedFilters] = useState({
    arrivalDate: null,
    stages: [],
    tags: [],
    agent: null,
    createdDate: null,
    modifiedBy: null,
    valueFrom: null,
    valueTo: null,
  });

  const handleSearchClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDropdownChange = (value, field) => {
    console.log(`Field: ${field}, Value:`, value);
    setSelectedFilters((prev) => {
      const currentValues = prev[field] || [];
      const newValues = Array.isArray(value) ? value : [value];
      return {
        ...prev,
        [field]: Array.isArray(value)
          ? Array.from(new Set([...currentValues, ...newValues]))
          : newValues[0],
      };
    });
  };

  const handleReset = () => {
    setSelectedFilters({
      arrivalDate: null,
      stages: [],
      tags: [],
      agent: null,
      createdDate: null,
      modifiedBy: null,
      valueFrom: null,
      valueTo: null,
    });
  };

  const handleApply = () => {
    console.log("Applying filters:", selectedFilters);
  };

  const getSelectedValues = (filters) => {
    const values = [];
    if (filters.arrivalDate)
      values.push(`Arrival date: ${filters.arrivalDate.format("YYYY-MM-DD")}`);
    if (filters.stages.length > 0)
      values.push(`Stages: ${filters.stages.join(", ")}`);
    if (filters.tags.length > 0) values.push(`Tags: ${{ ...filters.tags }}`);
    if (filters.agent) values.push(`Agents: ${filters.agent}`);
    if (filters.createdDate)
      values.push(`Created date: ${filters.createdDate.format("YYYY-MM-DD")}`);
    if (filters.modifiedBy) values.push(`Modified by: ${filters.modifiedBy}`);
    if (filters.valueFrom) values.push(`Value from: ${filters.valueFrom}`);
    if (filters.valueTo) values.push(`Value to: ${filters.valueTo}`);
    return values;
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className="mr-1"
      >
        {label}
      </Tag>
    );
  };

  const searchMenu = (
    <Menu className="text-blue-400">
      <Menu.Item key="1">
        <Form.Item className="mb-2 flex-row-reverse w-full">
          <DatePicker
            className="w-full flex-row-reverse input"
            placeholder={translate("filters.detailedSearch.arrivaldate")}
            onChange={(date) => handleDropdownChange(date, "arrivalDate")}
            value={selectedFilters.arrivalDate}
            prefix={<CalendarOutlined />}
          />
        </Form.Item>
      </Menu.Item>
      <Menu.Item key="2">
        <Form.Item className="mb-2 flex-row-reverse w-full">
          <Select
            className="w-full flex-row-reverse"
            placeholder={translate("filters.detailedSearch.stages")}
            value={selectedFilters.stages}
            onChange={(value) => handleDropdownChange(value, "stages")}
            mode="multiple"
            allowClear
            options={stages.map((stage) => ({
              label: stage.stage_name,
              value: stage.stage_id,
            }))}
          />
        </Form.Item>
      </Menu.Item>
      <Menu.Item key="3">
        <Form.Item className="mb-2 flex-row-reverse w-full">
          <Select
            className="w-full flex-row-reverse"
            placeholder={translate("filters.detailedSearch.tags")}
            mode="multiple"
            value={selectedFilters.tags}
            onChange={(value) => handleDropdownChange(value, "tags")}
            allowClear
            options={tags.map((tag) => ({
              label: tag.stage_name,
              value: tag.stage_id,
            }))}
          />
        </Form.Item>
      </Menu.Item>
      <Menu.Item key="4">
        <Form.Item className="mb-2 flex-row-reverse w-full">
          <DatePicker
            className="input w-full"
            placeholder={translate("filters.detailedSearch.createddata")}
            onChange={(date) => handleDropdownChange(date, "createdDate")}
            value={selectedFilters.createdDate}
            prefix={<CalendarOutlined />}
          />
        </Form.Item>
      </Menu.Item>
      <Menu.Item key="5">
        <Form.Item className="mb-2 flex-row-reverse w-full">
          <Input
            className="input"
            placeholder={translate("filters.detailedSearch.modifiedby")}
            value={selectedFilters.modifiedBy || ""}
            onChange={(e) => handleDropdownChange(e.target.value, "modifiedBy")}
          />
        </Form.Item>
      </Menu.Item>
      <Menu.Item key="6" className="flex items-center">
        <div className="flex gap-2 items-center justify-between w-full">
          <Form.Item className="w-full">
            <Input
              className="input"
              placeholder={translate("filters.detailedSearch.valuefrom")}
              value={selectedFilters.valueFrom || ""}
              onChange={(e) =>
                handleDropdownChange(e.target.value, "valueFrom")
              }
            />
          </Form.Item>
          <div className="border w-20 border-primary opacity-20 mb-6" />
          <Form.Item className="w-full">
            <Input
              className="input"
              placeholder={translate("filters.detailedSearch.valueto")}
              value={selectedFilters.valueTo || ""}
              onChange={(e) => handleDropdownChange(e.target.value, "valueTo")}
            />
          </Form.Item>
        </div>
      </Menu.Item>
      <Menu.Item key="8">
        <div className="flex justify-between gap-3">
          <ThemeButton type="simple" onClick={handleReset}>
            {translate("filters.detailedSearch.reset")}
          </ThemeButton>
          <ThemeButton type="active" onClick={handleApply}>
            {translate("filters.detailedSearch.apply")}
          </ThemeButton>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="relative w-full mb-0">
      <Dropdown
        overlay={searchMenu}
        trigger={["click"]}
        visible={isDropdownVisible}
        onVisibleChange={(visible) => setIsDropdownVisible(visible)}
      >
        <Form.Item
          id="searchTaskList"
          className="mb-0 cursor-pointer"
          onClick={handleSearchClick}
        >
          <div className="flex search items-center gap-3 border rounded-md px-3 h-10">
            <HandleIcon
              IconComponent={SearchIcon}
              iconColor={"off"}
              iconSize={"small"}
              state="simple"
            />
            <Select
              mode="multiple"
              tagRender={tagRender}
              className="outline-none shadow-none h-9"
              placeholder={translate("filters.detailedSearch.title")}
              readOnly
              notFoundContent={null}
              value={getSelectedValues(selectedFilters)} // This should return multiple tags
            />
          </div>
        </Form.Item>
      </Dropdown>
    </div>
  );
};
