import PropTypes from "prop-types";

export const LocationFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Location icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M2.5 3.82059C2.2632 3.82059 2.0361 3.72652 1.86865 3.55908C1.70121 3.39164 1.60714 3.16453 1.60714 2.92773C1.60714 2.69093 1.70121 2.46383 1.86865 2.29639C2.0361 2.12895 2.2632 2.03488 2.5 2.03488C2.7368 2.03488 2.9639 2.12895 3.13135 2.29639C3.29879 2.46383 3.39286 2.69093 3.39286 2.92773C3.39286 3.04499 3.36976 3.16109 3.32489 3.26942C3.28002 3.37774 3.21425 3.47617 3.13135 3.55908C3.04844 3.64199 2.95001 3.70776 2.84168 3.75263C2.73336 3.7975 2.61725 3.82059 2.5 3.82059ZM2.5 0.427734C1.83696 0.427734 1.20107 0.691126 0.732233 1.15997C0.263392 1.62881 0 2.26469 0 2.92773C0 4.80273 2.5 7.57059 2.5 7.57059C2.5 7.57059 5 4.80273 5 2.92773C5 2.26469 4.73661 1.62881 4.26777 1.15997C3.79893 0.691126 3.16304 0.427734 2.5 0.427734Z" 
        fill={color}
      />
    </svg>
  );
};

LocationFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
