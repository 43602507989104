import {
  ChangePassword,
  Dashboard,
  ForgotPassword,
  Login,
  NotFound,
  Pipeline,
  Profile,
  Proposal,
  SignUp,
  VerifyEmail,
} from "pages/pages"; // Import the Dashboard component from the pages directory
import { useLayoutEffect } from "react"; // Import useLayoutEffect hook from React
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"; // Import necessary components from react-router-dom
import PrivateRoute from "./PrivateRoute";

// Component to scroll to the top of the page on route change
function ScrollToTop() {
  const { pathname } = useLocation(); // Get the current pathname
  useLayoutEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]); // Run the effect when the pathname changes
  return null; // This component does not render anything
}

// Main router component
function AppRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Scroll to top on route change */}
      <Routes>
        {/* Auth routes */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/change-password" element={<ChangePassword />} />

        {/* Protected routes */}
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/pipeline" element={<Pipeline />} />
          <Route exact path="/proposal" element={<Proposal />} />
        </Route>

        {/* Fallback route */}
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter; // Export the AppRouter component as the default export
