import React from "react";
import { MyLayout, LeadCard, ProposalPriceCard } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { travelerData } from "components/cards/leadCard/constant";
import { MENU_KEYS } from "constant/menuKeys";
import { TimelineCards } from "./components/timelineCard";
import { tripData } from "./components/constant";

export const Proposal = () => {
  const { translate } = useDictionary();
  return (
    <MyLayout
      title={translate("layout.sidebar.proposal")} //Change to correct name
      activeKey={MENU_KEYS.PROPOSAL}
    >
      <LeadCard data={travelerData} isEditTag />
      <br />
      <ProposalPriceCard title={translate("layout.sidebar.proposal")} />
      <LeadCard />
      <br />
      <ProposalPriceCard />
      <br />
      <TimelineCards tripData={tripData} />
    </MyLayout>
  );
};
