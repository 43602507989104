export const CreditCheckIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Credit Check Icon"
    >
      <path
        d="M2.66634 4.41667H17.333V2.58333H2.66634V4.41667ZM0.833008 2.58333C0.833008 2.07917 1.01252 1.64757 1.37155 1.28854C1.73058 0.929514 2.16217 0.75 2.66634 0.75H17.333C17.8372 0.75 18.2688 0.929514 18.6278 1.28854C18.9868 1.64757 19.1663 2.07917 19.1663 2.58333V8.08333H2.66634V13.5833H6.42467V15.4167H2.66634C2.16217 15.4167 1.73058 15.2372 1.37155 14.8781C1.01252 14.5191 0.833008 14.0875 0.833008 13.5833V2.58333ZM12.7038 17.25L8.80801 13.3542L10.0913 12.0708L12.7038 14.6375L17.883 9.45833L19.1663 10.7875L12.7038 17.25ZM2.66634 2.58333V13.5833V9.45833V12.0479V2.58333Z"
        className={color}
      />
    </svg>
  );
};
