export const MenuUnFold = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Menu Unfold Icon"
    >
      <title>Menu Unfold Icon</title>
      <path
        d="M1 1.08594H9.01523M1 4.00057H9.01523M1 6.9152H9.01523"
        className={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9062 5.0932L12.9992 4.00021L11.9062 2.90723"
        className={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
