import { Button } from "antd";
import { CloseIcon, EditIcon } from "assets/icons/icons";
import PropTypes from "prop-types";

const getType = (type) => {
  const styles = {
    active: "bg-ctaBg text-white border-ctaBg",
    warn: [
      "text-negativeButtonText",
      "!border-textfieldStroke",
      "hover:!text-negativeButtonText",
      "bg-cardsBackground",
      "hover:!bg-cardsBackground",
    ].join(" "),
    simple: "text-ctaBg border-ctaBg !bg-background",
    edit: "text-ctaBg border-ctaBg !bg-background",
    dashed: "text-ctaBg border-ctaBg border-dashed !bg-background",
  };
  if (!styles[type]) {
    console.warn(
      `Invalid button type: ${type}. Valid types are: ${Object.keys(
        styles
      ).join(", ")}. Falling back to active style.`
    );
  }
  return styles[type] || styles.active;
};

export const ThemeButton = ({
  type,
  children,
  StartIcon,
  onClick,
  className,
  disabled,
  startIconColor = "fill-white",
  closeIconColor = "fill-negativeButtonText",
  editIconColor = "fill-ctaBg",
  iconSize = 13,
  htmlType = "submit",
}) => {
  const buttonType = getType(type);
  return (
    <Button
      htmlType={htmlType}
      type={type === "active" ? "primary" : undefined}
      onClick={onClick}
      className={`${buttonType} ${className} commission-card flex items-center gap-3 h-10 rounded-lg`}
      disabled={disabled}
    >
      {StartIcon && <StartIcon color={startIconColor} />}
      {children}
      {type === "warn" && <CloseIcon color={closeIconColor} size={iconSize} />}
      {type === "edit" && <EditIcon color={editIconColor} size={iconSize} />}
    </Button>
  );
};

ThemeButton.propTypes = {
  type: PropTypes.oneOf(["active", "warn"]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  StartIcon: PropTypes.elementType,
  onClick: PropTypes.func,
};

ThemeButton.defaultProps = {
  className: "",
  type: "active",
};
