import { forgot_bg } from "assets/images/images";
import LottieLogo from "components/myLayout/lottieLogo";
import { useContext } from "react";
import Context from "context/context";
import { useDictionary } from "dictionary/dictionaryContext";
import { Form, Input, Spin } from "antd";
import { ThemeButton } from "components/components";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { HandleIcon } from "helpers/handleIcons";
import { LeftArrowIcon } from "assets/icons/icons";
import { useAuthRedirect } from "hooks/useAuthRedirect";

export const ForgotPassword = () => {
  const { activeTheme } = useContext(Context);
  const { translate } = useDictionary();
  const navigate = useNavigate();
  useAuthRedirect("/");
  const loginStatus = useSelector((state) => state.auth.status);
  const handleForgotPassword = async (values) => {
    navigate("/verify-email");
  };
  return (
    <div className="bg-background py-10 px-32">
      <div className="bg-cardsBackground grid grid-cols-2 gap-10 p-8 rounded-3xl">
        <div className="flex flex-col items-start">
          <LottieLogo theme={activeTheme} />
          <NavLink
            className="textfield text-primary flex items-center gap-2"
            to={"/login"}
          >
            <HandleIcon
              IconComponent={LeftArrowIcon}
              iconColor={"off"}
              iconSize={"xSmall"}
              state="simple"
              stroke
            />
            {translate("auth.forgot_password.back")}
          </NavLink>
          <p className="h1 text-primary mt-2 leading-[50px]">
            {translate("auth.forgot_password.title")}
          </p>
          <p className="textfield text-primary mt-3">
            {translate("auth.forgot_password.subtitle")}
          </p>
          <Form
            layout="vertical"
            className="w-full mt-5"
            onFinish={handleForgotPassword}
          >
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.forgot_password.form.email")}
                </p>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: translate("auth.forgot_password.errors.email"),
                },
                {
                  type: "email",
                  message: translate("auth.forgot_password.errors.emailvalid"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.forgot_password.form.email")}
                className="input"
              />
            </Form.Item>
            <Form.Item>
              <ThemeButton className="w-full white">
                {loginStatus === "loading" ? (
                  <Spin />
                ) : (
                  translate("auth.forgot_password.form.button")
                )}
              </ThemeButton>
            </Form.Item>
          </Form>
        </div>
        <div className="flex justify-end">
          <img src={forgot_bg} alt="forgot_bg" width={"100%"} />
        </div>
      </div>
    </div>
  );
};
