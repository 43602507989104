import { useState, useMemo } from "react";
import Context from "./context";

// State component to provide theme context to its children
const ThemeProvider = ({ children }) => {
  // Initialize activeTheme state with the value from localStorage
  const initialTheme = useMemo(
    () => localStorage.getItem("theme") || "light",
    []
  );
  const [activeTheme, setActiveTheme] = useState(initialTheme);
  const initialMenuState = useMemo(
    () => localStorage.getItem("menuCollapsed") === "true" || false,
    []
  );
  const [menuCollapsed, setMenuCollapsed] = useState(initialMenuState);

  return (
    // Provide activeTheme and setActiveTheme to the context
    <Context.Provider
      value={{ activeTheme, setActiveTheme, menuCollapsed, setMenuCollapsed }}
    >
      {children}
    </Context.Provider>
  );
};

export default ThemeProvider;
