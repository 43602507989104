import PropTypes from "prop-types";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDictionary } from "dictionary/dictionaryContext";

export const SearchInput = ({ className, ...props }) => {
  const { translate } = useDictionary();
  const baseClassName =
    "border-textfieldStroke textfield h-10 bg-cardsBackground !text-primary placeholder:!text-primary hover:bg-cardsBackground focus:bg-cardsBackground focus-within:bg-cardsBackground";
  return (
    <Input
      prefix={<SearchOutlined />}
      role="searchbox"
      aria-label={translate("filters.search")}
      // !important modifiers are required due to CSS specificity issues
      className={`${className} ${baseClassName}`}
      placeholder={translate("filters.search")}
      {...(({ dangerouslySetInnerHTML, ...safeProps }) => safeProps)(props)}
    />
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  // Document that this component accepts all Ant Design Input props
  ...Input.propTypes,
};
