import { AUTH } from "helpers/endpoints";
import api from "app/api"; // Axios instance

// Function to handle login
export const loginUser = async (email, password) => {
  try {
    const response = await api.post(AUTH.LOGIN, { email, password });

    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error("Authentication failed");
    }
    if (data.token) {
      // Save the token in localStorage
      localStorage.setItem("token", data.token);
      return data;
    } else {
      throw new Error("Authentication failed");
    }
  } catch (error) {
    if (error.response) {
      // Sanitize error messages
      throw new Error("Authentication failed");
    } else if (error.request) {
      throw new Error("Network error occurred");
    } else {
      throw new Error("An error occurred");
    }
  }
};

// Handle API errors
const handleApiError = (error, defaultMessage) => {
  if (error.response) {
    throw new Error(defaultMessage);
  } else if (error.request) {
    throw new Error("Network error occurred");
  }
  throw new Error("An error occurred");
};

// Function to handle signup
export const signupUser = async (userData) => {
  try {
    const response = await api.post(AUTH.REGISTER, userData);

    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error("Registration failed");
    }
    if (data.token) {
      // Save the token in localStorage
      localStorage.setItem("token", data.token);
      return data;
    } else {
      throw new Error("Registration failed");
    }
  } catch (error) {
    handleApiError(error, "Registration failed");
  }
};

// Function to get user details
export const getUserDetails = async () => {
  try {
    const response = await api.get(AUTH.USER);

    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error("Failed to fetch user details");
    }

    return data;
  } catch (error) {
    handleApiError(error, "Failed to fetch user details");
  }
};
