import { Avatar } from "antd";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UploadButton } from "./uploadButton";

export const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const { translate } = useDictionary();
  const getInitials = (name) => {
    const [firstName, lastName] = name.split(" ");
    const initials =
      firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    return initials;
  };
  const fullName = user?.first_name + " " + user?.last_name;
  const initials = getInitials(fullName);
  return (
    <div className="bg-cardsBackground rounded-2xl p-5 border border-cardsStroke flex justify-between">
      <div className="flex items-center gap-5">
        <Avatar size={70} className="sub-heading bg-background text-primary">
          {initials}
        </Avatar>
        {isEditing ? (
          <UploadButton />
        ) : (
          <div>
            <p className="text-primary sub-heading pb-1">
              {user?.first_name} {user?.last_name}
            </p>
            <p className="textfield text-cardsHeaders">Team Manager</p>
            <p className="textfield text-cardsHeaders">Karachi, Pakistan</p>
          </div>
        )}
      </div>
      <ThemeButton
        type={isEditing ? "active" : "edit"}
        onClick={() => setIsEditing(!isEditing)}
      >
        {isEditing
          ? translate("profile.main.save")
          : translate("profile.main.edit")}
      </ThemeButton>
    </div>
  );
};
