// leads & stages
export const LEADS = {
  GET_LIST: "/leads/list",
  GET_STAGES: "/leads/stage/get",
  // Assigns a lead to a different stage
  UPDATE_STAGE: "/leads/assign/stage",
  // Updates stage properties (name, order, etc.)
  EDIT_STAGE: "/leads/stage/update",
};

// tasks
export const TASKS = {
  GET_LIST: "/tasks/list",
};

// auth
export const AUTH = {
  LOGIN: "/aunt/login",
  REGISTER: "/aunt/register",
  USER: "/aunt/me",
};

// tags
export const TAGS = {
  GET_LIST: "/tags/list",
};
