import React, { useState } from "react";
import {
  CreditCheckIcon,
  HomeIcon,
  PatientListIcon,
  PersonCheckIcon,
  PlusIcon,
} from "assets/icons/icons";
import {
  BreakdownCard,
  MyLayout,
  StatisticsCard,
  TasksCard,
  ThemeButton,
} from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "features/leads/leadsSlice";
import { useEffect } from "react";
import { fetchTasks } from "features/tasks/tasksSlice";
import { DashboardTable } from "./components/dashboardTable";
import { DashboardFilters } from "./components/dashboardFilters";
import { filterLeads } from "./helpers/filterLeads";
import { MENU_KEYS } from "constant/menuKeys";

export const Dashboard = () => {
  const { translate } = useDictionary();
  const dispatch = useDispatch();
  const { leads, status: leadsStatus } = useSelector((state) => state.leads);
  const { tasks, status: tasksStatus } = useSelector((state) => state.tasks);
  const [filters, setFilters] = useState({
    search: "",
    destination: "",
    agent: "",
  });
  const [filteredLeads, setFilteredLeads] = useState([]);

  useEffect(() => {
    dispatch(fetchLeads()); // Fetch leads on component mount
    dispatch(fetchTasks()); // Fetch tasks on component mount
  }, [dispatch]);

  useEffect(() => {
    const filtered = filterLeads(leads, filters);
    setFilteredLeads(filtered);
  }, [filters, leads]);

  const statisticsCardsData = [
    {
      title: translate("dashboard.cards.activeLeads"),
      counts: filteredLeads.length,
      stats: "8,9",
      Icon: PersonCheckIcon,
    },
    {
      title: translate("dashboard.cards.newLeads"),
      counts: filteredLeads.length,
      stats: "8,9",
      Icon: PatientListIcon,
    },
    {
      title: translate("dashboard.cards.avgChanges"),
      counts: filteredLeads.length,
      stats: "8,9",
      Icon: CreditCheckIcon,
    },
    {
      title: translate("dashboard.cards.totalSales"),
      counts: filteredLeads.length,
      stats: "8,9",
      Icon: HomeIcon,
    },
  ];

  return (
    <MyLayout
      title={translate("dashboard.main.title")}
      isLoading={leadsStatus === "loading" || tasksStatus === "loading"}
      activeKey={MENU_KEYS.DASHBOARD}
    >
      <div className="grid grid-cols-4 gap-3">
        <div className="grid grid-cols-2 col-span-2 gap-3">
          {statisticsCardsData.map((card, index) => (
            <StatisticsCard
              key={index}
              title={card.title}
              counts={card.counts}
              stats={card.stats}
              Icon={card.Icon}
            />
          ))}
        </div>
        <BreakdownCard leads={filteredLeads} />
        <TasksCard tasks={tasks} isLoading={tasksStatus === "loading"} />
      </div>
      <div className="bg-cardsBackground rounded-2xl p-5 border border-cardsStroke mt-3">
        <div className="flex items-center justify-between">
          <p className="text-primary sub-heading">
            {translate("dashboard.main.leads")}
          </p>
          <ThemeButton StartIcon={PlusIcon}>
            {translate("dashboard.main.createLead")}
          </ThemeButton>
        </div>
        <div className="mt-5">
          <DashboardFilters
            filters={filters}
            setFilters={setFilters}
            setFilteredLeads={setFilteredLeads}
          />
        </div>
        <div className="mt-5">
          <DashboardTable leads={filteredLeads} />
        </div>
      </div>
    </MyLayout>
  );
};
