import { LEADS } from "helpers/endpoints";
import api from "app/api"; // Axios instance

// Function to make an API request
const makeApiRequest = async (endpoint, entityName) => {
  try {
    const response = await api.get(endpoint);
    const data = response.data;

    if (!data || typeof data !== "object") {
      throw new Error(`Invalid response data: ${JSON.stringify(data)}`);
    }
    if (Array.isArray(data) && data.length === 0) {
      console.info(`No ${entityName} found`);
    }
    return data;
  } catch (error) {
    if (error.response) {
      throw new Error(`API error: ${error.response.status} - Request failed`);
    } else if (error.request) {
      throw new Error("Network error: No response received from server");
    } else {
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};

// Function to get leads
export const getLeads = () => makeApiRequest(LEADS.GET_LIST, "leads");

// Function to get Stages
export const getStages = () => makeApiRequest(LEADS.GET_STAGES, "stage");

// Function to update the stage of a lead
export const updateStage = async ({ lead_id, stage_id }) => {
  try {
    // Ensure lead_id and stage_id are valid
    if (typeof lead_id !== "number" || typeof stage_id !== "number") {
      throw new Error("Invalid input: lead_id and stage_id should be numbers");
    }

    // API call to update stage
    const response = await api.put(LEADS.UPDATE_STAGE, { lead_id, stage_id });

    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error(`Invalid response data: ${JSON.stringify(data)}`);
    }

    return data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status outside of 2xx
      throw new Error(`API error: ${error.response.status} - Request failed`);
    } else if (error.request) {
      // No response received from server
      throw new Error("Network error: No response received from server");
    } else {
      // Request setup error
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};

// Function to update a single stage
export const editStage = async (stage) => {
  try {
    // API call to update the stage
    const response = await api.post(LEADS.EDIT_STAGE, stage);

    // Handle the response
    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error(`Invalid response data: ${JSON.stringify(data)}`);
    }

    return data;
  } catch (error) {
    // Handle errors based on type
    if (error.response) {
      // Server responded with a status outside of 2xx
      throw new Error(`API error: ${error.response.status} - Request failed`);
    } else if (error.request) {
      // No response received from server
      throw new Error("Network error: No response received from server");
    } else {
      // Request setup error
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};
