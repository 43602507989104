import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDictionary } from "dictionary/dictionaryContext";
import { LanguagesData } from "constant/constant";

// LanguageToggle component allows users to switch between English and Spanish
export const LanguageToggle = () => {
  const { setLanguage, dictionary } = useDictionary(); // Get setLanguage function and current language from context
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const dropdownRef = useRef(null); // Reference for the dropdown element

  // Function to handle language selection
  const handleSelect = (language) => {
    setLanguage(language); // Set the selected language
    setIsOpen(false); // Close the dropdown
  };

  // Function to handle clicks outside the dropdown
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    // Add event listener to detect clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const currentLanguage = LanguagesData.find(
    (lang) => lang.code === dictionary
  );

  return (
    <div
      className="relative h-10 rounded-lg px-3 bg-cardsBackground border-2 border-cardsStroke flex items-center"
      ref={dropdownRef} // Attach ref to the container
    >
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown visibility
        className="focus:outline-none flex items-center"
        aria-label={`Change language. Current language: ${currentLanguage.name}`} // Accessible label for screen readers
      >
        <img
          src={currentLanguage.flag}
          alt={`${currentLanguage.name} flag`}
          className="w-6 h-4 mr-2"
        />
        <span className="text-primary textfield">{currentLanguage.name}</span>{" "}
        {/* Display language text based on current language */}
      </button>

      {isOpen && (
        <ul
          className="absolute z-20 bg-cardsBackground text-primary border-2 border-cardsStroke mt-2 w-full top-7 right-0"
          role="menu"
        >
          {/*Loop through language options and display them in a dropdown*/}
          {LanguagesData.map((option) => (
            <li
              key={option.code}
              className="flex items-center cursor-pointer px-2"
            >
              <button
                onClick={() => handleSelect(option.code)}
                className="w-full text-left flex items-center"
                role="menuitem"
                type="button"
              >
                <img
                  src={option.flag}
                  alt={`${option.name} flag`}
                  className="w-6 h-4 mr-2"
                />
                <span className="textfield">{option.name}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
