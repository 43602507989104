import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import {
  CardWithSeparateLogo
} from "components/components";
import { dummyImage} from "./constant";
import { Flex } from "antd";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

export const TimelineCards = ({tripData}) => {
  const [timeline, setTimeline] = useState(tripData.days[0].timeline)
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over  && active.id !== over.id) {
      setTimeline(items => {
        const oldIndex = items.findIndex(item => 
          item.timeline_element_id === active.id
        )
        const newIndex = items.findIndex(item => item.timeline_element_id === over.id)
        return arrayMove(items, oldIndex, newIndex);
      })
    }
  }

  const itemIds = useMemo(() => timeline.map((item) => item?.details?.timeline_element_id), [timeline]);

  return (
    <Flex vertical gap={20}>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds}>
          {timeline.map(data => 
            <CardWithSeparateLogo 
              key={data.timeline_element_id}
              src={dummyImage} // TODO pass image into API and get it inside this component
              data={data} 
              id={tripData?.leadID}
            />
          )}
        </SortableContext>
      </DndContext>
    </Flex>
  );
};

TimelineCards.propTypes = {
  tripData: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.shape({
      timeline: PropTypes.array.isRequired
    })).isRequired
  }).isRequired
};