export const PlusIcon = ({ color = "currentColor", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Plus icon"
    >
      <path
        d="M12.688 7.488H7.84V12.456H5.296V7.488H0.448V5.184H5.296V0.215999H7.84V5.184H12.688V7.488Z"
        className={color}
      />
    </svg>
  );
};
