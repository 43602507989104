import PropTypes from "prop-types";
import { Modal } from "antd";
import { PlusIcon } from "assets/icons/icons";
import { ThemeButton } from "components/components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Stage } from "./stage";
import { useDictionary } from "dictionary/dictionaryContext";

export const EditPipelineModal = ({ isModalOpen, setIsModalOpen }) => {
  const { translate } = useDictionary();
  const { stages } = useSelector((state) => state.leads);
  const [allStages, setAllStages] = useState([]);
  useEffect(() => {
    if (!Array.isArray(stages)) return;
    setAllStages(stages.map((stage) => ({ ...stage, isEditable: false })));
  }, [stages]);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = handleOk;
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setAllStages((items) => {
        const oldIndex = items.findIndex((item) => item.stage_id === active.id);
        const newIndex = items.findIndex((item) => item.stage_id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Modal
      title={translate("pipeline.editPipelineModal.title")}
      open={isModalOpen}
      className="textfield"
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
      okText={translate("pipeline.editPipelineModal.oktext")}
      cancelButtonProps={{ style: { display: "none" } }}
      aria-labelledby="pipeline-modal-title"
    >
      <p className="text-cardsHeaders">
        {translate("pipeline.editPipelineModal.description")}
      </p>
      <div className="my-5 flex flex-col gap-3">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={allStages.map((stage) => stage.stage_id)}
            strategy={verticalListSortingStrategy}
          >
            {allStages.map((stage) => (
              <Stage
                key={stage.stage_id}
                data={stage}
                allStages={allStages}
                setAllStages={setAllStages}
              />
            ))}
          </SortableContext>
        </DndContext>
        <ThemeButton
          StartIcon={PlusIcon}
          startIconColor="fill-ctaBg"
          type="dashed"
        >
          {translate("pipeline.editPipelineModal.addStage")}
        </ThemeButton>
      </div>
    </Modal>
  );
};

EditPipelineModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
