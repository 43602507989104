import PropTypes from "prop-types";
import { Spin } from "antd";

/**
 * A centered loading spinner component using Ant Design's Spin.
 * Displays a loading indicator in the center of the viewport.
 * @component
 * @example
 * return (
 *   <LoadingSpinner />
 * )
 */

export const LoadingSpinner = ({ size = "default", className = "" }) => (
  <div
    className={`flex items-center justify-center h-screen ${className}`}
    role="status"
    aria-live="polite"
  >
    <Spin size={size} />
  </div>
);

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(["small", "default", "large"]),
  className: PropTypes.string,
};
