import React from "react";
import { Button, Card, Divider, Dropdown, Flex, Image } from "antd";
import PropTypes from "prop-types";
import { useDictionary } from "dictionary/dictionaryContext";
import { EllipsisOutlined } from "@ant-design/icons";
import { menuItems } from "./constants";
import { FlightCard } from "components/cards/timelineCards/flightCard";
import { ActivityCard } from "components/cards/timelineCards/activityCard";
import { HotelCard } from "components/cards/timelineCards/hotelCard";
import { TransportCard } from "components/cards/timelineCards/transportCard";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const CardWithSeparateLogo = React.memo(({ 
  src, 
  data,
  id,
}) => {  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: data.details.timeline_element_id});
  const { translate } = useDictionary();
  const handleMenuClick = () => {
    // TODO: Implement menu click handling
  }
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };
  const selectComponent = (data) => {
    switch (data.type) {
      case 'flight':
        return <FlightCard data={data} />;

      case 'hotel': 
        return <HotelCard data={data} />;

      case 'location': 
        return <TransportCard data={data} />;

      case 'activity': 
        return <ActivityCard data={data} />;

      default:
        return null; // Or consider returning a fallback component
    }
  }

  return (
    <div         
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Card
        bordered={false} 
        style={{ 
          width: '100%', 
          maxWidth: '437px',
          minHeight: '76px'
        }} 
        bodyStyle={{ padding: 0 }} 
      > 
        <Flex>
          {src && (
            <>
              <Flex 
                style={{
                  border: '1px',
                  overflow:'hidden',
                  minWidth: '54px',
                  padding: '15px'
                }}
                align='center'
                justify='space-around'
              >
                <Image
                  preview={false}
                  style={{
                    borderRadius: '4px',
                    border: '0.5px solid',
                    objectFit: 'cover',
                  }}
                  className='text-textfieldStroke'
                  width={22}
                  height={22}
                  src={src}
                />
              </Flex>

              <Divider  
                type='vertical'
                style={{ 
                  height: 'auto', 
                  margin: 0
                }}
              />
            </>
          )}

          <Flex 
            vertical 
            style={{
              padding: '3px 3px 11px 16px', 
              width: '100%'
            }}
          >
            <Flex align='center' gap={8} justify='flex-end' >
              <Flex 
                justify='space-between' 
                align='center' 
                gap={4}
              >
                <div
                  className='text-cardsCaption5 card-primary-small' 
                >
                  {translate('proposal.timelineCard.id').toUpperCase()}
                </div>

                <div
                  className='text-primary subtext' 
                >
                  {id} 
                </div>
              </Flex>

              <Dropdown menu={menuProps}>
                <Button 
                  icon={<EllipsisOutlined /> }
                  style={{width: '22px', height: '22px'}}
                />
              </Dropdown>
            </Flex>
          
            {selectComponent(data)}

          </Flex>
        </Flex>

      </Card>
    </div>
  )}
);

CardWithSeparateLogo.propTypes = {
  src: PropTypes.string,
  data: PropTypes.shape({
    details: PropTypes.shape({
      timeline_element_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
};