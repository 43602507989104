import PropTypes from "prop-types";

export const Location = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Location icon"
      aria-hidden={true}
    >
      <path
        d="M5 3.5C4.56735 3.5 4.14442 3.62829 3.78469 3.86866C3.42496 4.10903 3.14458 4.45067 2.97901 4.85038C2.81345 5.25009 2.77013 5.68993 2.85453 6.11426C2.93894 6.53859 3.14728 6.92837 3.4532 7.2343C3.75913 7.54022 4.14891 7.74856 4.57324 7.83297C4.99757 7.91737 5.43741 7.87405 5.83712 7.70849C6.23683 7.54292 6.57847 7.26254 6.81884 6.90281C7.05921 6.54308 7.1875 6.12015 7.1875 5.6875C7.1875 5.10734 6.95703 4.55094 6.5468 4.1407C6.13656 3.73047 5.58016 3.5 5 3.5ZM5 7C4.74041 7 4.48665 6.92302 4.27081 6.7788C4.05497 6.63458 3.88675 6.4296 3.78741 6.18977C3.68807 5.94994 3.66208 5.68604 3.71272 5.43144C3.76336 5.17684 3.88837 4.94298 4.07192 4.75942C4.25548 4.57587 4.48934 4.45086 4.74394 4.40022C4.99854 4.34958 5.26244 4.37557 5.50227 4.47491C5.7421 4.57425 5.94708 4.74247 6.0913 4.95831C6.23552 5.17415 6.3125 5.42791 6.3125 5.6875C6.3125 6.0356 6.17422 6.36944 5.92808 6.61558C5.68194 6.86172 5.3481 7 5 7ZM5 0.875C3.72409 0.876447 2.50085 1.38394 1.59865 2.28615C0.696442 3.18835 0.188947 4.41159 0.1875 5.6875C0.1875 7.40469 0.981016 9.22469 2.48438 10.9512C3.15989 11.7313 3.92017 12.4338 4.75117 13.0457C4.82473 13.0972 4.91237 13.1249 5.00219 13.1249C5.092 13.1249 5.17964 13.0972 5.2532 13.0457C6.08268 12.4336 6.84148 11.7311 7.51562 10.9512C9.0168 9.22469 9.8125 7.40469 9.8125 5.6875C9.81105 4.41159 9.30356 3.18835 8.40135 2.28615C7.49915 1.38394 6.27591 0.876447 5 0.875ZM5 12.1406C4.09602 11.4297 1.0625 8.81836 1.0625 5.6875C1.0625 4.64321 1.47734 3.64169 2.21577 2.90327C2.95419 2.16484 3.95571 1.75 5 1.75C6.04429 1.75 7.04581 2.16484 7.78423 2.90327C8.52266 3.64169 8.9375 4.64321 8.9375 5.6875C8.9375 8.81727 5.90398 11.4297 5 12.1406Z" 
        fill={color}
      />
    </svg>
  );
};

Location.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
