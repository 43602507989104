/**
 * Spanish translations organized by feature:
 * - layout: Common UI elements (sidebar, header)
 * - dashboard: Dashboard-specific translations
 *   - main: Primary dashboard labels
 *   - cards: Dashboard card components
 *   - breakdown: Stage breakdown component
 *   - tasks: Task management
 *   - table: Data table columns
 * - filters: Common filter components
 */

export const es = {
  layout: {
    sidebar: {
      label: "Menú",
      support: "Apoyo",
      dashboard: "Tablero",
      pipeline: "Tubería",
      chat: "Charla",
      proposal: "Propuesta",
      confirmations: "Confirmaciones",
      helpCenter: "Centro de ayuda",
      settings: "Configuraciones",
    },
    header: {
      commission: "Comisión",
      profile: "Perfil",
      help: "Ayuda",
      logout: "Cerrar sesión",
    },
  },
  dashboard: {
    main: {
      title: "Tablero",
      leads: "Clientes potenciales",
      createLead: "Crear cliente potencial",
    },
    cards: {
      activeLeads: "Clientes potenciales activos",
      newLeads: "Nuevos clientes potenciales",
      avgChanges: "Cambios promedio",
      totalSales: "Ventas totales",
      lastMonth: "del último mes",
    },
    breakdown: {
      title: "Desglose de etapas",
    },
    tasks: {
      title: "Tareas",
      due: "Vencimiento",
    },
    table: {
      surname: "Apellido",
      email: "Correo electrónico",
      destination_country: "País de destino",
      arrival_date: "Fecha de arribo",
      proposal_date: "Fecha de propuesta",
      stage_name: "Nombre de la etapa",
      packages: "Paquetes",
      agent: "Agente",
      adults_numbers: "Número de adultos",
      childrens_numbers: "Número de niños",
      infant_numbers: "Número de bebés",
    },
  },
  filters: {
    search: "Buscar",
    destination: "Destino",
    agent: "Agente",
    clear: "Borrar todo",
    detailedSearch: {
      title: "Buscar por correo electrónico, apellido, teléfono",
      arrivaldate: "Fecha de llegada",
      stages: "Todas las etapas",
      tags: "Etiquetas",
      createddata: "Fecha de creación",
      modifiedby: "Modificado por",
      valuefrom: "Valor desde",
      valueto: "Valor hasta",
      reset: "Reiniciar",
      apply: "Aplicar",
    },
  },
  auth: {
    login: {
      title: "Iniciar sesión",
      subtitle: "Ingrese sus credenciales para acceder a su cuenta",
      haveaccount: "¿No tienes una cuenta?",
      signup: "Regístrate",
      loginwith: "O inicie sesión con",
      forgot: "¿Olvidó la contraseña?",
      success: "Inicio de sesión exitoso",
      failed: "Error al iniciar sesión",
      form: {
        email: "Correo electrónico",
        password: "Contraseña",
        button: "Iniciar sesión",
      },
      errors: {
        email: "Correo electrónico es requerido",
        emailvalid: "El correo electrónico no es válido",
        password: "Contraseña es requerida",
      },
    },
    signup: {
      title: "Regístrate",
      subtitle: "Ingrese sus credenciales para acceder a su cuenta",
      haveaccount: "¿Ya tienes una cuenta?",
      createaccount: "Crear una cuenta",
      login: "Iniciar sesión",
      signupwith: "O regístrese con",
      success: "Registro exitoso",
      failed: "Error al registrarse",
      form: {
        firstname: "Nombre",
        lastname: "Apellido",
        email: "Correo electrónico",
        phone: "Teléfono",
        password: "Contraseña",
        confirmpassword: "Confirmar contraseña",
        agreed: {
          text: "Al registrarse, acepta los",
          terms: "Términos",
          and: "y",
          privacy: "Políticas de privacidad",
        },
        button: "Aceptar",
      },
      errors: {
        firstname: "Nombre es requerido",
        lastname: "Apellido es requerido",
        email: "Correo electrónico es requerido",
        emailvalid: "El correo electrónico no es válido",
        phone: "Número de teléfono es requerido",
        phonevalid: "Número de teléfono no es válido",
        password: "Contraseña es requerida",
        passwordvalid:
          "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, un número y un carácter especial",
        confirmpassword: "Confirmar contraseña es requerida",
        passmatch: "Las contraseñas no coinciden",
        agreed: "Debe aceptar los términos y condiciones",
      },
    },
    forgot_password: {
      title: "Olvidé mi contraseña",
      back: "Volver al inicio de sesión",
      subtitle: "Ingrese su correo electrónico para restablecer su contraseña",
      form: {
        email: "Correo electrónico",
        button: "Enviar",
      },
      errors: {
        email: "Correo electrónico es requerido",
        emailvalid: "El correo electrónico no es válido",
      },
    },
    verify_email: {
      title: "Verificar correo electrónico",
      back: "Volver al inicio de sesión",
      subtitle:
        "Ingrese el código de verificación enviado a su correo electrónico",
      form: {
        code: "Código",
        button: "Verificar",
      },
      errors: {
        code: "Código es requerido",
        codevalid: "El código debe contener solo números",
      },
    },
    change_password: {
      title: "Cambiar contraseña",
      back: "Volver al inicio de sesión",
      subtitle: "Ingrese su nueva contraseña",
      success: "Contraseña cambiada con éxito",
      form: {
        password: "Contraseña",
        confirmpassword: "Confirmar contraseña",
        button: "Cambiar contraseña",
      },
      errors: {
        password: "Contraseña es requerida",
        confirmpassword: "Confirmar contraseña es requerida",
        passwordvalid:
          "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, un número y un carácter especial",
        passmatch: "Las contraseñas no coinciden",
      },
    },
  },
  profile: {
    main: {
      title: "Perfil",
      edit: "Editar",
      save: "Guardar",
      upload: "Subir",
    },
    personal_information: {
      title: "Información personal",
      firstname: "Nombre",
      lastname: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
    },
    address: {
      title: "Dirección",
      country: "País",
      city: "Ciudad/Estado",
      zip: "Código postal",
    },
    errors: {
      firstname: "Nombre es requerido",
      lastname: "Apellido es requerido",
      email: "Correo electrónico es requerido",
      emailvalid: "El correo electrónico no es válido",
      phone: "Número de teléfono es requerido",
      phonevalid: "Número de teléfono no es válido",
      country: "País es requerido",
      city: "Ciudad es requerida",
      zip: "Código postal es requerido",
      zipvalid: "Código postal no es válido",
    },
  },
  notfound: {
    title: "Lo sentimos, la página que visitaste no existe.",
    back: "Volver",
  },
  proposal: {
    leadCard: {
      add: "Agregar",
      trip: "Viaje:",
      travellers: "Viajeros:",
      arrivalDate: "Fecha de llegada:",
      days: "días",
    },
    proposalPriceCard: {
      priceAnalysis: "Análisis de precios",
      netProfit: "Beneficio neto",
      commission: "Comisión",
      margin: "Margen",
      sellingPrice: "Precio de venta",
      buyingPrice: "Precio de compra",
      adult: "Adulto",
      child: "Niño",
      infant: "Bebé",
    },
  },
  pipeline: {
    main: {
      title: "Tubería",
      createLead: "Crear cliente potencial",
      additem: "Agregar artículo",
    },
    editPipelineModal: {
      title: "Editar tubería",
      description:
        "Establezca las etapas que atraviesa al trabajar con los clientes o elija una plantilla predefinida.",
      oktext: "Hecho",
      addStage: "Agregar etapa",
      successEdit: "Etapa actualizada con éxito",
      successDelete: "Etapa eliminada con éxito",
    },
  },
  timelineCard: {
    id: "id",
    airline: "Aerolínea",
    number: "Número",
    time: "Tiempo",
    stops: "Paradas",
    class: "Clase",
    luggage: "Equipaje",
    type: "Tipo",
    supplier: "Proveedor",
    plate: "Matrícula",
    pickup: "Levantar",
    duration: "Duración",
    location: "Ubicación",
    rooms: "Alojamiento",
    mealPlan: "Plan de comidas",
    activityName: "Nombre de la actividad",
    hotel: "Hotel",
    destination: "Destino",
  },
  dropdowns: {
    pipelineLead: {
      move: "Mover a",
      changeAgent: "Cambiar agente",
      edit: "Editar",
      delete: "Eliminar",
    },
    pipelineStage: {
      actions: "Acciones",
      selectAll: "Seleccionar todo",
      moveTo: "Mover a",
      sortBy: "Ordenar por",
      bylastmessage: "Por último mensaje",
      bylastevent: "Por último evento",
      bycreationdate: "Por fecha de creación",
      bysale: "Por venta",
    },
  },
};
