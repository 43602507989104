import clsx from "clsx";

// Function to set icon size based on the input
const getSize = (size) => {
  switch (size) {
    case "large":
      return "24px";
    case "medium":
      return "21px";
    case "small":
      return "18px";
    case "xSmall":
      return "9px";
    default:
      return "21px";
  }
};

// Main Icon component
export const HandleIcon = ({
  /** The icon component to render */
  /** @type {React.ComponentType<{ size: string, color: string }>} */
  IconComponent,
  /** Icon color state: 'on' | 'off' */
  /** @type {'on' | 'off'} @default 'on' */
  iconColor = "on",
  /** Icon size: 'large' | 'medium' | 'small' | 'xSmall' */
  iconSize = "medium",
  /** Render mode: 'simple' | 'button' */
  state = "simple",
  /** Click handler function */
  onClick,
  /** Enable hover effects */
  hoverable = false,
  /** Use stroke instead of fill for icon colors */
  stroke,
  /** Apply fixed dimensions */
  layout,
}) => {
  // Color mappings for the icon
  const COLOR_MAPPINGS = {
    on: {
      stroke: "stroke-iconsActive",
      fill: "fill-iconsActive",
    },
    off: {
      stroke: "stroke-iconsDefault",
      fill: "fill-iconsDefault",
    },
  };

  // Get the color based on the iconColor prop
  const getColor = (color) => {
    const mapping = COLOR_MAPPINGS[color] || COLOR_MAPPINGS.off;
    return mapping[stroke ? "stroke" : "fill"];
  };

  // Determine the icon size
  const size = getSize(iconSize);

  // Determine the icon color
  const color = getColor(iconColor);

  return (
    <>
      {state === "button" ? (
        // Render icon inside a button if state is "button"
        <button
          onClick={onClick}
          className={clsx(
            "bg-cardsBackground h-10 w-10 border rounded-lg flex items-center justify-center",
            {
              "border-iconsActive": iconColor === "on",
              "border-cardsStroke": iconColor !== "on",
              "hover:bg-iconsHovered": hoverable,
            }
          )}
        >
          <IconComponent size={size} color={color} />
        </button>
      ) : (
        state === "simple" && (
          // Render icon inside a div if state is "simple"
          <div
            onClick={onClick}
            onKeyDown={(e) => e.key === "Enter" && onClick?.()}
            role={onClick ? "button" : undefined}
            tabIndex={onClick ? 0 : undefined}
            className={clsx("rounded-lg flex items-center justify-center", {
              "cursor-pointer": onClick,
              "h-10 w-10": layout,
              "hover:bg-iconsHovered": hoverable,
            })}
          >
            <IconComponent size={size} color={color} />
          </div>
        )
      )}
    </>
  );
};
