import { CheckIcon, MenuVerticalIcon, PlusIcon } from "assets/icons/icons";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

export const TasksCard = ({ tasks, isLoading }) => {
  const sortedTasks = useMemo(
    () =>
      [...tasks].sort(
        (a, b) => new Date(b.task_due_date) - new Date(a.task_due_date)
      ),
    [tasks]
  );
  const { translate } = useDictionary();
  if (isLoading) return <Spin />;

  return (
    <div
      style={{
        height: 260,
      }}
      className="bg-cardsBackground rounded-2xl overflow-y-auto border border-cardsStroke flex flex-col justify-between"
    >
      <div className="w-full sticky top-0 bg-cardsBackground px-5 pt-5 pb-2 flex items-center justify-between z-10">
        <p className="chart-card-heading text-primary">
          {translate("dashboard.tasks.title")}
        </p>
        <HandleIcon
          IconComponent={PlusIcon}
          iconColor={"off"}
          iconSize={"small"}
          state="simple"
          background="off"
        />
      </div>
      <div className="flex flex-col gap-2 px-3">
        {sortedTasks.map((task) => {
          return (
            <div
              key={task.task_id}
              className="bg-cellBg2 rounded-full px-3 flex items-center h-16 justify-between"
            >
              <div className="flex items-center gap-3">
                <div
                  className="border hover:bg-iconsHovered cursor-pointer border-iconsDefault h-11 w-11 rounded-full flex items-center justify-center"
                  role="button"
                  aria-label="Complete task"
                  tabIndex={0}
                >
                  <HandleIcon
                    IconComponent={CheckIcon}
                    iconColor={"off"}
                    iconSize={"small"}
                    state="simple"
                    stroke
                  />
                </div>
                <div>
                  <p className="text-cardsCaption2 card-caption">
                    {task.task_name}
                  </p>
                  <p className="text-iconsDefault textfield mt-1">
                    {translate("dashboard.tasks.due")}:{" "}
                    <span className="text-cardsCaption2">
                      {new Intl.DateTimeFormat(undefined, {
                        dateStyle: "medium",
                      }).format(new Date(task.task_due_date))}{" "}
                    </span>
                  </p>
                </div>
              </div>
              <HandleIcon
                IconComponent={MenuVerticalIcon}
                iconColor={"off"}
                iconSize={"small"}
                state="simple"
                aria-label="Task options"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

TasksCard.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      task_id: PropTypes.string.isRequired,
      task_name: PropTypes.string.isRequired,
      task_due_date: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};
