import { useEffect, useState } from "react";
// DnD
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { KanbanContainer } from "./kanbanContainer";
import Items from "./items";
import { handleEnd } from "../helpers/handleDragEnd";
import { handleDragMove } from "../helpers/handleDragMove";
import { useDispatch, useSelector } from "react-redux";
import { updateLeadStage } from "features/leads/leadsSlice";

export const KanbanBoard = ({ leads }) => {
  const dispatch = useDispatch();
  const { stages } = useSelector((state) => state.leads);
  const [containers, setContainers] = useState([]);
  useEffect(() => {
    setContainers(
      stages.map((stage) => ({
        id: `container-${stage.stage_id}`,
        title: stage.stage_name,
        color: stage.color,
        items: leads
          .filter((lead) => lead.stage_name === stage.stage_name)
          .map((lead) => ({
            id: `item-${lead.lead_id}`,
            title: lead.name,
            budget: lead.budget_per_person,
            ...lead,
          })),
      }))
    );
  }, [leads, stages]);

  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function findValueOfItems(id, type) {
    if (type === "container") {
      return containers.find((item) => item.id === id);
    }
    if (type === "item") {
      return containers.find((container) =>
        container.items.find((item) => item.id === id)
      );
    }
  }

  const findItem = (id) => {
    const container = findValueOfItems(id, "item");
    if (!container) return "";
    const item = container.items.find((item) => item.id === id);
    if (!item) return "";
    return item;
  };

  const findContainerTitle = (id) => {
    const container = findValueOfItems(id, "container");
    if (!container) return "";
    return container.title;
  };

  const findContainerItems = (id) => {
    const container = findValueOfItems(id, "container");
    if (!container) return [];
    return container.items;
  };

  const handleDragStart = (event) => {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
  };

  const handleStage = async () => {
    const find = containers.find((container) =>
      container.items.find((item) => item.id === activeId)
    );
    await dispatch(
      updateLeadStage({
        lead_id: Number.parseInt(activeId.split("-")[1]),
        stage_id: Number.parseInt(find.id.split("-")[1]),
      })
    );
  };

  const handleDragEnd = (event) => {
    handleEnd(
      event,
      containers,
      arrayMove,
      setContainers,
      findValueOfItems,
      setActiveId
    );
    handleStage();
  };

  return (
    <div className="py-5">
      <div>
        <div className="flex overflow-y-auto">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragMove={(event) =>
              handleDragMove(
                event,
                findValueOfItems,
                containers,
                arrayMove,
                setContainers
              )
            }
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={containers.map((i) => i.id)}>
              {containers.map((container) => (
                <KanbanContainer
                  id={container.id}
                  title={container.title}
                  color={container.color}
                  key={container.id}
                  count={container.items.length}
                  budget={container.items.reduce(
                    (acc, item) => acc + item.budget,
                    0
                  )}
                  onAddItem={() => {}}
                >
                  <SortableContext items={container.items.map((i) => i.id)}>
                    <div className="flex flex-col gap-y-4">
                      {container.items.map((i) => (
                        <Items id={i.id} key={i.id} data={i} />
                      ))}
                    </div>
                  </SortableContext>
                </KanbanContainer>
              ))}
            </SortableContext>
            <DragOverlay adjustScale={false}>
              {/* Drag Overlay For item Item */}
              {activeId?.toString().includes("item") && (
                <Items id={activeId} data={findItem(activeId)} />
              )}
              {/* Drag Overlay For Container */}
              {activeId?.toString().includes("container") && (
                <KanbanContainer
                  id={activeId}
                  title={findContainerTitle(activeId)}
                >
                  {findContainerItems(activeId).map((i) => (
                    <Items id={i.id} key={i.id} data={i} />
                  ))}
                </KanbanContainer>
              )}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </div>
  );
};
