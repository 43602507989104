import { useDictionary } from "dictionary/dictionaryContext";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { getChartColorArray } from "constant/constant";
import { Card } from "antd";

// Function to count occurrences and format the array
const countOccurrences = (data) => {
  // Use reduce to count occurrences of each name
  const nameCount = data.reduce((acc, curr) => {
    // If the stage_name already exists, increment its count
    if (acc[curr.stage_name]) {
      acc[curr.stage_name]++;
    } else {
      // If the stage_name does not exist, initialize its count to 1
      acc[curr.stage_name] = 1;
    }
    return acc;
  }, {});

  // Map the result to the desired format (array of objects with name and value)
  return Object.keys(nameCount).map((name) => ({
    name,
    value: nameCount[name],
  }));
};

// Constant for converting degrees to radians
const RADIAN = Math.PI / 180;

// BreakdownCard component
export const BreakdownCard = ({ leads }) => {
  // Format the leads data
  const formattedLeads = useMemo(() => countOccurrences(leads), [leads]);
  const { translate } = useDictionary();
  // Function to render customized labels on the pie chart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    payload,
  }) => {
    // Calculate the position of the label
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        className="textfield"
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {payload.value}
      </text>
    );
  };
  const colors = getChartColorArray();
  return (
    <Card
      hoverable
      style={{ height: 260 }}
      className="bg-cardsBackground rounded-2xl border border-cardsStroke"
    >
      {/* Heading for the card */}
      <p className="chart-card-heading text-primary text-start w-full">
        {translate("dashboard.breakdown.title")}
      </p>
      <div className="relative">
        {/* Display total number of leads */}
        <p className="absolute heading text-primary top-[95px] left-[48%] transform -translate-x-1/2 -translate-y-1/2">
          {leads.length}
        </p>
      </div>
      {/* Pie chart */}
      <div className="flex justify-center mt-2">
        <PieChart
          width={180}
          height={180}
          role="img"
          aria-label={translate("dashboard.breakdown.title")}
        >
          <Pie
            data={formattedLeads}
            cx={80}
            cy={80}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            innerRadius={45}
            dataKey="value"
          >
            {/* Render cells with different colors */}
            {formattedLeads.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          {/* Tooltip for the pie chart */}
          <Tooltip formatter={(value, name) => [`${value}`, `${name}`]} />
        </PieChart>
      </div>
    </Card>
  );
};

BreakdownCard.propTypes = {
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      stage_name: PropTypes.oneOf(["Real Stage", "Unassigned"]).isRequired,
    })
  ).isRequired,
};
