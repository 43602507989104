import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editStage, getLeads, getStages, updateStage } from "./leadsApi";
import { STATUS } from "constant/constant";

// Async thunk for fetching leads
export const fetchLeads = createAsyncThunk(
  "leads/fetchLeads",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getLeads(); // Call the API
      return data; // Return the leads data to the thunk
    } catch (error) {
      return rejectWithValue(error.message); // Handle the error in the thunk and send only the error message
    }
  }
);

// Async thunk for fetching stages
export const fetchStages = createAsyncThunk(
  "leads/fetchStages",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getStages(); // Call the API
      return data; // Return the stages data to the thunk
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch stages"); // Provide a fallback error message
    }
  }
);

// Async thunk for updating the stage of a lead
export const updateLeadStage = createAsyncThunk(
  "leads/updateLeadStage",
  async ({ lead_id, stage_id }, { rejectWithValue }) => {
    try {
      const data = await updateStage({ lead_id, stage_id }); // Call the API
      return data; // Return the updated lead data to the thunk
    } catch (error) {
      return rejectWithValue(error.message); // Handle the error in the thunk and send only the error message
    }
  }
);

// Async thunk for editing the stage details
export const editStageDetails = createAsyncThunk(
  "leads/editStageDetails",
  async (stage, { rejectWithValue }) => {
    try {
      const data = await editStage(stage); // Call the API function
      return data; // Return the updated lead data to the thunk
    } catch (error) {
      return rejectWithValue(error.message); // Handle the error and only pass the error message
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    leads: [],
    stages: [],
    status: STATUS.IDLE,
    getStageStatus: STATUS.IDLE,
    updateStageStatus: STATUS.IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Leads and Stages
      .addCase(fetchLeads.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.leads = action.payload; // Store leads in state
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload || "Failed to fetch leads"; // Handle the error
      })

      // Fetch Stages
      .addCase(fetchStages.pending, (state) => {
        state.getStageStatus = STATUS.LOADING;
      })
      .addCase(fetchStages.fulfilled, (state, action) => {
        state.getStageStatus = STATUS.SUCCEEDED;
        state.stages = action.payload; // Store stages in state
      })
      .addCase(fetchStages.rejected, (state, action) => {
        state.getStageStatus = STATUS.FAILED;
        state.error = action.payload || "Failed to fetch leads"; // Handle the error
      })

      // Update Lead Stage
      .addCase(updateLeadStage.pending, (state) => {
        state.updateStageStatus = STATUS.LOADING;
      })
      .addCase(updateLeadStage.fulfilled, (state, action) => {
        state.updateStageStatus = STATUS.SUCCEEDED;

        // Update the stage of the lead in the leads array
        const { lead_id, stage_id } = action.meta.arg;
        const leadIndex = state.leads.findIndex((lead) => lead.id === lead_id);
        if (leadIndex !== -1) {
          state.leads[leadIndex].stage_id = stage_id; // Update the stage_id
        }
      })
      .addCase(updateLeadStage.rejected, (state, action) => {
        state.updateStageStatus = STATUS.FAILED;
        state.error = action.payload || "Failed to update lead stage"; // Handle the error
      })

      // Edit Stage Details
      .addCase(editStageDetails.pending, (state) => {
        state.updateStageStatus = STATUS.LOADING;
      })
      .addCase(editStageDetails.fulfilled, (state, action) => {
        state.updateStageStatus = STATUS.SUCCEEDED;

        // Update the stage details in the stages array
        const stageIndex = state.stages.findIndex(
          (stage) => stage.id === action.payload.id
        );
        if (stageIndex !== -1) {
          state.stages[stageIndex] = action.payload; // Update the stage details
        }
      })
      .addCase(editStageDetails.rejected, (state, action) => {
        state.updateStageStatus = STATUS.FAILED;
        state.error = action.payload || "Failed to update stage details"; // Handle the error
      });
  },
});

export default leadsSlice.reducer;
