/**
 * English translations organized by feature:
 * - layout: Common UI elements (sidebar, header)
 * - dashboard: Dashboard-specific translations
 *   - main: Primary dashboard labels
 *   - cards: Dashboard card components
 *   - breakdown: Stage breakdown component
 *   - tasks: Task management
 *   - table: Data table columns
 * - filters: Common filter components
 */

export const en = {
  layout: {
    sidebar: {
      label: "Menu",
      dashboard: "Dashboard",
      pipeline: "Pipeline",
      chat: "Chat",
      proposal: "Proposal",
      support: "Support",
      confirmations: "Confirmations",
      helpCenter: "Help Center",
      settings: "Settings",
    },
    header: {
      commission: "Commission",
      profile: "Profile",
      help: "Help",
      logout: "Logout",
    },
  },
  dashboard: {
    main: {
      title: "Dashboard",
      leads: "Leads",
      createLead: "Create Lead",
    },
    cards: {
      activeLeads: "Active Leads",
      newLeads: "New Leads",
      avgChanges: "AVG Changes",
      totalSales: "Total Sales",
      lastMonth: "from the last month",
    },
    breakdown: {
      title: "Stage Breakdown",
    },
    tasks: {
      title: "Tasks",
      due: "Due",
    },
    table: {
      surname: "Surname",
      email: "Email",
      destination_country: "Destination Country",
      arrival_date: "Arrival Date",
      proposal_date: "Proposal Date",
      stage_name: "Stage Name",
      packages: "Packages",
      agent: "Agent",
      adults_numbers: "Number Adults",
      childrens_numbers: "Number Childrens",
      infant_numbers: "Number Infant",
    },
  },
  filters: {
    search: "Search",
    destination: "Destination",
    agent: "Agent",
    clear: "Clear all",
    detailedSearch: {
      title: "Search by email, surname, phone",
      arrivaldate: "Arrival Date",
      stages: "All stages",
      tags: "Tags",
      createddata: "Created Date",
      modifiedby: "Modified by",
      valuefrom: "Value from",
      valueto: "Value to",
      reset: "Reset",
      apply: "Apply",
    },
  },
  auth: {
    login: {
      title: "Login",
      subtitle: "Login to access your Safari Expert account",
      haveaccount: "Don't have an account?",
      signup: "Sign up",
      loginwith: "Or login with",
      forgot: "Forgot password",
      success: "Login successful",
      failed: "Login failed",
      form: {
        email: "Email",
        password: "Password",
        button: "Login",
      },
      errors: {
        email: "Email is required",
        emailvalid: "Email is not valid",
        password: "Password is required",
      },
    },
    signup: {
      title: "Sign up",
      subtitle:
        "Let’s get you all set up so you can access your personal account.",
      haveaccount: "Already have an account?",
      login: "Login",
      signupwith: "Or Sign up with",
      success: "SignUp successful",
      failed: "SignUp failed",
      form: {
        firstname: "First Name",
        lastname: "Last Name",
        email: "Email",
        phone: "Phone Number",
        password: "Password",
        confirmpassword: "Confirm Password",
        agreed: {
          text: "I agree to all the",
          terms: "Terms",
          and: "and",
          privacy: "Privacy Policies",
        },
        button: "Create Account",
      },
      errors: {
        firstname: "First Name is required",
        lastname: "Last Name is required",
        email: "Email is required",
        emailvalid: "Email is not valid",
        phone: "Phone is required",
        phonevalid: "Phone number is not valid",
        password: "Password is required",
        passwordvalid:
          "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number",
        confirmpassword: "Confirm Password is required",
        passmatch: "Passwords do not match",
        agreed: "You must agree to the terms and privacy policies",
      },
    },
    forgot_password: {
      title: "Forgot your password?",
      back: "Back to login",
      subtitle:
        "Don’t worry, it happens to all of us. Enter your email below to recover your password",
      form: {
        email: "Email",
        button: "Submit",
      },
      errors: {
        email: "Email is required",
        emailvalid: "Email is not valid",
      },
    },
    verify_email: {
      title: "Verify Email",
      back: "Back to login",
      subtitle: "An authentication code has been sent to your email.",
      form: {
        code: "Enter Code",
        button: "Verify",
      },
      errors: {
        code: "Code is required",
        codevalid: "Code must contain only numbers",
      },
    },
    change_password: {
      title: "Change Password",
      back: "Back to login",
      subtitle: "Create new password to keep your account secure.",
      success: "Password changed successfully",
      form: {
        password: "Create Password",
        confirmpassword: "Re-enter Password",
        button: "Set Password",
      },
      errors: {
        password: "Password is required",
        confirmpassword: "Confirm Password is required",
        passwordvalid:
          "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number",
        passmatch: "Passwords do not match",
      },
    },
  },
  profile: {
    main: {
      title: "Profile",
      edit: "Edit",
      save: "Save",
      upload: "Upload Photo",
    },
    personal_information: {
      title: "Personal Information",
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email Address",
      phone: "Phone",
    },
    address: {
      title: "Address",
      country: "Country",
      city: "City/State",
      zip: "Postal Code/Zip Code",
    },
    errors: {
      firstname: "First Name is required",
      lastname: "Last Name is required",
      email: "Email is required",
      emailvalid: "Email is not valid",
      phone: "Phone is required",
      phonevalid: "Phone number is not valid",
      country: "Country is required",
      city: "City is required",
      zip: "Postal Code is required",
      zipvalid: "Postal Code is not valid",
    },
  },
  notfound: {
    title: "Sorry, the page you visited does not exist.",
    back: "Go Back",
  },
  proposal: {
    leadCard: {
      add: "Add",
      trip: "Trip:",
      travellers: "Travellers:",
      arrivalDate: "Arrival Date:",
      days: "days",
    },
    proposalPriceCard: {
      priceAnalysis: "Price Analysis",
      netProfit: "Net profit",
      commission: "Commission",
      margin: "Margin",
      sellingPrice: "Selling Price",
      buyingPrice: "Buying price",
      adult: "Adult",
      child: "Child",
      infant: "Infant",
    },
  },
  pipeline: {
    main: {
      title: "Pipeline",
      createLead: "Create Lead",
      additem: "Add Item",
    },
    editPipelineModal: {
      title: "Edit pipeline",
      description:
        "Building relationships with clients is a process. Customize the stages that you go through when working with clients or choose a pre-built template.",
      oktext: "Done",
      addStage: "Add Stage",
      successEdit: "Stage updated successfully",
      successDelete: "Stage deleted successfully",
    },
  },
  timelineCard: {
    id: "id",
    airline: "Airline",
    number: "Number",
    time: "Time",
    stops: "Stops",
    class: "Class",
    luggage: "Luggage",
    type: "Type",
    supplier: "Supplier",
    plate: "Plate",
    pickup: "Pickup",
    duration: "Duration",
    location: "Location",
    rooms: "Rooms",
    mealPlan: "Meal Plan",
    activityName: "Activity name",
    hotel: "Hotel",
    destination: "Destination",
  },
  dropdowns: {
    pipelineLead: {
      move: "Move to",
      changeAgent: "Change Agent",
      edit: "Edit",
      delete: "Delete",
    },
    pipelineStage: {
      actions: "Actions",
      selectAll: "Select all",
      moveTo: "Move to",
      sortBy: "Sort by",
      bylastmessage: "By last message",
      bylastevent: "By last event",
      bycreationdate: "By creation date",
      bysale: "By sale",
    },
  },
};
