import { apple, facebook, google } from "assets/icons/icons";
import { ThemeButton } from "components/components";

export const SocialButtons = () => {
  return (
    <div className="flex w-full items-center gap-5">
      <ThemeButton type="simple" className="w-full h-14">
        <img src={facebook} alt="facebook" />
      </ThemeButton>
      <ThemeButton type="simple" className="w-full h-14">
        <img src={google} alt="google" />
      </ThemeButton>
      <ThemeButton type="simple" className="w-full h-14">
        <img src={apple} alt="apple" />
      </ThemeButton>
    </div>
  );
};
