export const PersonCheckIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.51 8.83984L13.96 5.28984L15.385 3.88984L17.51 6.01484L21.76 1.76484L23.16 3.18984L17.51 8.83984ZM8.95996 8.83984C7.85996 8.83984 6.91829 8.44818 6.13496 7.66484C5.35163 6.88151 4.95996 5.93984 4.95996 4.83984C4.95996 3.73984 5.35163 2.79818 6.13496 2.01484C6.91829 1.23151 7.85996 0.839844 8.95996 0.839844C10.06 0.839844 11.0016 1.23151 11.785 2.01484C12.5683 2.79818 12.96 3.73984 12.96 4.83984C12.96 5.93984 12.5683 6.88151 11.785 7.66484C11.0016 8.44818 10.06 8.83984 8.95996 8.83984ZM0.959961 16.8398V14.0398C0.959961 13.4732 1.10579 12.9523 1.39746 12.4773C1.68913 12.0023 2.07663 11.6398 2.55996 11.3898C3.59329 10.8732 4.64329 10.4857 5.70996 10.2273C6.77663 9.96901 7.85996 9.83984 8.95996 9.83984C10.06 9.83984 11.1433 9.96901 12.21 10.2273C13.2766 10.4857 14.3266 10.8732 15.36 11.3898C15.8433 11.6398 16.2308 12.0023 16.5225 12.4773C16.8141 12.9523 16.96 13.4732 16.96 14.0398V16.8398H0.959961ZM2.95996 14.8398H14.96V14.0398C14.96 13.8565 14.9141 13.6898 14.8225 13.5398C14.7308 13.3898 14.61 13.2732 14.46 13.1898C13.56 12.7398 12.6516 12.4023 11.735 12.1773C10.8183 11.9523 9.89329 11.8398 8.95996 11.8398C8.02663 11.8398 7.10163 11.9523 6.18496 12.1773C5.26829 12.4023 4.35996 12.7398 3.45996 13.1898C3.30996 13.2732 3.18913 13.3898 3.09746 13.5398C3.00579 13.6898 2.95996 13.8565 2.95996 14.0398V14.8398ZM8.95996 6.83984C9.50996 6.83984 9.98079 6.64401 10.3725 6.25234C10.7641 5.86068 10.96 5.38984 10.96 4.83984C10.96 4.28984 10.7641 3.81901 10.3725 3.42734C9.98079 3.03568 9.50996 2.83984 8.95996 2.83984C8.40996 2.83984 7.93913 3.03568 7.54746 3.42734C7.15579 3.81901 6.95996 4.28984 6.95996 4.83984C6.95996 5.38984 7.15579 5.86068 7.54746 6.25234C7.93913 6.64401 8.40996 6.83984 8.95996 6.83984Z"
        className={color}
      />
    </svg>
  );
};
