export const NotificationsIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Notifications Icon"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5875 19.4125C6.97917 19.8042 7.45 20 8 20C8.55 20 9.02083 19.8042 9.4125 19.4125C9.80417 19.0208 10 18.55 10 18H6C6 18.55 6.19583 19.0208 6.5875 19.4125ZM0 15V17H16V15H14V9C13.65 9 13.3042 8.9625 12.9625 8.8875C12.6208 8.8125 12.3 8.70833 12 8.575V15H4V8C4 6.9 4.39167 5.95833 5.175 5.175C5.95833 4.39167 6.9 4 8 4C8.18333 4 8.35417 4.0125 8.5125 4.0375C8.67083 4.0625 8.83333 4.09167 9 4.125C8.98333 3.725 9.01667 3.33333 9.1 2.95C9.18333 2.56667 9.31667 2.19167 9.5 1.825V1.5C9.5 1.08333 9.35417 0.729167 9.0625 0.4375C8.77083 0.145833 8.41667 0 8 0C7.58333 0 7.22917 0.145833 6.9375 0.4375C6.64583 0.729167 6.5 1.08333 6.5 1.5V2.2C5.16667 2.53333 4.08333 3.2375 3.25 4.3125C2.41667 5.3875 2 6.61667 2 8V15H0Z"
        className={color}
      />
      <path
        d="M14 7C13.1667 7 12.4583 6.70833 11.875 6.125C11.2917 5.54167 11 4.83333 11 4C11 3.16667 11.2917 2.45833 11.875 1.875C12.4583 1.29167 13.1667 1 14 1C14.8333 1 15.5417 1.29167 16.125 1.875C16.7083 2.45833 17 3.16667 17 4C17 4.83333 16.7083 5.54167 16.125 6.125C15.5417 6.70833 14.8333 7 14 7Z"
        fill="#E6BA21"
      />
    </svg>
  );
};
