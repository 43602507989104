import PropTypes from "prop-types";

export const GraphUp = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Graph Up icon"
      aria-hidden={true}
    >
      <g clip-path="url(#clip0_68_1419)">
        <path d="M1.5 9C1.5 5.46447 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46447 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4017 2.59835C16.5 3.6967 16.5 5.46447 16.5 9C16.5 12.5355 16.5 14.3033 15.4017 15.4017C14.3033 16.5 12.5355 16.5 9 16.5C5.46447 16.5 3.6967 16.5 2.59835 15.4017C1.5 14.3033 1.5 12.5355 1.5 9Z" 
          stroke={color} 
          stroke-width="1.5"
        />
        <path d="M5.25 10.5L6.96967 8.78033C7.26256 8.48744 7.73744 8.48744 8.03033 8.78033L9.21967 9.96967C9.51256 10.2626 9.98744 10.2626 10.2803 9.96967L12.75 7.5M12.75 7.5V9.375M12.75 7.5H10.875" 
          stroke={color}
          stroke-width="1.5" 
          stroke-linecap="round" 
          stroke-linejoin="round"
        />
      </g>
      <defs>
      <clipPath id="clip0_68_1419">
       <rect width="18" height="18" fill="none"/>
      </clipPath>
      </defs>
    </svg>
  );
};

GraphUp.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
