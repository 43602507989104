import PropTypes from "prop-types";

export const RoomsFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Rooms icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M0 7V6.22222H0.777778V0H4.66667V0.388889H6.22222V6.22222H7V7H5.44444V1.16667H4.66667V7H0ZM3.11111 3.88889C3.2213 3.88889 3.31372 3.85156 3.38839 3.77689C3.46306 3.70222 3.50026 3.60993 3.5 3.5C3.49974 3.39007 3.46241 3.29778 3.388 3.22311C3.31359 3.14844 3.2213 3.11111 3.11111 3.11111C3.00093 3.11111 2.90863 3.14844 2.83422 3.22311C2.75981 3.29778 2.72248 3.39007 2.72222 3.5C2.72196 3.60993 2.7593 3.70235 2.83422 3.77728C2.90915 3.8522 3.00144 3.88941 3.11111 3.88889Z"
        fill={color}
      />
    </svg>
  );
};

RoomsFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
