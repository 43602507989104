import { Card, Divider, Flex } from "antd";
import { WalletOutlined } from "@ant-design/icons";
import { Adult, CurrencyUSD, Infant, Child, CurrencyBox, GraphUp } from "assets/icons/icons";
import { TravelerSection } from "components/general/helper/travelerSection";
import { CONSTANTS, travelerData } from "../leadCard/constant";
import { ContentWithIcon } from "../../general/helper/contentWithIcon";
import { useDictionary } from "dictionary/dictionaryContext";

// ProposalPriceCard component
export const ProposalPriceCard = () => {
  const { translate } = useDictionary();
  return (
    <Card 
      hoverable
      bordered={false} 
      style={{ width: '100%', maxWidth: '391px' }} 
      bodyStyle={{ padding: 0 }} 
    >   
        <Flex justify='space-between' style={{ padding: '16px' }}>
          <div className='text-cardsHeaders card-primary'>
            {translate('proposal.proposalPriceCard.priceAnalysis')}
          </div>
          <div className='text-cardsHeaders'>
            <CurrencyUSD size={16}/>
          </div>
        </Flex>

        <Divider style={{ margin: 0 }}/>

        <Flex >
          <Flex vertical style={{ width: '225px' }}>
            <div style={{ padding: '24px 24px 16px' }}>
              <Flex gap={8} align='center' >
                <ContentWithIcon 
                  icon={<CurrencyBox size={19} />}
                  content={CONSTANTS.NUMBER_1}
                  gap={4}
                  textStyle={'text-cardsCaption1 chart-card-heading'}
                  iconStyle={'text-cardsCaption1'}
                />
                  <div className='text-cardsCaption5 card-primary-small'>
                    {translate('proposal.proposalPriceCard.netProfit')}
                  </div>
              </Flex>

              <Flex gap={8} align='center'>
                <ContentWithIcon 
                  icon={<GraphUp size={19}/>}
                  content={`${CONSTANTS.NUMBER_2}%`}
                  gap={4}
                  textStyle={'text-cardsCaption1 chart-card-heading'}
                  iconStyle={'text-cardsCaption1'}
                />
                  <div className='text-cardsCaption5 card-primary-small'>
                    {translate('proposal.proposalPriceCard.margin')}
                  </div>
              </Flex>

              <Flex gap={8} align='center'>
                <ContentWithIcon 
                  icon={<WalletOutlined style={{ fontSize: '19px' }}/>}
                  content={`$${CONSTANTS.NUMBER_3}`}
                  gap={4}
                  textStyle={'text-cardsCaption1 chart-card-heading'}
                  iconStyle={'text-cardsCaption1'}
                />
                <div className='text-cardsCaption5 card-primary-small'>
                  {translate('proposal.proposalPriceCard.commission')}
                </div>
              </Flex>
            </div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }}/>
            
            <Flex justify='space-around' style={{ padding: '8px 8px 16px' }}>
              <Flex vertical >
                <div className='text-cardsCaption5 card-primary-small'>
                  {translate('proposal.proposalPriceCard.sellingPrice')}
                </div>

                <div className='text-cardsCaption1 card-caption-medium'>
                  {`$ ${CONSTANTS.NUMBER_4}`}
                </div>
              </Flex>

              <Flex vertical >
                <div className='text-cardsCaption5 card-primary-small'>
                  {translate('proposal.proposalPriceCard.buyingPrice')}
                </div>

                <div className='text-cardsDanger card-caption-medium'>
                  {`$ ${CONSTANTS.NUMBER_4}`}
                </div>
              </Flex>
            </Flex>
          </Flex>

          <Divider  
            type='vertical'
            style={{ 
              height: 'auto', 
              paddingRight: '30px', 
              margin: 0
            }}
          />

          <Flex vertical gap={18} style={{ height: 'auto', padding: '6px' }}>
            <TravelerSection 
              icon={<Adult size={14}/>}
              label={translate('proposal.proposalPriceCard.adult')}
              count={travelerData.traveler_summary.adults ?? 0}
              price={CONSTANTS.NUMBER_5}
              priceStyle='text-cardsPriceAdult'
            />

            <TravelerSection 
              icon={<Child size={14}/>}
              label={translate('proposal.proposalPriceCard.child')}
              count={travelerData.traveler_summary.children ?? 0}
              price={CONSTANTS.NUMBER_5}
              priceStyle='text-cardsPriceChild'
            />

            <TravelerSection 
              icon={<Infant size={14}/>}
              label={translate('proposal.proposalPriceCard.infant')}
              count={travelerData.traveler_summary.infants ?? 0}
              price={CONSTANTS.NUMBER_5}
              priceStyle='text-cardsPriceInfant'
            />
          </Flex>
        </Flex>
    </Card>
  );
};

