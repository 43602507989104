import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";

export const PersonalInformation = () => {
  const { user } = useSelector(useMemo(() => (state) => state.auth, []));
  const { translate } = useDictionary();
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className="bg-cardsBackground rounded-2xl p-5 border border-cardsStroke mt-4">
      <div className="flex justify-between">
        <p className="text-primary sub-heading">
          {translate("profile.personal_information.title")}
        </p>
        {!isEditing && (
          <ThemeButton type={"edit"} onClick={() => setIsEditing(true)}>
            {translate("profile.main.edit")}
          </ThemeButton>
        )}
      </div>
      {!isEditing && (
        <div className="textfield text-cardsHeaders grid grid-cols-2 gap-y-7 mt-4">
          <div>
            <p>{translate("profile.personal_information.firstname")}</p>
            <p className="pt-1">{user?.first_name}</p>
          </div>
          <div>
            <p>{translate("profile.personal_information.lastname")}</p>
            <p className="pt-1">{user?.last_name}</p>
          </div>
          <div>
            <p>{translate("profile.personal_information.email")}</p>
            <p className="pt-1">{user?.email}</p>
          </div>
          <div>
            <p>{translate("profile.personal_information.phone")}</p>
            <p className="pt-1">{user?.phone}</p>
          </div>
        </div>
      )}

      {isEditing && (
        <Form
          layout="vertical"
          className="w-full mt-10 grid grid-cols-2 gap-5 textfield relative"
          onFinish={() => {}}
          initialValues={user}
        >
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.personal_information.firstname")}
              </p>
            }
            name="first_name"
            rules={[
              {
                required: true,
                message: translate("profile.errors.firstname"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.personal_information.firstname")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.personal_information.lastname")}
              </p>
            }
            name="last_name"
            rules={[
              {
                required: true,
                message: translate("profile.errors.lastname"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.personal_information.lastname")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.personal_information.email")}
              </p>
            }
            name="email"
            rules={[
              {
                required: true,
                message: translate("profile.errors.email"),
              },
              {
                type: "email",
                message: translate("profile.errors.emailvalid"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.personal_information.email")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label={
              <p className="text-primary">
                {translate("profile.personal_information.phone")}
              </p>
            }
            name="phone"
            rules={[
              {
                required: true,
                message: translate("profile.errors.phone"),
              },
              {
                pattern: /^\+?[1-9]\d{1,14}$/,
                message: translate("profile.errors.phonevalid"),
              },
            ]}
          >
            <Input
              placeholder={translate("profile.personal_information.phone")}
              className="input"
            />
          </Form.Item>
          <Form.Item className="col-span-2 mt-2 absolute right-0 -top-[80px]">
            <ThemeButton type={"active"} onClick={() => setIsEditing(false)}>
              {translate("profile.main.save")}
            </ThemeButton>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
