const travelerDataJSON = `{
    "lead_id": 1,
    "contact_id": 1,
    "organisation_id": 1,
    "name": "Jean",
    "surname": "Dupont",
    "origin_country": "Tanzania",
    "address_1": "123 Rue de Paris",
    "address_2": "Apt. 4",
    "mobile": "812345678",
    "email": "jean.dupont@example.com",
    "stage_name": "Unassigned",
    "destination_country": "Tanzania",
    "duration_trip_days": 5,
    "budget_per_person": 3000,
    "announcement": "Special request 1",
    "created_at": "2024-09-10T16:19:54Z",
    "arrival_date": "2024-07-10T00:00:00Z",
    "tags": [
      {
        "tag_id": 1,
        "tag_name": "Tag1",
        "tag_color": "#ff0000",
        "organisation_id": 1,
        "user_id": 1
      },
      {
        "tag_id": 2,
        "tag_name": "Tag2",
        "tag_color": "#ffdb58",
        "organisation_id": 1,
        "user_id": 2
      }
    ],
    "traveler_summary": {
      "adults": 2,
      "children": 1,
      "infants": 1
    },
    "rating": "bronze",
    "booking_agent": "Chris Wilson",
    "booking_agent_id": 3,
    "service_agent": "Michael Brown",
    "service_agent_id": 2
  }`;

export const travelerData = JSON.parse(travelerDataJSON);
export const CONSTANTS = {
  NUMBER_1: "5,675",
  NUMBER_2: "47,7",
  NUMBER_3: "215",
  NUMBER_4: "15,500",
  NUMBER_5: "2500",
};

export const ICON_SIZE = {
  SMALL: "14px",
  MEDIUM: "16px",
  AVATAR: 50,
};
export const avatarSrc =
  "https://kartinki.pics/uploads/posts/2022-12/thumbs/1670021041_3-kartinkin-net-p-fon-cheloveka-vkontakte-3.jpg";
