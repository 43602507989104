export const STAGE_COLORS = {
  proposal_sent: "blue",
  follow_up_1: "green",
  follow_up_2: "orange",
  confirmed: "cyan",
  initial_contact: "blue",
  qualified: "volcano",
  cancelled: "error",
  negotiating: "gray",
  booked: "lime",
  new_request: "green",
};
