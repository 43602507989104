import PropTypes from "prop-types";

export const DoubleCheckIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Double check mark"
    >
      <title>Double check mark</title>
      <path
        d="M10.7163 2.22712L4.41634 8.41462C4.25856 8.56964 4.04621 8.6565 3.82501 8.6565C3.60382 8.6565 3.39147 8.56964 3.23368 8.41462L0.533684 5.76313C0.454598 5.68548 0.391582 5.59301 0.348234 5.491C0.304885 5.38899 0.282053 5.27945 0.281041 5.16861C0.278997 4.94478 0.365955 4.7293 0.522785 4.56958C0.600439 4.49049 0.69291 4.42748 0.794918 4.38413C0.896926 4.34078 1.00647 4.31795 1.11731 4.31694C1.34114 4.31489 1.55662 4.40185 1.71634 4.55868L3.82571 6.63009L9.53439 1.02337C9.69401 0.866538 9.9094 0.779543 10.1332 0.781521C10.3569 0.783499 10.5708 0.874287 10.7276 1.03391C10.8844 1.19354 10.9714 1.40893 10.9694 1.6327C10.9675 1.85647 10.8767 2.07029 10.717 2.22712H10.7163ZM17.4769 1.03181C17.3992 0.95245 17.3066 0.889212 17.2044 0.845719C17.1022 0.802226 16.9925 0.779333 16.8814 0.778353C16.7704 0.777373 16.6602 0.798326 16.5573 0.840009C16.4543 0.881692 16.3607 0.943287 16.2816 1.02126L10.575 6.63009L10.0209 6.08587C9.86132 5.92904 9.64593 5.84204 9.42216 5.84402C9.19839 5.846 8.98458 5.93679 8.82775 6.09641C8.67092 6.25604 8.58392 6.47143 8.5859 6.6952C8.58788 6.91897 8.67867 7.13279 8.83829 7.28962L9.98368 8.41462C10.1415 8.56964 10.3538 8.6565 10.575 8.6565C10.7962 8.6565 11.0086 8.56964 11.1663 8.41462L17.4663 2.22712C17.5454 2.14946 17.6084 2.057 17.6517 1.95501C17.695 1.85302 17.7178 1.7435 17.7187 1.6327C17.7197 1.5219 17.6989 1.41199 17.6574 1.30925C17.6159 1.20651 17.5545 1.11295 17.4769 1.03391V1.03181Z"
        className={color}
      />
    </svg>
  );
};

DoubleCheckIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
