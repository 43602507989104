const tripDataJSON = `{
  "proposalID": 4,
  "leadID": 1,
  "contactID": 1,
  "serviceAgentID": 1,
  "bookingAgentID": 4,
  "arrivalCountryID": 1,
  "arrivalLocationID": 1,
  "tripStartDt": "2024-07-14T00:00:00+03:00",
  "durationTripDays": 7,
  "people": [
    {
      "firstName": "John",
      "lastName": "John",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 20,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2161
    },
    {
      "firstName": "Jane",
      "lastName": "Jane",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 11,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2162
    },
    {
      "firstName": "Sophia",
      "lastName": "Sophia",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 2,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2163
    },
    {
      "firstName": "Daniel",
      "lastName": "Daniel",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 1,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2164
    },
    {
      "firstName": "Olivia",
      "lastName": "Olivia",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 5,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2165
    },
    {
      "firstName": "Lucas",
      "lastName": "Lucas",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 7,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2166
    },
    {
      "firstName": "Chloe",
      "lastName": "Chloe",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 45,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2167
    },
    {
      "firstName": "Matthew",
      "lastName": "Matthew",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 33,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2168
    },
    {
      "firstName": "Emma",
      "lastName": "Emma",
      "dob": "2024-10-19T00:00:00+03:00",
      "age": 9,
      "travelerPassportID": "123456789",
      "travelerPassportExpDT": "2024-10-19T00:00:00+03:00",
      "travelerPassportIssueDT": "2024-10-19T00:00:00+03:00",
      "traveler_id": 2169
    }
  ],
  "days": [
    {
      "date": 1720904400,
      "timeline": [
        {
          "type": "location",
          "timeline_element_id": 83,
          "timeline_position_id": 0,
          "details": {
            "proposed_location_id": 9,
            "timeline_element_id": 83,
            "transfer_type": "Car",
            "pickup_location_id": "201",
            "dropoff_location_id": "301",
            "pickup_time": "2024-09-28T08:00:00+03:00",
            "created_at": "2024-09-27T20:59:50+03:00",
            "updated_at": "2024-10-20T20:25:27+03:00",
            "deleted_at": null,
            "last_updated_by": "system",
            "attendance_masters": null,
            "traveler_ids": [],
            "supplier": "TODO",
            "plate": "TODO",
            "pickup_location": "TODO",
            "dropoff_location": "TODO",
            "class": "TODO"
          }
        },
        {
          "type": "flight",
          "timeline_element_id": 85,
          "timeline_position_id": 3,
          "details": {
            "departure_date": "2024-10-15T06:00:00Z",
            "return_date": "2024-10-25T14:00:00+03:00",
            "src_cities": "New York",
            "src_airports": "JFK",
            "dst_cities": "Paris",
            "dst_airports": "CDG",
            "travelers": {
              "adults": 2,
              "children": 1,
              "infant_in_seat": 1,
              "infant_on_lap": 0
            },
            "traveler_ids": [],
            "currency": "USD",
            "stops": 1,
            "class": "Economy",
            "trip_type": "Round Trip",
            "price": 1200,
            "outbound_flight_duration": 120,
            "return_flight_duration": 240,
            "timeline_element_id": 85,
            "flight": [
              {
                "proposed_flight_id": 241,
                "timeline_element_id": 85,
                "dep_airport_code": "JFK",
                "dep_airport_name": "John F. Kennedy International",
                "dep_city": "New York",
                "arr_airport_code": "LHR",
                "arr_airport_name": "London Heathrow",
                "arr_city": "London",
                "dep_time": "2024-10-15T09:00:00+03:00",
                "arr_time": "2024-10-15T18:00:00+03:00",
                "duration": 32400,
                "airplane": "Boeing 747",
                "flight_number": "BA100",
                "airline_name": "British Airways",
                "legroom": "Standard",
                "class": "TODO",
                "luggage": "TODO"
              },
              {
                "proposed_flight_id": 242,
                "timeline_element_id": 85,
                "dep_airport_code": "LHR",
                "dep_airport_name": "London Heathrow",
                "dep_city": "London",
                "arr_airport_code": "CDG",
                "arr_airport_name": "Charles de Gaulle Airport",
                "arr_city": "Paris",
                "dep_time": "2024-10-15T19:00:00+03:00",
                "arr_time": "2024-10-15T21:00:00+03:00",
                "duration": 7200,
                "airplane": "Airbus A320",
                "flight_number": "AF123",
                "airline_name": "Air France",
                "legroom": "Standard",
                "class": "TODO",
                "luggage": "TODO"
              }
            ]
          }
        },
        {
          "type": "hotel",
          "timeline_element_id": 86,
          "timeline_position_id": 0,
          "details": {
            "proposed_hotel_id": 1,
            "timeline_element_id": 86,
            "hotel_name": "Sample Hotel",
            "hotel_id": 456,
            "number_rooms": 10,
            "room_type": "Deluxe",
            "meal_plan": "Full Board",
            "created_at": "2024-10-20T17:30:02+03:00",
            "updated_at": "2024-10-20T17:30:02+03:00",
            "deleted_at": null,
            "last_updated_by": "admin",
            "attendance_masters": null,
            "traveler_ids": [],
            "rooms": "TODO",
            "location": "TODO"
          }
        },
        {
          "type": "activity",
          "timeline_element_id": 87,
          "timeline_position_id": 0,
          "details": {
            "proposed_activity_id": 1,
            "timeline_element_id": 87,
            "activity_name": "Sample Activity",
            "activity_id": 123,
            "traveler_id": 0,
            "created_at": "2024-10-20T17:31:20+03:00",
            "updated_at": "2024-10-20T17:31:20+03:00",
            "deleted_at": null,
            "last_updated_by": "admin",
            "attendance_masters": null,
            "traveler_ids": [],
            "duration": "TODO",
            "location": "TODO"
          }
        }
      ]
    }
  ]
}`

export const tripData = JSON.parse(tripDataJSON);
export const dummyImage ='https://i0.wp.com/blog.eternal3d.com/wp-content/uploads/2024/02/The-Best-3-Famous-Japanese-Art-photo.png?fit=1000,720&ssl=1'