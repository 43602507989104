import { Col, Flex, Image, Row } from "antd";
import PropTypes from 'prop-types';
import { Point } from "assets/icons/icons";
import { dummyImage, travelerData, } from "./constant";
import { useDictionary } from "dictionary/dictionaryContext";
import { useState } from "react";
import { Collapsing } from "components/general/helper/collapsing";
import { TravelersInfo } from "components/general/helper/travelersInfo";
import { ContentWithIcon } from "components/components";
import { chunkArray, getHotelDetails } from "./utils";

export const HotelCard = ({data}) => {
  const [isCollapsedView, setIsCollapsedView] = useState(true);
  const { translate } = useDictionary();
    
  const hotelData = data.details;
  const hotelDetails = getHotelDetails(hotelData);
  const hotelDetailsNonCollapsedView = hotelDetails.filter(detail => detail.value !== 'name')
  const hotelDetailsCollapsedView = hotelDetails.filter(
    detail => detail.value !== 'location' && detail.value !== 'meal'
  )

  const chunkedDetails = chunkArray(hotelDetailsNonCollapsedView, 2);
  const collapsedChunkedDetails = chunkArray(hotelDetailsCollapsedView, 1);

  return (
    <Flex vertical style={{paddingRight: '8px'}}>
      {isCollapsedView
        ? (
          <Flex gap={20}>
            {collapsedChunkedDetails.map((chunk, index) => (
              <Row key={index} >
                {chunk.map(detail => (
                  <Col key={detail.content}>
                    <ContentWithIcon
                      icon={detail.icon}
                      content={translate(detail.content)}
                      gap={3}
                      textStyle={'text-primary subtext'}
                      iconStyle={'text-cardsCaption3'}
                    />
                    <div 
                      style={{padding: '3px 0 15px'}}
                      className='text-primary card-primary-small'
                    >
                      {detail.data}
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </Flex>
        )
        : (
          <Flex vertical >
            <Flex gap={9} style={{paddingBottom: '15px'}}>
              <div className='text-cardsCaption5'>
                <Point />
              </div>

              <div className='text-primary commission-card'>
                {hotelData.hotel_name}
              </div>
            </Flex>

            <Flex gap={12}>
              <Image 
                preview={false} 
                style={{
                  borderRadius: '3px',
                  objectFit: 'cover',
                }}
                className='text-textfieldStroke'
                width={55}
                height={55}
                src={dummyImage}
              />

              <Flex gap={20}>
                {chunkedDetails.map((chunk, index) => (
                  <Col key={index}>
                    {chunk.map(detail => (
                      <Col key={detail.content}>
                        <ContentWithIcon
                          icon={detail.icon}
                          content={translate(detail.content)}
                          gap={3}
                          textStyle={'text-primary subtext'}
                          iconStyle={'text-cardsCaption3'}
                        />
                        <div 
                          style={{padding: '3px 0 15px'}}
                          className='text-primary card-primary-small'
                        >
                          {detail.data}
                        </div>
                      </Col>
                    ))}
                  </Col>
                ))}
              </Flex>
            </Flex>
          </Flex>
        )
      }

      <Flex gap={15} justify='flex-end'>
        <TravelersInfo
          adults={travelerData.traveler_summary.adults}
          children={travelerData.traveler_summary.children}
          infants={travelerData.traveler_summary.infants}
        />

        <Collapsing
          isCollapsed={isCollapsedView}
          setIsCollapsed={setIsCollapsedView}
        />
      </Flex>
    </Flex> 
  );
};

HotelCard.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.shape({
      hotel_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};