import { useMemo } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "./filters.css";

export const AgentSelect = ({ className, isLoading, ...props }) => {
  const agents = useSelector((state) =>
    state.leads.leads.map((lead) => lead?.booking_agent).filter(Boolean)
  );
  const format = useMemo(() => {
    const uniqueAgents = [...new Set(agents)];
    return uniqueAgents.map((agent) => ({
      value: agent,
      label: agent,
    }));
  }, [agents]);
  if (!format.length) {
    return (
      <Select
        className={`${className} textfield h-10`}
        disabled
        placeholder={
          isLoading
            ? "Loading booking agents..."
            : "No booking agents found in the system"
        }
        {...props}
      />
    );
  }
  return (
    <Select
      className={`${className} textfield h-10`}
      options={format}
      aria-label="Filter by agent"
      allowClear
      loading={isLoading}
      {...props}
    />
  );
};

AgentSelect.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

AgentSelect.defaultProps = {
  className: "",
  isLoading: false,
};
