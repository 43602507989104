import { signup_bg } from "assets/images/images";
import LottieLogo from "components/myLayout/lottieLogo";
import { useContext } from "react";
import Context from "context/context";
import { useDictionary } from "dictionary/dictionaryContext";
import { Checkbox, Form, Input, message, Spin } from "antd";
import { ThemeButton } from "components/components";
import { signup } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { SocialButtons } from "../components/socialButtons";
import { useAuthRedirect } from "hooks/useAuthRedirect";

export const SignUp = () => {
  const { activeTheme } = useContext(Context);
  const { translate } = useDictionary();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuthRedirect("/");
  const signupStatus = useSelector((state) => state.auth.status);
  const handleSignUp = async (values) => {
    const res = await dispatch(signup(values));
    // if (res?.error?.message === "Rejected") {
    //   return message.error(translate("auth.signup.failed"));
    // }
    message.success(translate("auth.signup.success"));
    navigate("/");
  };
  return (
    <div className="bg-background py-10 px-32">
      <div className="bg-cardsBackground grid grid-cols-5 gap-10 p-8 rounded-3xl">
        <div className="flex justify-end col-span-2">
          <img src={signup_bg} alt="login_bg" width={"100%"} />
        </div>
        <div className="flex flex-col items-start col-span-3">
          <LottieLogo theme={activeTheme} />
          <p className="h1 text-primary">{translate("auth.signup.title")}</p>
          <p className="textfield text-primary mt-1">
            {translate("auth.signup.subtitle")}
          </p>
          <Form
            layout="vertical"
            className="w-full mt-5 grid grid-cols-2 gap-5"
            onFinish={handleSignUp}
          >
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.firstname")}
                </p>
              }
              name="first_name"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.firstname"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.signup.form.firstname")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.lastname")}
                </p>
              }
              name="last_name"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.lastname"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.signup.form.lastname")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.email")}
                </p>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.email"),
                },
                {
                  type: "email",
                  message: translate("auth.signup.errors.emailvalid"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.signup.form.email")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.phone")}
                </p>
              }
              name="phone"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.phone"),
                },
                {
                  pattern: /^\+?[1-9]\d{1,14}$/,
                  message: translate("auth.signup.errors.phonevalid"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.signup.form.phone")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              className="col-span-2"
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.password")}
                </p>
              }
              name="password"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.password"),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  message: translate("auth.signup.errors.passwordvalid"),
                },
              ]}
            >
              <Input.Password
                placeholder={translate("auth.signup.form.password")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              className="col-span-2"
              label={
                <p className="text-primary card-primary">
                  {translate("auth.signup.form.confirmpassword")}
                </p>
              }
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.confirmpassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      translate("auth.signup.errors.passmatch")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={translate("auth.signup.form.confirmpassword")}
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: translate("auth.signup.errors.agreed"),
                },
              ]}
              className="-mt-3"
            >
              <Checkbox className="text-primary card-primary w-96">
                {translate("auth.signup.form.agreed.text")}{" "}
                <NavLink to="/terms" className="text-negativeButtonText">
                  {translate("auth.signup.form.agreed.terms")}{" "}
                </NavLink>
                {translate("auth.signup.form.agreed.and")}{" "}
                <NavLink to="/privacy" className="text-negativeButtonText">
                  {translate("auth.signup.form.agreed.privacy")}
                </NavLink>
              </Checkbox>
            </Form.Item>
            <Form.Item className="col-span-2 -mt-2">
              <ThemeButton
                className="w-full"
                disabled={signupStatus === "loading"}
              >
                {signupStatus === "loading" ? (
                  <Spin />
                ) : (
                  translate("auth.signup.form.button")
                )}
              </ThemeButton>
            </Form.Item>
          </Form>

          <p className="text-center w-full textfield text-primary relative -top-3">
            {translate("auth.signup.haveaccount")}{" "}
            <NavLink to={"/login"} className="text-negativeButtonText">
              {translate("auth.signup.login")}
            </NavLink>
          </p>
          <div className="flex items-center mb-4 gap-3 w-full">
            <div className="border w-full border-primary opacity-20" />
            <p className="textfield text-primary opacity-50 w-80 text-center">
              {translate("auth.signup.signupwith")}
            </p>
            <div className="border w-full border-primary opacity-20" />
          </div>
          <SocialButtons />
        </div>
      </div>
    </div>
  );
};
