export const HomeIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Home"
    >
      <path
        d="M3.58301 15.5837V9.16699H5.41634V15.5837H3.58301ZM9.08301 15.5837V9.16699H10.9163V15.5837H9.08301ZM0.833008 19.2503V17.417H19.1663V19.2503H0.833008ZM14.583 15.5837V9.16699H16.4163V15.5837H14.583ZM0.833008 7.33366V5.50033L9.99967 0.916992L19.1663 5.50033V7.33366H0.833008ZM4.91217 5.50033H15.0872L9.99967 2.97949L4.91217 5.50033Z"
        className={color}
      />
    </svg>
  );
};
