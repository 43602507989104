import PropTypes from "prop-types";

export const ChevronRight = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Chevron Right"
    >
      <title>Chevron Right</title>
      <path
        d="M6.95039 6.00019C6.95039 6.13353 6.92956 6.25853 6.88789 6.37519C6.84622 6.49186 6.77539 6.6002 6.67539 6.7002L2.07539 11.3002C1.89206 11.4835 1.65872 11.5752 1.37539 11.5752C1.09206 11.5752 0.858724 11.4835 0.675391 11.3002C0.492057 11.1169 0.400391 10.8835 0.400391 10.6002C0.400391 10.3169 0.492057 10.0835 0.675391 9.9002L4.57539 6.00019L0.67539 2.10019C0.492057 1.91686 0.40039 1.68353 0.40039 1.4002C0.40039 1.11686 0.492057 0.883529 0.67539 0.700195C0.858723 0.516862 1.09206 0.425195 1.37539 0.425195C1.65872 0.425195 1.89206 0.516862 2.07539 0.700195L6.67539 5.30019C6.77539 5.40019 6.84622 5.50853 6.88789 5.62519C6.92956 5.74186 6.95039 5.86686 6.95039 6.00019Z"
        className={color}
      />
    </svg>
  );
};

ChevronRight.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([16, 24, 32, 48]),
  ]).isRequired,
};
