import PropTypes from "prop-types";

export const LuggageFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Luggage icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
        <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M4.125 0.125C3.82663 0.125 3.54048 0.243526 3.3295 0.454505C3.11853 0.665483 3 0.951631 3 1.25V1.625H1.875C1.57663 1.625 1.29048 1.74353 1.0795 1.9545C0.868526 2.16548 0.75 2.45163 0.75 2.75V5.75C0.75 6.04837 0.868526 6.33452 1.0795 6.5455C1.29048 6.75647 1.57663 6.875 1.875 6.875H7.125C7.42337 6.875 7.70952 6.75647 7.9205 6.5455C8.13147 6.33452 8.25 6.04837 8.25 5.75V2.75C8.25 2.45163 8.13147 2.16548 7.9205 1.9545C7.70952 1.74353 7.42337 1.625 7.125 1.625H6V1.25C6 0.951631 5.88147 0.665483 5.6705 0.454505C5.45952 0.243526 5.17337 0.125 4.875 0.125H4.125ZM5.25 1.625H3.75V1.25C3.75 1.15054 3.78951 1.05516 3.85984 0.984835C3.93016 0.914509 4.02554 0.875 4.125 0.875H4.875C4.97446 0.875 5.06984 0.914509 5.14016 0.984835C5.21049 1.05516 5.25 1.15054 5.25 1.25V1.625Z"
        fill={color}
      />
    </svg>
  );
};

LuggageFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
