const { Flex } = require("antd");
const { Point } = require("assets/icons/icons");

export const LocationDisplay = ({ location }) => (
  <Flex gap={9}>
    <div className='text-cardsCaption5'>
      <Point />
    </div>
    <div className='text-primary commission-card'>
      {location}
    </div>
  </Flex>
);