export const ConfirmationIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Confirmation Icon"
      role="img"
    >
      <title>Confirmation Icon</title>
      <path
        className={color}
        d="M5.95 11L10.9 6.05L9.475 4.65L5.95 8.175L4.525 6.75L3.1 8.175L5.95 11ZM0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H12C12.55 0 13.0208 0.195833 13.4125 0.5875C13.8042 0.979167 14 1.45 14 2V18L7 15L0 18ZM2 14.95L7 12.8L12 14.95V2H2V14.95Z"
      />
    </svg>
  );
};
