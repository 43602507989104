import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { delete_white, drag_icon, edit_white } from "assets/icons/icons";
import { Button, Input, message } from "antd";
import { editStageDetails } from "features/leads/leadsSlice";
import { useDispatch } from "react-redux";
import { useDictionary } from "dictionary/dictionaryContext";

export const Stage = ({ data, allStages, setAllStages }) => {
  const dispatch = useDispatch();
  const { translate } = useDictionary();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: data.stage_id });
  const handleEditable = () => {
    setAllStages(
      allStages.map((stage) =>
        stage.stage_id === data.stage_id
          ? { ...stage, isEditable: !stage.isEditable }
          : stage
      )
    );
  };
  const handleEdit = async () => {
    try {
      await dispatch(editStageDetails(data));
      setAllStages(
        allStages.map((stage) =>
          stage.stage_id === data.stage_id
            ? { ...stage, isEditable: !stage.isEditable }
            : stage
        )
      );
      message.success(translate("pipeline.editPipelineModal.successEdit"), 2);
    } catch (error) {
      console.error("Failed to update stage:", error);
      // Add error notification here
    }
  };
  const handleDelete = () => {
    setAllStages((prev) =>
      prev.filter((stage) => stage.stage_id !== data.stage_id)
    );
    message.success(translate("pipeline.editPipelineModal.successDelete"), 2);
  };
  const onChange = (e) => {
    setAllStages(
      allStages.map((stage) =>
        stage.stage_id === data.stage_id
          ? { ...stage, stage_name: e.target.value }
          : stage
      )
    );
  };
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: data.color,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="h-11 rounded-lg text-white flex items-center justify-between gap-4 px-4"
    >
      <div className="flex items-center gap-3 w-full">
        <img
          src={drag_icon}
          alt="Drag stage to reorder"
          {...listeners}
          {...attributes}
          className="cursor-grab"
        />
        {data.isEditable ? (
          <Input
            className="w-full input"
            value={data.stage_name}
            suffix={
              <Button type="primary" onClick={() => handleEdit(data)}>
                Save
              </Button>
            }
            onChange={onChange}
          />
        ) : (
          <p>{data.stage_name}</p>
        )}
      </div>
      <div className="flex items-center gap-3">
        <img
          onClick={handleEditable}
          onKeyDown={(e) => e.key === "Enter" && handleEditable()}
          role="button"
          tabIndex={0}
          className="cursor-pointer"
          src={edit_white}
          alt="Edit stage name"
        />
        {data.deletable && (
          <img
            onClick={handleDelete}
            onKeyDown={(e) => e.key === "Enter" && handleDelete()}
            role="button"
            tabIndex={0}
            src={delete_white}
            alt="Delete stage"
            className="cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};
