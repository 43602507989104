import { useEffect, useState } from "react";
import { MyLayout } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { PipelineFilters } from "./components/pipelineFilters";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads, fetchStages } from "features/leads/leadsSlice";
import { filterLeads } from "pages/dashboard/helpers/filterLeads";
import { KanbanBoard } from "./components/kanbanBoard";
import { EditPipelineModal } from "./components/editPipelineModal";
import { fetchTags } from "features/tags/tagsSlice";
import { MENU_KEYS } from "constant/menuKeys";

export const Pipeline = () => {
  const { translate } = useDictionary();
  const dispatch = useDispatch();
  const { leads, status, getStageStatus } = useSelector((state) => state.leads);
  const [filters, setFilters] = useState({
    destination: "",
    agent: "",
  });
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLeads()); // Fetch leads on component mount
    dispatch(fetchStages()); // Fetch stages on component mount
    dispatch(fetchTags()); // Fetch tags on component mount
  }, [dispatch]);

  useEffect(() => {
    const filtered = filterLeads(leads, filters);
    setFilteredLeads(filtered);
  }, [filters, leads]);

  return (
    <MyLayout
      isLoading={status === "loading" || getStageStatus === "loading"}
      title={translate("pipeline.main.title")}
      activeKey={MENU_KEYS.PIPELINE}
    >
      <EditPipelineModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <PipelineFilters
        filters={filters}
        setFilters={setFilters}
        setFilteredLeads={setFilteredLeads}
        setIsModalOpen={setIsModalOpen}
      />
      <KanbanBoard leads={filteredLeads} />
    </MyLayout>
  );
};
