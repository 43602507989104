import { Tag, Tooltip } from "antd";
import { STAGE_COLORS } from "constant/constant";

export const handleStages = (text) => {
  if (!text || typeof text !== "string") {
    return <Tag color="default">Unknown</Tag>;
  }
  const normalizedStage = text
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, "_")
    .replace(/^_+|_+$/g, "");

  return (
    <Tooltip title={`Stage: ${text}`} placement="top">
      <Tag
        color={STAGE_COLORS[normalizedStage] || "default"}
        aria-label={`Stage: ${text}`}
      >
        {text}
      </Tag>
    </Tooltip>
  );
};
