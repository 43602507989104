import React from "react";
import PropTypes from "prop-types";
import { DownOutlined, UpOutlined } from "@ant-design/icons";


export const Collapsing = ({ isCollapsed, setIsCollapsed }) => {  
  return (
    <div 
      onClick={() => setIsCollapsed(!isCollapsed)} 
      onPointerDown={(e) => e.stopPropagation()}
    >
      { isCollapsed 
        ? <DownOutlined className='text-cardsCaption3'/> 
        : <UpOutlined className='text-cardsCaption3'/>
      }
    </div>
  )
};

Collapsing.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
};