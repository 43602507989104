import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS } from "constant/constant";
import { getTags } from "./tagsApi";

// Async thunk for fetching tags
export const fetchTags = createAsyncThunk(
  "tags/fetchTags",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getTags();
      return data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        code: error.code || "UNKNOWN_ERROR",
      });
    }
  }
);

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    tags: [],
    status: STATUS.IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Tags
      .addCase(fetchTags.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.tags = action.payload; // Store tags in state
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload || "Failed to fetch tags"; // Handle the error
      });
  },
});

// Selector functions
export const selectAllTags = (state) => state.tags.tags;
export const selectTagsStatus = (state) => state.tags.status;
export const selectTagsError = (state) => state.tags.error;

export default tagsSlice.reducer;
