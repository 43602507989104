import { Col, Flex, Image, Row } from "antd";
import { ActiveHumanFilled, Point } from "assets/icons/icons";
import { dummyImage, travelerData } from "./constant";
import { useDictionary } from "dictionary/dictionaryContext";
import { useState } from "react";
import { Collapsing } from "components/general/helper/collapsing";
import { TravelersInfo } from "components/general/helper/travelersInfo";
import { ContentWithIcon } from "components/components";
import { getActivityDetails } from "./utils";
import { LocationDisplay } from "components/general/helper/locationDisplay";

export const ActivityCard = ({data}) => {
  const [isCollapsedView, setIsCollapsedView] = useState(true);
  const { translate } = useDictionary();
    
  const activityData = data.details;
  const activityDetails = getActivityDetails(activityData);
  
  return (
    <Flex vertical style={{paddingRight: '8px'}}>
      {isCollapsedView
        ? (
          <Flex vertical>
            <ContentWithIcon
              icon={<ActiveHumanFilled size={10}/> }
              content={translate('proposal.timelineCard.activityName')}
              gap={3}
              textStyle={'text-primary subtext'}
              iconStyle={'text-cardsCaption3'}
            />
            <div 
              style={{padding: '3px 0 15px'}}
              className='text-primary card-primary-small'
            >
              {activityData.activity_name}
            </div>
          </Flex>
        )
        : (
          <Flex vertical >
            <div style={{ paddingBottom: '15px' }}>
              <LocationDisplay location={activityData.activity_name} />
            </div>
            
            <Flex gap={12}>
              <Image 
                preview={false}
                style={{
                  borderRadius: '3px',
                  objectFit: 'cover',
                }}
                className='text-textfieldStroke'
                width={55}
                height={55}
                src={dummyImage} // TODO implement image from API
              />

              <Flex gap={20}>
                {activityDetails.map((detail, index) => (
                  <Row key={index} >
                      <Col key={detail.content}>
                        <ContentWithIcon
                          icon={detail.icon}
                          content={translate(detail.content)}
                          gap={3}
                          textStyle={'text-primary subtext'}
                          iconStyle={'text-cardsCaption3'}
                        />
                        <div 
                          style={{padding: '3px 0 15px'}}
                          className='text-primary card-primary-small'
                        >
                          {detail.data}
                        </div>
                      </Col>
                  </Row>
                ))}
              </Flex>
            </Flex>
          </Flex>
        )
      }

      <Flex gap={15} justify='flex-end'>
        <TravelersInfo
          adults={travelerData.traveler_summary.adults}
          children={travelerData.traveler_summary.children}
          infants={travelerData.traveler_summary.infants}
        />

        <Collapsing
          isCollapsed={isCollapsedView}
          setIsCollapsed={setIsCollapsedView}
        />
      </Flex>
    </Flex> 
  );
};

