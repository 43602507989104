import { login_bg } from "assets/images/images";
import LottieLogo from "components/myLayout/lottieLogo";
import { useContext } from "react";
import Context from "context/context";
import { useDictionary } from "dictionary/dictionaryContext";
import { Form, Input, message, Spin } from "antd";
import { ThemeButton } from "components/components";
import { login } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { SocialButtons } from "../components/socialButtons";
import { useAuthRedirect } from "hooks/useAuthRedirect";

export const Login = () => {
  const { activeTheme } = useContext(Context);
  const { translate } = useDictionary();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuthRedirect("/");
  const loginStatus = useSelector((state) => state.auth.status);
  const handleLogin = async (values) => {
    try {
      // Sanitize inputs
      const sanitizedValues = {
        email: values.email.trim().toLowerCase(),
        password: values.password,
      };

      const res = await dispatch(login(sanitizedValues));
      if (res?.error) {
        // Map backend errors to user-friendly messages
        return message.error(translate(`auth.login.failed`));
      }

      message.success(translate("auth.login.success"));
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      message.error(translate("auth.login.errors.unexpected"));
    }
  };
  return (
    <div className="bg-background py-10 px-32">
      <div className="bg-cardsBackground grid grid-cols-2 gap-10 p-8 rounded-3xl">
        <div className="flex flex-col items-start">
          <LottieLogo theme={activeTheme} />
          <p className="h1 text-primary">{translate("auth.login.title")}</p>
          <p className="textfield text-primary mt-1">
            {translate("auth.login.subtitle")}
          </p>
          <Form
            layout="vertical"
            className="w-full mt-5"
            onFinish={handleLogin}
            aria-label={translate("auth.login.form.title")}
          >
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.login.form.email")}
                </p>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: translate("auth.login.errors.email"),
                },
                {
                  type: "email",
                  message: translate("auth.login.errors.emailvalid"),
                },
              ]}
            >
              <Input
                placeholder={translate("auth.login.form.email")}
                className="input"
                aria-label={translate("auth.login.form.email")}
                autoComplete="email"
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="text-primary card-primary">
                  {translate("auth.login.form.password")}
                </p>
              }
              name="password"
              rules={[
                {
                  required: true,
                  message: translate("auth.login.errors.password"),
                },
              ]}
            >
              <Input.Password
                placeholder={translate("auth.login.form.password")}
                className="input"
                aria-label={translate("auth.login.form.password")}
                autoComplete="current-password"
              />
            </Form.Item>
            <div className="flex justify-end w-full relative -top-4">
              <NavLink
                to={"/forgot-password"}
                className="text-primary card-primary"
              >
                {translate("auth.login.forgot")}
              </NavLink>
            </div>
            <Form.Item>
              <ThemeButton
                className="w-full"
                disabled={loginStatus === "loading"}
              >
                {loginStatus === "loading" ? (
                  <Spin />
                ) : (
                  translate("auth.login.form.button")
                )}
              </ThemeButton>
            </Form.Item>
          </Form>

          <p className="text-center w-full textfield text-primary relative -top-3">
            {translate("auth.login.haveaccount")}{" "}
            <NavLink to={"/signup"} className="text-negativeButtonText">
              {translate("auth.login.signup")}
            </NavLink>
          </p>
          <div className="flex items-center mb-4 gap-3 w-full">
            <div className="border w-full border-primary opacity-20" />
            <p className="textfield text-primary opacity-50 w-80 text-center">
              {translate("auth.login.loginwith")}
            </p>
            <div className="border w-full border-primary opacity-20" />
          </div>
          <SocialButtons />
        </div>
        <div className="flex justify-end">
          <img src={login_bg} alt="login_bg" width={"100%"} />
        </div>
      </div>
    </div>
  );
};
