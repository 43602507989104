import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { LoadingSpinner } from "components/components";

const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const validateToken = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        // Decode the token to get its expiration time
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          setIsAuthenticated(true);
        } else {
          // Token is expired, remove it from storage
          localStorage.removeItem("token");
          setIsAuthenticated(false);
        }
      } catch (error) {
        if (error.name === "InvalidTokenError") {
          console.error("Invalid token format:", error);
        } else if (error.name === "TokenExpiredError") {
          console.error("Token has expired:", error);
        } else {
          console.error("Token validation failed:", error);
        }
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
