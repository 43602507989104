import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { LeadCard } from "components/components";

const Items = ({ id, data }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: {
      type: data.type || "item",
    },
  });

  return (
    <div
      {...listeners}
      ref={setNodeRef}
      {...attributes}
      role="button"
      aria-roledescription="draggable item"
      aria-label={`Drag lead card for ${data.name || "unnamed lead"}`}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      className={clsx("cursor-pointer", isDragging && "opacity-50")}
    >
      <LeadCard data={data} isEditTag />
    </div>
  );
};

export default Items;

Items.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
};
