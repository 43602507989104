import { Button, Layout } from "antd";
import { MenuFold, MenuUnFold } from "assets/icons/icons";
import ThemeToggle from "./themeToggle";
import { Notifications } from "./notifications";
import { Commissions } from "./commissions";
import { LanguageToggle } from "./languageToggle";
import { HandleIcon } from "helpers/handleIcons";
import { ProfileDropdown } from "./profileDropdown";
import { memo, useContext } from "react";
import Context from "context/context";

const { Header } = Layout;

const HeaderUtilities = memo(({ commissionAmount }) => (
  <div className="flex items-center gap-5">
    <ThemeToggle />
    <Commissions amount={commissionAmount} />
    <LanguageToggle />
    <Notifications />
    <ProfileDropdown />
  </div>
));

// MyHeader component definition
export const MyHeader = ({ title }) => {
  const { menuCollapsed, setMenuCollapsed } = useContext(Context);
  return (
    <Header className="bg-background flex items-center justify-between pl-0 pr-8">
      <div className="flex items-center">
        {/* Button to toggle the collapsed state */}
        <Button
          className="bg-cardsBackground !w-10 !h-10 flex items-center justify-center border-2 border-background rounded-full relative -left-[20px] hover:!bg-cardsBackground"
          type="text"
          icon={
            menuCollapsed ? (
              <HandleIcon
                IconComponent={MenuUnFold}
                iconColor={"on"}
                iconSize={"small"}
                state="simple"
                stroke
              />
            ) : (
              <HandleIcon
                IconComponent={MenuFold}
                iconColor={"on"}
                iconSize={"small"}
                state="simple"
                stroke
              />
            )
          }
          onClick={() => setMenuCollapsed(!menuCollapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        {/* title of the header */}
        <p className="text-primary heading">{title}</p>
      </div>
      <HeaderUtilities commissionAmount={8451} />
    </Header>
  );
};
