import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails, loginUser, signupUser } from "./authApi"; // Auth API functions for user management
import { STATUS } from "constant/constant";

// Async thunk for logging in the user
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = await loginUser(email, password); // Call the login API
      return data; // Return the user data (e.g., token) on successful login
    } catch (error) {
      return rejectWithValue(error.message); // Handle the error in the thunk and send only the error message
    }
  }
);

// Async thunk for signing up the user
export const signup = createAsyncThunk(
  "auth/signup",
  async (userData, { rejectWithValue }) => {
    try {
      const data = await signupUser(userData);
      if (!data.token || !data.user) {
        throw new Error("Invalid response format");
      }
      localStorage.setItem("token", data.token);
      return data; // Return the user data (e.g., token) on successful signup
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        details: error.response?.data,
      });
    }
  }
);

// Async thunk for getting the user details
export const userDetails = createAsyncThunk(
  "auth/details",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }
      const data = await getUserDetails(); // Call the API
      if (!data) {
        throw new Error("Invalid response format");
      }
      return data; // Return the leads data to the thunk
    } catch (error) {
      return rejectWithValue(error.message); // Handle the error in the thunk and send only the error message
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    status: STATUS.IDLE,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
      state.status = STATUS.IDLE;
      state.error = null;
    },
    tokenExpired: (state) => {
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
      state.status = STATUS.FAILED;
      state.error = "Token expired";
    },
  },
  extraReducers: (builder) => {
    builder
      // Login cases
      .addCase(login.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.user = action.payload.user; // Assuming user details are in payload
        state.token = action.payload.token; // Store token from login response
      })
      .addCase(login.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        if (action.payload?.status === 401) {
          state.error = "Invalid credentials";
        } else if (action.payload?.status === 403) {
          state.token = null;
          state.user = null;
          state.error = "Session expired";
        } else {
          state.error = action.payload?.message || "Login failed";
        }
      })
      // Signup cases
      .addCase(signup.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.user = action.payload.user; // Assuming user details are in payload
        state.token = action.payload.token; // Store token from signup response
      })
      .addCase(signup.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload || "Signup failed"; // Handle the error
      })
      // User details cases
      .addCase(userDetails.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.user = action.payload; // Assuming user details are in payload
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload || "Failed to get user details"; // Handle the error
      });
  },
});

export const { logout, tokenExpired } = authSlice.actions;

// Selectors
export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectAuthStatus = (state) => state.auth.status;
export const selectAuthError = (state) => state.auth.error;

export default authSlice.reducer;
