import React from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";

export const NotFound = () => {
  const navigate = useNavigate();
  const { translate } = useDictionary();
  return (
    <div
      className="flex justify-center items-center h-screen textfield bg-background"
      role="alert"
      aria-label={translate("notfound.title")}
    >
      <Result
        status="404"
        title={<p className="text-primary">404</p>}
        subTitle={<p className="text-primary">{translate("notfound.title")}</p>}
        extra={
          <div className="flex justify-center">
            <ThemeButton
              onClick={() => {
                if (window.history.length > 1) {
                  navigate(-1);
                } else {
                  navigate("/");
                }
              }}
              type="active"
            >
              {translate("notfound.back")}
            </ThemeButton>
          </div>
        }
      />
    </div>
  );
};
