import { en } from "./en.jsx";
import { es } from "./es.jsx";

export const DEFAULT_LANGUAGE = "en";

/**  @type {Record<string, Record<string, string>>}  */

export const languages = {
  en,
  es,
};
