import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import leadsReducer from "../features/leads/leadsSlice";
import tasksReducer from "../features/tasks/tasksSlice";
import authReducer from "../features/auth/authSlice";
import tagsReducer from "../features/tags/tagsSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "token"], // specify which parts of auth state to persist
};

const store = configureStore({
  reducer: {
    leads: leadsReducer,
    tasks: tasksReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    tags: tagsReducer,
  },
});

export default store;
