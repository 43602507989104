import PropTypes from "prop-types";

export const Infant = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Infant icon"
      aria-hidden={true}
    >
     <path d="M5.57929 1.13188L5.28762 1.42354C4.50595 0.986042 3.57262 0.898542 2.73845 1.17271L2.69179 1.13188C2.12595 0.560208 1.19845 0.560208 0.63262 1.13188C0.0667866 1.70354 0.0609533 2.62521 0.63262 3.19104L0.673453 3.23771C0.399287 4.07187 0.486787 5.00521 0.924287 5.78687L0.63262 6.07854C0.550784 6.15938 0.48581 6.25567 0.441465 6.36181C0.397119 6.46795 0.374284 6.58184 0.374284 6.69688C0.374284 6.81191 0.397119 6.9258 0.441465 7.03194C0.48581 7.13808 0.550784 7.23436 0.63262 7.31521L1.45512 8.14354C1.79929 8.48187 2.34762 8.48187 2.69179 8.14354L2.89595 7.93354L7.02595 12.0577C7.36429 12.4019 7.91845 12.4019 8.26262 12.0577L11.5585 8.76188C11.9026 8.41771 11.9026 7.86354 11.5585 7.51937L7.43429 3.39521L7.64429 3.19104C7.98262 2.84687 7.98262 2.29854 7.64429 1.95437L6.81595 1.12604C6.65112 0.962954 6.42829 0.871964 6.19641 0.873058C5.96454 0.874151 5.74258 0.96724 5.57929 1.13188ZM1.47845 3.61104C1.52512 3.41271 1.60679 3.22021 1.71762 3.03938L1.25095 2.57271C1.18824 2.51364 1.142 2.43925 1.11679 2.35687C1.04095 2.15854 1.08179 1.91354 1.25095 1.75021C1.42012 1.58687 1.65929 1.54021 1.85762 1.61604C1.93929 1.63938 2.00929 1.68604 2.07345 1.75021L2.54012 2.21687C2.72095 2.10604 2.91345 2.02437 3.11179 1.97771C3.61345 1.83187 4.15012 1.86687 4.62845 2.07687L1.57762 5.12771C1.36762 4.64937 1.33262 4.11271 1.47845 3.61104ZM10.9401 8.14354L7.64429 11.4394L3.52012 7.31521L6.81595 4.01937L10.9401 8.14354ZM7.02595 2.57271L2.07345 7.52521L1.25095 6.69688L6.19762 1.75021L7.02595 2.57271ZM7.64429 6.28271L5.78345 8.14354L5.16512 7.52521L7.02595 5.66437L7.64429 6.28271ZM8.88095 7.52521L7.02595 9.38021L6.40179 8.76188L8.26262 6.90104L8.88095 7.52521Z" 
       fill={color}/>
    </svg>
  );
};

Infant.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
