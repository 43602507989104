export const formatDateToISOString = (date) => {
  if (!(date instanceof Date) && Number.isNaN(new Date(date).getTime())) {
    throw new Error("Invalid date input");
  }

  const d = new Date(date);
  const year = d.getUTCFullYear();
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const day = String(d.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
