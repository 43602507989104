import PropTypes from "prop-types";

export const HotelFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Hotel icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M2.45455 3.70625C3.13364 3.70625 3.68182 3.1535 3.68182 2.46875C3.68182 1.784 3.13364 1.23125 2.45455 1.23125C1.77545 1.23125 1.22727 1.784 1.22727 2.46875C1.22727 3.1535 1.77545 3.70625 2.45455 3.70625ZM9 1.23125H4.09091V4.11875H0.818182V0.40625H0V6.59375H0.818182V5.35625H8.18182V6.59375H9V1.23125Z" 
        fill={color}
      />
    </svg>
  );
};

HotelFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
