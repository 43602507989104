import PropTypes from "prop-types";

export const CheckIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Check Icon"
    >
      <title>Check Icon</title>
      <path
        d="M18.0394 1.875L6.81022 13.1042L1.70605 8"
        className={color}
        strokeWidth="2.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CheckIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
