import { useCallback } from "react";
import {
  AgentSelect,
  DestinationSelect,
  LoadingSpinner,
  ThemeButton,
} from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useSelector } from "react-redux";
import { MenuVerticalIcon, PlusIcon } from "assets/icons/icons";
import { HandleIcon } from "helpers/handleIcons";
import { DetailedSearchInput } from "components/general/filters/detailedSearchInput";

const INITIAL_FILTERS = {
  search: "",
  destination: "",
  agent: "",
};

export const PipelineFilters = ({
  filters,
  setFilters,
  setFilteredLeads,
  setIsModalOpen,
}) => {
  const { translate } = useDictionary();
  const { leads } = useSelector((state) => state.leads);
  const onChangeSelect = useCallback(
    (value, name) => {
      if (name in filters) {
        setFilters((prev) => ({ ...prev, [name]: value }));
      }
    },
    [filters, setFilters]
  );
  const handleClearAll = useCallback(() => {
    setFilteredLeads(leads);
    setFilters(INITIAL_FILTERS);
  }, [leads, setFilters, setFilteredLeads]);
  if (!leads.length) return <LoadingSpinner />; // If there are no leads, return null
  return (
    <div className="flex items-center gap-3 justify-between bg-cardsBackground border border-cardsStroke p-7 rounded-2xl">
      <DetailedSearchInput />
      <div className="flex items-center gap-3">
        <DestinationSelect
          value={filters.destination || translate("filters.destination")}
          className="w-40"
          onChange={(val) => onChangeSelect(val, "destination")}
        />
        <AgentSelect
          value={filters.agent || translate("filters.agent")}
          className="w-40"
          onChange={(val) => onChangeSelect(val, "agent")}
        />
        <ThemeButton type="warn" onClick={handleClearAll}>
          {translate("filters.clear")}
        </ThemeButton>
        <ThemeButton StartIcon={PlusIcon}>
          {translate("pipeline.main.createLead")}
        </ThemeButton>
        <div>
          <HandleIcon
            onClick={() => setIsModalOpen(true)}
            IconComponent={MenuVerticalIcon}
            iconColor={"off"}
            iconSize={"small"}
            state="button"
            hoverable
          />
        </div>
      </div>
    </div>
  );
};
