import { moon, sun } from "assets/icons/icons";
import Context from "context/context";
import { useState, useEffect, useContext, useCallback } from "react";

function ThemeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { setActiveTheme } = useContext(Context);

  const applyTheme = useCallback(
    (theme) => {
      if (theme === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      setActiveTheme(theme);
      setIsDarkMode(theme === "dark");
    },
    [setActiveTheme]
  );

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    applyTheme(savedTheme);
  }, [applyTheme]);

  const toggleTheme = useCallback(() => {
    const newTheme = isDarkMode ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    applyTheme(newTheme);
  }, [isDarkMode, applyTheme]);

  return (
    <div
      className={`w-16 h-8 flex items-center bg-toggleBg rounded-full pl-[10px] cursor-pointer transition-colors duration-300`}
      onClick={toggleTheme}
    >
      <div
        className={`transform bg-background p-[6px] rounded-full shadow-md transition-transform duration-300 flex items-center justify-center text-sm ${
          isDarkMode ? "translate-x-0" : "translate-x-6"
        }`}
      >
        {isDarkMode ? (
          <img src={moon} alt="darkmode" />
        ) : (
          <img src={sun} alt="lightmode" />
        )}
      </div>
    </div>
  );
}

export default ThemeToggle;
