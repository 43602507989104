export const handleEnd = async (
  event,
  containers,
  arrayMove,
  setContainers,
  findValueOfItems,
  setActiveId
) => {
  const { active, over } = event;

  // Handling Container Sorting
  if (
    active &&
    over &&
    active.id.toString().includes("container") &&
    over?.id.toString().includes("container") &&
    active.id !== over.id
  ) {
    // Find the index of the active and over container
    const activeContainerIndex = containers.findIndex(
      (container) => container.id === active.id
    );
    const overContainerIndex = containers.findIndex(
      (container) => container.id === over.id
    );
    // Swap the active and over container
    let newItems = [...containers];
    newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
    setContainers(newItems);
  }

  // Handling item Sorting
  if (
    active.id.toString().includes("item") &&
    over?.id.toString().includes("item") &&
    active &&
    over &&
    active.id !== over.id
  ) {
    // Find the active and over container
    const activeContainer = findValueOfItems(active.id, "item");
    const overContainer = findValueOfItems(over.id, "item");

    // If the active or over container is not found, return
    if (!activeContainer || !overContainer) return;
    // Find the index of the active and over container
    const activeContainerIndex = containers.findIndex(
      (container) => container.id === activeContainer.id
    );
    const overContainerIndex = containers.findIndex(
      (container) => container.id === overContainer.id
    );
    // Find the index of the active and over item
    const activeItemIndex = activeContainer.items.findIndex(
      (item) => item.id === active.id
    );
    const overItemIndex = overContainer.items.findIndex(
      (item) => item.id === over.id
    );

    // In the same container
    if (activeContainerIndex === overContainerIndex) {
      let newItems = [...containers];
      newItems[activeContainerIndex].items = arrayMove(
        newItems[activeContainerIndex].items,
        activeItemIndex,
        overItemIndex
      );
      setContainers(newItems);
    } else {
      // In different containers
      let newItems = [...containers];
      const [removeditem] = newItems[activeContainerIndex].items.splice(
        activeItemIndex,
        1
      );
      newItems[overContainerIndex].items.splice(overItemIndex, 0, removeditem);
      setContainers(newItems);
    }
  }
  // Handling item dropping into Container
  if (
    active.id.toString().includes("item") &&
    over?.id.toString().includes("container") &&
    active &&
    over &&
    active.id !== over.id
  ) {
    // Find the active and over container
    const activeContainer = findValueOfItems(active.id, "item");
    const overContainer = findValueOfItems(over.id, "container");

    // If the active or over container is not found, return
    if (!activeContainer || !overContainer) return;
    // Find the index of the active and over container
    const activeContainerIndex = containers.findIndex(
      (container) => container.id === activeContainer.id
    );
    const overContainerIndex = containers.findIndex(
      (container) => container.id === overContainer.id
    );
    // Find the index of the active and over item
    const activeItemIndex = activeContainer.items.findIndex(
      (item) => item.id === active.id
    );

    let newItems = [...containers];
    const [removeditem] = newItems[activeContainerIndex].items.splice(
      activeItemIndex,
      1
    );
    newItems[overContainerIndex].items.push(removeditem);
    setContainers(newItems);
  }
  setActiveId(null);
};
