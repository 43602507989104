import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Layout, Spin } from "antd";
import { MyHeader } from "./myHeader";
import { MySidebar } from "./mySidebar";
import { useDispatch } from "react-redux";
import { userDetails } from "features/auth/authSlice";

const { Content } = Layout;

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components to render
 * @param {string} props.title - title heading/title for the layout
 * @param {boolean} props.isLoading - Loading state flag
 * @param {string} props.activeKey - Active key for the sidebar menu. Required. Determines which menu item should be selected (e.g., "1" for dashboard)
 */

// MyLayout component that wraps the layout structure
export const MyLayout = ({ children, title, isLoading, activeKey }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userDetails()); // Fetch user details on component mount
  }, [dispatch]);

  return (
    <Layout className="min-h-screen">
      {/* Sidebar component with collapsed state */}
      <MySidebar activeKey={activeKey} />
      <Layout className="bg-background min-h-screen">
        {/* Header component with primary and collapsed state */}
        <MyHeader title={title} />
        {/* Main content area */}
        <Content className="p-3">
          {isLoading ? (
            <div className="h-[85vh] w-full flex items-center justify-center">
              <Spin />
            </div>
          ) : (
            children
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

MyLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  activeKey: PropTypes.string.isRequired,
};
