import { Select } from "antd";
import { useSelector } from "react-redux";
import "./filters.css";
import PropTypes from "prop-types";
import { useMemo } from "react";

export const DestinationSelect = ({ className, ...props }) => {
  const { leads, loading } = useSelector((state) => state.leads);
  const format = useMemo(() => {
    try {
      const destinations = leads
        .map((lead) => lead.destination_country)
        .filter(Boolean);
      const uniqueCountries = [...new Set(destinations)];
      return uniqueCountries.map((country) => ({
        value: country,
        label: country,
      }));
    } catch (error) {
      console.error("Error processing destinations:", error);
      return [];
    }
  }, [leads]);
  if (!leads.length) return null;
  return (
    <Select
      className={`${className} textfield h-10`}
      options={format}
      showSearch
      allowClear
      loading={loading}
      aria-label="Destination country selector"
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      {...props}
    />
  );
};

DestinationSelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

DestinationSelect.defaultProps = {
  className: "",
  placeholder: "Select a destination",
  disabled: false,
  loading: false,
  onChange: () => {},
  value: "",
};
