import { Col, Flex, Row } from "antd";
import { FlightDetailsArrow } from "assets/icons/icons";
import { travelerData } from "./constant";
import { useDictionary } from "dictionary/dictionaryContext";
import { useState } from "react";
import { Collapsing } from "components/general/helper/collapsing";
import { TravelersInfo } from "components/general/helper/travelersInfo";
import { ContentWithIcon } from "components/components";
import { chunkArray, getTransportDetails } from "./utils";
import { LocationDisplay } from "components/general/helper/locationDisplay";

export const TransportCard = ({data}) => {
  const [isCollapsedView, setIsCollapsedView] = useState(true);
  const { translate } = useDictionary();
    
  const transportData = data.details;
  const transportDetails = getTransportDetails(transportData);
  const transportDetailsNonCollapsedView = transportDetails.filter(detail => detail.value !== 'destination')
  const transportDetailsCollapsedView = transportDetails.filter(
    detail => detail.value === 'type' || detail.value === 'destination'
  )
  const chunkedDetails = chunkArray(transportDetailsNonCollapsedView, 2);
  const collapsedChunkedDetails = chunkArray(transportDetailsCollapsedView, 1);

  return (
    <Flex vertical style={{paddingRight: '8px'}}>
      {isCollapsedView
        ? (
          <Flex gap={20}>
            {collapsedChunkedDetails.map((chunk, index) => (
              <Row key={index} >
                {chunk.map(detail => (
                  <Col key={detail.content}>
                    <ContentWithIcon
                      icon={detail.icon}
                      content={translate(detail.content)}
                      gap={3}
                      textStyle={'text-primary subtext'}
                      iconStyle={'text-cardsCaption3'}
                    />
                    <div 
                      style={{padding: '3px 0 15px'}}
                      className='text-primary card-primary-small'
                    >
                      {detail.data}
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </Flex>
        )
        : (
          <Flex vertical >
            <LocationDisplay location={transportData.pickup_location} />

            <Flex 
              gap={14} 
              style={{
                padding: '18px 0 3px',
                width: '100%',
              }} 
              align='center'
            >
              <div style={{padding: '0 9px 15px'}} className='text-cardsCaption5'>
                <FlightDetailsArrow />
              </div>

              <Flex gap={20} align='end'>
                {chunkedDetails.map((chunk, index) => (
                  <Col key={index}>
                    {chunk.map(detail => (
                      <Col key={detail.content}>
                        <ContentWithIcon
                          icon={detail.icon}
                          content={translate(detail.content)}
                          gap={3}
                          textStyle={'text-primary subtext'}
                          iconStyle={'text-cardsCaption3'}
                        />
                        <div 
                          style={{padding: '3px 0 15px'}}
                          className='text-primary card-primary-small'
                        >
                          {detail.data}
                        </div>
                      </Col>
                    ))}
                  </Col>
                ))}
              </Flex>
            </Flex>

            <LocationDisplay location={transportData.dropoff_location} />
            
          </Flex>
        )
      }

      <Flex gap={15} justify='flex-end'>
        <TravelersInfo
          adults={travelerData.traveler_summary.adults}
          children={travelerData.traveler_summary.children}
          infants={travelerData.traveler_summary.infants}
        />

        <Collapsing
          isCollapsed={isCollapsedView}
          setIsCollapsed={setIsCollapsedView}
        />
      </Flex>
    </Flex> 
  );
};

