/** @typedef {import('react').FC} FC */

/** @typedef {Object} NotificationsIconProps
 *  @property {string} className - CSS class names to apply to the icon
 */
/** @type {typeof import('assets/icons/icons').NotificationsIcon} */
import { NotificationsIcon } from "assets/icons/icons";
import { HandleIcon } from "helpers/handleIcons";
import { useState } from "react";

/**
 * Renders a notification icon button.
 * @type {FC}
 */
export const Notifications = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const handleNotificationsClick = () => {
    // Implement notification panel opening logic
  };

  return (
    <div>
      {/* Render the NotificationsIcon component with a specific fill color */}
      <HandleIcon
        IconComponent={NotificationsIcon}
        iconColor={"off"}
        iconSize={"small"}
        state="button"
        onClick={handleNotificationsClick}
      />
      {unreadCount > 0 && (
        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1">
          {unreadCount}
        </span>
      )}
    </div>
  );
};
