import PropTypes from "prop-types";

export const Trip = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Trip icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <mask 
        id="mask0_1_6455" 
        style="mask-type:luminance" 
        maskUnits="userSpaceOnUse" 
        x="0" 
        y="0" 
        width={size} 
        height={size}
      >
        <path d="M8.07903 6.54067V8.20211C8.07903 9.06445 7.38958 9.76361 6.53885 9.76361C5.68811 9.76361 4.99867 9.06445 4.99867 8.20211L5.00151 2.92907C5.00151 1.99472 4.31206 1.2373 3.46133 1.2373C2.61059 1.2373 1.92114 1.99472 1.92114 2.92907V6.5409" 
          stroke="white" 
          stroke-linecap="round" 
          stroke-linejoin="round"
        />
        <path d="M9.49982 7.15836L8.07877 5.7373L6.65771 7.15836" 
          stroke="white" 
          stroke-linecap="round" 
          stroke-linejoin="round"
        />
        <path d="M9.49999 2.3564C9.49999 3.41153 8.07894 4.3158 8.07894 4.3158C8.07894 4.3158 6.65789 3.41153 6.65789 2.3564C6.65789 1.99664 6.80757 1.6518 7.07402 1.39719C7.34568 1.14085 7.70543 0.998661 8.07894 1.00001C8.45245 0.998661 8.8122 1.14085 9.08386 1.39719C9.2146 1.52054 9.3189 1.66917 9.39043 1.83406C9.46197 1.99895 9.49924 2.17666 9.49999 2.3564ZM3.3421 8.04061C3.3421 9.09574 1.92105 10 1.92105 10C1.92105 10 0.5 9.09574 0.5 8.04061C0.5 7.68084 0.649684 7.336 0.916131 7.0814C1.18779 6.82505 1.54754 6.68287 1.92105 6.68421C2.29456 6.68287 2.65431 6.82505 2.92597 7.0814C3.05674 7.20477 3.16106 7.35344 3.23259 7.51837C3.30413 7.68331 3.34139 7.86083 3.3421 8.04061Z" 
          fill={color} 
          stroke="white" 
          stroke-linecap="round" 
          stroke-linejoin="round"
        />
        <path d="M8.07866 2.89482C8.34027 2.89482 8.55235 2.68274 8.55235 2.42113C8.55235 2.15952 8.34027 1.94745 8.07866 1.94745C7.81706 1.94745 7.60498 2.15952 7.60498 2.42113C7.60498 2.68274 7.81706 2.89482 8.07866 2.89482Z" 
          fill="black"
        />
        <path d="M1.92119 8.57786C2.1828 8.57786 2.39488 8.36579 2.39488 8.10418C2.39488 7.84257 2.1828 7.63049 1.92119 7.63049C1.65959 7.63049 1.44751 7.84257 1.44751 8.10418C1.44751 8.36579 1.65959 8.57786 1.92119 8.57786Z" 
          fill="black"
        />
        </mask>
        <g mask="url(#mask0_1_6455)">
          <path d="M-0.684814 -0.184753L10.6836 -0.184753V11.1837H-0.684814L-0.684814 -0.184753Z" 
            fill={color}
          />
        </g>
    </svg>
  );
};

Trip.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
