export const ChatIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Chat Icon"
    >
      <path
        className={color}
        d="M5 16C4.71667 16 4.47917 15.9042 4.2875 15.7125C4.09583 15.5208 4 15.2833 4 15V13H17V4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5V17.575C20 18.025 19.7958 18.3375 19.3875 18.5125C18.9792 18.6875 18.6167 18.6167 18.3 18.3L16 16H5ZM4 11L1.7 13.3C1.38333 13.6167 1.02083 13.6875 0.6125 13.5125C0.204167 13.3375 0 13.025 0 12.575V1C0 0.716667 0.0958333 0.479167 0.2875 0.2875C0.479167 0.0958333 0.716667 0 1 0H14C14.2833 0 14.5208 0.0958333 14.7125 0.2875C14.9042 0.479167 15 0.716667 15 1V10C15 10.2833 14.9042 10.5208 14.7125 10.7125C14.5208 10.9042 14.2833 11 14 11H4ZM13 9V2H2V9H13Z"
      />
    </svg>
  );
};
