import PropTypes from "prop-types";

export const CurrencyUSD = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Currency USD icon"
      aria-hidden={true}
    >
      <path
        d="M0.0839844 7.75H1.25065C1.25065 8.38 2.04982 8.91667 3.00065 8.91667C3.95148 8.91667 4.75065 8.38 4.75065 7.75C4.75065 7.10833 4.14398 6.875 2.86065 6.56583C1.62398 6.25667 0.0839844 5.87167 0.0839844 4.25C0.0839844 3.20583 0.941484 2.31917 2.12565 2.02167V0.75H3.87565V2.02167C5.05982 2.31917 5.91732 3.20583 5.91732 4.25H4.75065C4.75065 3.62 3.95148 3.08333 3.00065 3.08333C2.04982 3.08333 1.25065 3.62 1.25065 4.25C1.25065 4.89167 1.85732 5.125 3.14065 5.43417C4.37732 5.74333 5.91732 6.12833 5.91732 7.75C5.91732 8.79417 5.05982 9.68083 3.87565 9.97833V11.25H2.12565V9.97833C0.941484 9.68083 0.0839844 8.79417 0.0839844 7.75Z" 
        fill={color}
      />
    </svg>
  );
};

CurrencyUSD.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
