import {  
  Airplane, 
  CarFilled, 
  ClockFilled, 
  Diamond, 
  Hashtag, 
  HotelFilled, 
  LocationFilled, 
  LuggageFilled, 
  Meal, 
  RoomsFilled, 
  StopsFilled, 
  Supplier 
} from "assets/icons/icons";

export const getTime = (data) => {
  switch (typeof data) {
    case 'string': //for date "2024-10-15T09:00:00+03:00"
    {
      const date = new Date(data);
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
    
    case 'number': //for duration in seconds
    {
      const hours = Math.floor(data / 3600);
      const minutes = Math.floor((data % 3600) / 60);
      return `${hours}h ${minutes}min`;
    }

    default:
      console.log("Unknown type");
  }
};

export const getFlightDetails = (data) => [
  {
    icon: <Airplane size={10}/>,
    content: 'proposal.timelineCard.airline',
    data: data.airline_name,
  },
  {
    icon: <StopsFilled size={10}/>,
    content: 'proposal.timelineCard.stops',
    data: 'TODO',
  },
  {
    icon: <Hashtag size={10}/>,
    content: 'proposal.timelineCard.number',
    data: data.flight_number,
  },
  {
    icon: <Diamond size={10}/>,
    content: 'proposal.timelineCard.class',
    data: data.class,
  },
  {
    icon: <ClockFilled size={10}/>,
    content: 'proposal.timelineCard.time',
    data: getTime(data.duration),
  },
  {
    icon: <LuggageFilled size={10}/>,
    content: 'proposal.timelineCard.luggage',
    data: data.luggage,
  },
];

export const getActivityDetails = (data) => [
  {
    icon: <ClockFilled size={10}/>,
    content: 'proposal.timelineCard.duration',
    data: data.duration, //TODO use getTime(data.duration) after implementing duration into API
  },
  {
    icon: <LocationFilled size={10}/>,
    content: 'proposal.timelineCard.location',
    data: data.location,
  },
];

export const getHotelDetails = (data) => [
  {
    value: 'class',
    icon: <Diamond size={10}/>,
    content: 'proposal.timelineCard.class',
    data: data.room_type
  },
  {
    value: 'rooms',
    icon: <RoomsFilled size={10}/>,
    content: 'proposal.timelineCard.rooms',
    data: data.rooms,
  },
  {
    value: 'name',
    icon: <HotelFilled size={10}/>,
    content: 'proposal.timelineCard.hotel',
    data: data.hotel_name,
  },
  {
    value: 'location',
    icon: <LocationFilled size={10}/>,
    content: 'proposal.timelineCard.location',
    data: data.location,
  },
  {
    value: 'meal',
    icon: <Meal size={10}/>,
    content: 'proposal.timelineCard.mealPlan',
    data: data.meal_plan,
  },
];

export const getTransportDetails = (data) => [
  {
    value: 'type',
    icon: <CarFilled size={10}/>,
    content: 'proposal.timelineCard.type',
    data: data.transfer_type
  },
  {
    value: 'class',
    icon: <Diamond size={10}/>,
    content: 'proposal.timelineCard.class',
    data: data.class,
  },
  {
    value: 'destination',
    icon: <LocationFilled size={10}/>,
    content: 'proposal.timelineCard.destination',
    data: data.dropoff_location,
  },
  {
    value: 'supplier',
    icon: <Supplier size={10}/>,
    content: 'proposal.timelineCard.supplier',
    data: data.supplier,
  },
  {
    value: 'plate',
    icon: <Hashtag size={10}/>,
    content: 'proposal.timelineCard.plate',
    data: data.plate,
  },
  {
    value: 'pickup',
    icon: <ClockFilled size={10}/>,
    content: 'proposal.timelineCard.pickup',
    data: getTime(data.pickup_time)
  },
];

export  const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};