import { TAGS } from "helpers/endpoints";
import api from "app/api"; // Axios instance

// Function to get tags
export const getTags = async () => {
  try {
    const response = await api.get(TAGS.GET_LIST); // Call the API

    const data = response.data;
    if (!data || typeof data !== "object") {
      throw new Error("Invalid response format received from server");
    }
    if (Array.isArray(data) && data.length === 0) {
      // Log empty array case if it's expected
      console.info("No tags found");
    }
    return data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        `Tags API error: ${error.response.status}`,
        error.response.data
      );
      throw new Error(`API error: ${error.response.status} - Request failed`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Tags API network error:", error.request);
      throw new Error("Network error: No response received from server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Tags API request setup error:", error.message);
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};
