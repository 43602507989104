import { createContext } from "react";

// Create a context for managing the active theme across the application
// This context provides the current theme and a function to update it
const ThemeContext = createContext({
  activeTheme: "light", // Default theme is 'light'
  setActiveTheme: null, // Set to null; should be provided by a context provider
  availableThemes: ["light", "dark"], // List of available themes
  menuCollapsed: false, // Default menu state is not collapsed
  setMenuCollapsed: null, // Set to null; should be provided by a context provider
});

export default ThemeContext;
