import { Dropdown } from "antd";
import {
  DoubleCheckIcon,
  MenuHorizontal,
  MoveColIcon,
  SortingIcon,
} from "assets/icons/icons";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";

export const PipelineStageDropdown = () => {
  const { translate } = useDictionary();
  const items = [
    {
      key: "1",
      type: "group",
      label: (
        <p className="textfield text-primary">
          {translate("dropdowns.pipelineStage.actions")}
        </p>
      ),
      children: [
        {
          key: "1-1",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsDefault">
                {translate("dropdowns.pipelineStage.selectAll")}
              </p>
              <HandleIcon
                IconComponent={DoubleCheckIcon}
                iconColor="off"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
        {
          key: "1-2",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsDefault">
                {translate("dropdowns.pipelineStage.moveTo")}
              </p>
              <HandleIcon
                IconComponent={MoveColIcon}
                iconColor="off"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      type: "group",
      label: (
        <p className="textfield text-primary">
          {translate("dropdowns.pipelineStage.sortBy")}
        </p>
      ),
      children: [
        {
          key: "1-1",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsActive">
                {translate("dropdowns.pipelineStage.bylastmessage")}
              </p>
              <HandleIcon
                IconComponent={SortingIcon}
                iconColor="on"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
        {
          key: "1-2",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsDefault">
                {translate("dropdowns.pipelineStage.bylastevent")}
              </p>
              <HandleIcon
                IconComponent={SortingIcon}
                iconColor="off"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
        {
          key: "1-3",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsDefault">
                {translate("dropdowns.pipelineStage.bycreationdate")}
              </p>
              <HandleIcon
                IconComponent={SortingIcon}
                iconColor="off"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
        {
          key: "1-4",
          label: (
            <div className="flex items-center gap-2 py-1">
              <p className="textfield text-iconsDefault">
                {translate("dropdowns.pipelineStage.bysale")}
              </p>
              <HandleIcon
                IconComponent={SortingIcon}
                iconColor="off"
                iconSize="small"
                state="simple"
              />
            </div>
          ),
        },
      ],
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomLeft"
    >
      <div>
        <HandleIcon
          IconComponent={MenuHorizontal}
          iconColor="off"
          iconSize="small"
          state="simple"
        />
      </div>
    </Dropdown>
  );
};
