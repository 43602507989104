export const PatientListIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5417 10.4167C12.6736 10.4167 11.9358 10.1128 11.3281 9.50521C10.7205 8.89757 10.4167 8.15972 10.4167 7.29167C10.4167 6.42361 10.7205 5.68576 11.3281 5.07812C11.9358 4.47049 12.6736 4.16667 13.5417 4.16667C14.4097 4.16667 15.1476 4.47049 15.7552 5.07812C16.3628 5.68576 16.6667 6.42361 16.6667 7.29167C16.6667 8.15972 16.3628 8.89757 15.7552 9.50521C15.1476 10.1128 14.4097 10.4167 13.5417 10.4167ZM7.29167 16.6667V14.6875C7.29167 14.3229 7.37847 13.9757 7.55208 13.6458C7.72569 13.316 7.96875 13.0556 8.28125 12.8646C9.0625 12.3958 9.89149 12.0443 10.7682 11.8099C11.645 11.5755 12.5694 11.4583 13.5417 11.4583C14.5139 11.4583 15.4384 11.5755 16.3151 11.8099C17.1918 12.0443 18.0208 12.3958 18.8021 12.8646C19.1146 13.0556 19.3576 13.316 19.5312 13.6458C19.7049 13.9757 19.7917 14.3229 19.7917 14.6875V16.6667H7.29167ZM9.53125 14.5833H17.5521C16.9444 14.2361 16.3021 13.9757 15.625 13.8021C14.9479 13.6285 14.2535 13.5417 13.5417 13.5417C12.8299 13.5417 12.1354 13.6285 11.4583 13.8021C10.7812 13.9757 10.1389 14.2361 9.53125 14.5833ZM13.5417 8.33333C13.8368 8.33333 14.0842 8.23351 14.2839 8.03385C14.4835 7.8342 14.5833 7.58681 14.5833 7.29167C14.5833 6.99653 14.4835 6.74913 14.2839 6.54948C14.0842 6.34983 13.8368 6.25 13.5417 6.25C13.2465 6.25 12.9991 6.34983 12.7995 6.54948C12.5998 6.74913 12.5 6.99653 12.5 7.29167C12.5 7.58681 12.5998 7.8342 12.7995 8.03385C12.9991 8.23351 13.2465 8.33333 13.5417 8.33333ZM0 10.4167V8.33333H8.33333V10.4167H0ZM0 2.08333V0H12.5V2.08333H0ZM8.4375 6.25H0V4.16667H9.375C9.13194 4.46181 8.93663 4.78299 8.78906 5.13021C8.64149 5.47743 8.52431 5.85069 8.4375 6.25Z"
        className={color}
      />
    </svg>
  );
};
