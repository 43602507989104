import PropTypes from "prop-types";
import { PlusIcon } from "assets/icons/icons";
import { ThemeButton } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";

export const UnassignedActions = ({ onAdd }) => {
  const { translate } = useDictionary();
  return (
    <ThemeButton
      StartIcon={PlusIcon}
      startIconColor="fill-ctaBg"
      type="dashed"
      onClick={onAdd}
      aria-label={translate("pipeline.main.additem")}
    >
      {translate("pipeline.main.additem")}
    </ThemeButton>
  );
};

UnassignedActions.propTypes = {
  onAdd: PropTypes.func.isRequired,
};
