import { HandleIcon } from "helpers/handleIcons";
import PropTypes from "prop-types";

const RowContainer = ({ children }) => (
  <div className="flex items-center gap-3">{children}</div>
);

const TextContainer = ({ children }) => <div>{children}</div>;

const Title = ({ children }) => <p className="text-primary">{children}</p>;

const Count = ({ children }) => (
  <p className="text-cardsCaption2 card-caption">{children}</p>
);

export const ExpandableRow = ({
  Icon,
  title,
  count,
  iconColor = "on",
  iconSize = "large",
  iconState = "button",
}) => {
  return (
    <RowContainer>
      <HandleIcon
        IconComponent={Icon}
        iconColor={iconColor}
        iconSize={iconSize}
        state={iconState}
      />
      <TextContainer>
        <Title>{title}</Title>
        <Count>{count}</Count>
      </TextContainer>
    </RowContainer>
  );
};

ExpandableRow.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

RowContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

TextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

Count.propTypes = {
  children: PropTypes.node.isRequired,
};
