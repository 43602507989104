import PropTypes from "prop-types";

export const ChevronDown = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={Math.round((size * 7) / 12)}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Chevron Down"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Chevron Down</title>
      <path
        d="M5.9998 6.95039C5.86647 6.95039 5.74147 6.92956 5.6248 6.88789C5.50814 6.84622 5.3998 6.77539 5.2998 6.67539L0.699805 2.07539C0.516471 1.89206 0.424805 1.65872 0.424805 1.37539C0.424805 1.09206 0.516471 0.858724 0.699805 0.675391C0.883138 0.492057 1.11647 0.400391 1.3998 0.400391C1.68314 0.400391 1.91647 0.492057 2.0998 0.675391L5.9998 4.57539L9.89981 0.675391C10.0831 0.492057 10.3165 0.400391 10.5998 0.400391C10.8831 0.400391 11.1165 0.492057 11.2998 0.675391C11.4831 0.858724 11.5748 1.09206 11.5748 1.37539C11.5748 1.65872 11.4831 1.89206 11.2998 2.07539L6.6998 6.67539C6.5998 6.77539 6.49147 6.84622 6.3748 6.88789C6.25814 6.92956 6.13314 6.95039 5.9998 6.95039Z"
        className={color}
      />
    </svg>
  );
};

ChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number.isRequired,
};

ChevronDown.defaultProps = {
  color: "currentColor",
};
