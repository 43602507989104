export const MenuFold = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1.08594H4.98477M13 4.00057H4.98477M13 6.9152H4.98477"
        className={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.09375 5.0932L1.00076 4.00021L2.09375 2.90723"
        className={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
