import PropTypes from "prop-types";

export const Point = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Point icon"
      aria-hidden={true}
    //   style={{display: 'block'}}
    >
      <circle cx="7" cy="7" r="7" fill="#E1ECFB"/>
      <circle cx="7" cy="7" r="3" fill={color}/>
    </svg>
  );
};

Point.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
