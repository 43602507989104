/**
 * Provides dictionary context for translation functionality.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 */

import React, { createContext, useContext, useState } from "react";
import { DEFAULT_LANGUAGE, languages } from "./dictionary";

// Create a context for the dictionary
const dictionaryContext = createContext();

// Provider component to wrap around parts of the app that need access to the dictionary
export const DictionaryProvider = ({ children }) => {
  // State to hold the current language, default is "en"
  const [dictionary, setDictionary] = useState(DEFAULT_LANGUAGE);

  // Function to translate a key based on the current language, now supporting nested keys
  const translate = (key) => {
    const keys = key.split("."); // Split the key by dot notation
    let result = languages[dictionary]; // Get the dictionary for the current language
    for (const k of keys) {
      // Loop through each part of the key
      if (result && result[k]) {
        result = result[k]; // Go deeper into the object
      } else {
        return key; // Return the original key if it doesn't exist
      }
    }
    return result; // Return the translation
  };

  // Function to set the current language
  const setLanguage = (language) => {
    setDictionary(language); // Set language based on dropdown selection
  };

  // Provide the translate function, setLanguage function, and current language to the context
  return (
    <dictionaryContext.Provider value={{ translate, setLanguage, dictionary }}>
      {children}
    </dictionaryContext.Provider>
  );
};

/**
 * Custom hook to access the dictionary context.
 * @returns {Object} An object containing translate and setLanguage functions, and the current dictionary.
 */

// Custom hook to use the DictionaryContext
export const useDictionary = () => {
  return useContext(dictionaryContext);
};
