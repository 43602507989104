import PropTypes from "prop-types";

export const CurrencyBox = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Currency Box icon"
      aria-hidden={true}
    >
      <path d="M6 10.6667H7.2C7.2 11.2667 8.022 11.7778 9 11.7778C9.978 11.7778 10.8 11.2667 10.8 10.6667C10.8 10.0556 10.176 9.83333 8.856 9.53889C7.584 9.24444 6 8.87778 6 7.33333C6 6.33889 6.882 5.49444 8.1 5.21111V4H9.9V5.21111C11.118 5.49444 12 6.33889 12 7.33333H10.8C10.8 6.73333 9.978 6.22222 9 6.22222C8.022 6.22222 7.2 6.73333 7.2 7.33333C7.2 7.94444 7.824 8.16667 9.144 8.46111C10.416 8.75556 12 9.12222 12 10.6667C12 11.6611 11.118 12.5056 9.9 12.7889V14H8.1V12.7889C6.882 12.5056 6 11.6611 6 10.6667Z" 
        fill={color}
      />
        <g clip-path="url(#clip0_68_1412)">
          <path d="M1.5 9C1.5 5.46447 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46447 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4017 2.59835C16.5 3.6967 16.5 5.46447 16.5 9C16.5 12.5355 16.5 14.3033 15.4017 15.4017C14.3033 16.5 12.5355 16.5 9 16.5C5.46447 16.5 3.6967 16.5 2.59835 15.4017C1.5 14.3033 1.5 12.5355 1.5 9Z" 
            stroke={color}
            stroke-width="1.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_68_1412">
            <rect width="18" height="18" fill="none"/>
          </clipPath>
        </defs>
    </svg>
  );
};

CurrencyBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
