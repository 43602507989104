import { Flex } from "antd";
import PropTypes from "prop-types";
import React from "react";

// ContentWithIcon component
export const ContentWithIcon = React.memo(
  ({ icon, content, gap = "middle", textStyle = "", iconStyle = "" }) => {
    return !icon || !content ? null : (
      <Flex
        gap={gap}
        align="center"
        aria-label={`${content} with icon`}
        data-testid="content-with-icon"
      >
        <div className={iconStyle}>{icon}</div>

        <div className={`text-primary textfield ${textStyle}`}>{content}</div>
      </Flex>
    );
  }
);

ContentWithIcon.displayName = "ContentWithIcon";

ContentWithIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  content: PropTypes.string.isRequired,
  gap: PropTypes.string,
  textStyle: PropTypes.string,
  iconStyle: PropTypes.string,
};
