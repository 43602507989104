import { Avatar, Badge, Card, Divider, Flex, Input, Tag, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { MailOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Adult,
  Calendar,
  CurrencyUSD,
  Infant,
  Location,
  Child,
  gold,
  silver,
  bronze,
  platinum,
} from "assets/icons/icons";
import { avatarSrc, ICON_SIZE, travelerData } from "./constant";
import { ContentWithIcon } from "../../general/helper/contentWithIcon";
import { useDictionary } from "dictionary/dictionaryContext";
import { PipelineLeadDropdown } from "components/components";

// LeadCard component
export const LeadCard = ({ data, isEditTag }) => {
  const [tags, setTags] = useState(data?.tags);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const { translate } = useDictionary();

  const formattedDate = data?.arrival_date
    ? new Date(data.arrival_date).toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "N/A";

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTagId) => {
    const newTags = tags.filter((tag) => tag.tag_id !== removedTagId);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (!inputValue) return;

    // Mocked data to save a new tag
    const newTag = {
      tag_name: inputValue,
      tag_id: Date.now(),
    };
    const isNewTagUnique = tags.every(
      (tag) => tag.tag_name !== newTag.tag_name
    );

    if (isNewTagUnique) {
      setTags([...tags, newTag]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const ratingImages = {
    gold,
    silver,
    bronze,
    platinum,
  };

  return (
    <Card
      hoverable
      bordered={false}
      style={{ width: "100%", maxWidth: "350px", height: 260 }}
      className="bg-cardsBackground"
      bodyStyle={{ padding: 0 }}
    >
      <Flex gap={16} vertical style={{ padding: "16px 16px 8px 16px" }}>
        <Flex justify="space-between">
          <Flex gap={12}>
            <Badge
              count={
                <div
                  style={{
                    width: "19px",
                    height: "17px",
                    borderRadius: "25%",
                    transform: "rotate(45deg)",
                    backgroundColor: "white",
                  }}
                >
                  {ratingImages[data?.rating] && (
                    <img
                      src={ratingImages[data?.rating]}
                      alt={data?.rating}
                      style={{
                        transform: "rotate(45deg)",
                      }}
                    />
                  )}
                </div>
              }
              offset={[3, ICON_SIZE.AVATAR - 3]}
            >
              <Badge
                // count={
                //   <img
                //     src={flagMocked}
                //     alt="flag icon"
                //     style={{
                //       borderRadius: "50%",
                //       width: "16px",
                //       height: "16px",
                //     }}
                //   />
                // }
                offset={[-ICON_SIZE.AVATAR + 10, ICON_SIZE.AVATAR - 5]}
              >
                {avatarSrc ? (
                  <Avatar src={avatarSrc} size={ICON_SIZE.AVATAR} />
                ) : (
                  <Avatar
                    style={{ backgroundColor: travelerData.color }}
                    size={ICON_SIZE.AVATAR}
                  >
                    {`${data?.name?.substring(0, 1) || ""} ${
                      data?.surname?.substring(0, 1) || ""
                    }`}
                  </Avatar>
                )}
              </Badge>
            </Badge>

            <Flex vertical>
              <Flex className="text-primary commission-card">
                {`${data?.name} ${data?.surname}`}
              </Flex>

              <Flex vertical>
                <div className="text-cardsHeaders subtext">
                  {translate("proposal.leadCard.trip")}
                </div>

                <Flex gap={8}>
                  <ContentWithIcon
                    icon={<Location size={16} />}
                    content={data?.destination_country}
                    gap={0}
                    textStyle={"text-cardsCaption4 card-primary"}
                    iconStyle={"text-cardsCaption3"}
                  />

                  <ContentWithIcon
                    icon={<Calendar size={16} />}
                    content={`${data?.duration_trip_days} ${translate(
                      "proposal.leadCard.days"
                    )}`}
                    gap={0}
                    textStyle={"text-cardsCaption4 card-primary"}
                    iconStyle={"text-cardsCaption3"}
                  />

                  <ContentWithIcon
                    icon={<CurrencyUSD size={16} />}
                    content={data?.budget_per_person}
                    gap={0}
                    textStyle={"text-cardsCaption4 card-primary"}
                    iconStyle={"text-cardsCaption3"}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="flex-end" align="flex-start">
            <Flex>
              <Tag className="bg-background" title="Lead ID">
                <div className="text-cardsHeaders subtext" aria-label="Lead id">
                  {data?.lead_id}
                </div>
              </Tag>
              <PipelineLeadDropdown />
            </Flex>
          </Flex>
        </Flex>

        <Flex gap={8} vertical>
          <ContentWithIcon
            icon={
              <PhoneOutlined
                style={{
                  fontSize: ICON_SIZE.MEDIUM,
                  transform: "rotate(90deg)",
                }}
              />
            }
            content={data?.mobile}
            gap={6}
            textStyle={"text-cardsCaption4 card-primary"}
            iconStyle={"text-cardsCaption3"}
          />

          <ContentWithIcon
            icon={<MailOutlined style={{ fontSize: ICON_SIZE.MEDIUM }} />}
            content={data?.email}
            gap={6}
            textStyle={"text-cardsCaption4 card-primary"}
            iconStyle={"text-cardsCaption3"}
          />
        </Flex>

        <Flex gap={"4px 0"} wrap>
          {tags?.map((tag) => {
            const isLongTag = tag.tag_name.length > 20;
            const tagElem = (
              <Tag
                key={tag.tag_id}
                color={tag.tag_color}
                onClose={() => handleClose(tag.tag_id)}
                closable={isEditTag}
                style={{
                  fontSize: "12px",
                  padding: "2px 10px",
                  height: "26px",
                }}
                className="card-primary"
              >
                {isLongTag ? `${tag.tag_name.slice(0, 20)}...` : tag.tag_name}
              </Tag>
            );

            return isLongTag ? (
              <Tooltip title={tag.tag_name} key={tag.tag_id}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {isEditTag && (
            <>
              {inputVisible ? (
                <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                  style={{ width: "80px" }}
                />
              ) : (
                <Tag
                  className="card-primary"
                  onClick={showInput}
                  style={{
                    fontSize: "12px",
                    padding: "2px 10px",
                    height: "26px",
                  }}
                >
                  {`${translate("proposal.leadCard.add")} `}
                  <PlusOutlined />
                </Tag>
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Divider className="bg-cardsStroke my-2" />

      <Flex justify="space-between" style={{ padding: "8px 16px 8px 16px" }}>
        <Flex vertical>
          <div className="text-cardsHeaders subtext">
            {translate("proposal.leadCard.travellers")}
          </div>

          <Flex gap={8}>
            <ContentWithIcon
              icon={<Adult size={16} />}
              content={data?.traveler_summary.adults}
              gap={4}
              textStyle={"text-cardsCaption4 card-primary"}
              iconStyle={"text-cardsCaption3"}
            />

            <ContentWithIcon
              icon={<Child size={16} />}
              content={data?.traveler_summary.children}
              gap={4}
              textStyle={"text-cardsCaption4 card-primary"}
              iconStyle={"text-cardsCaption3"}
            />

            <ContentWithIcon
              icon={<Infant size={16} />}
              content={data?.traveler_summary.infants}
              gap={4}
              textStyle={"text-cardsCaption4 card-primary"}
              iconStyle={"text-cardsCaption3"}
            />
          </Flex>
        </Flex>

        <Flex vertical>
          <div className="text-cardsHeaders subtext">
            {translate("proposal.leadCard.arrivalDate")}
          </div>

          <Flex gap={4}>
            <ContentWithIcon
              icon={<Calendar size={16} />}
              content={formattedDate}
              gap={4}
              textStyle={"text-cardsCaption4 card-primary"}
              iconStyle={"text-cardsCaption5"}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
