import { useCallback } from "react";
import {
  AgentSelect,
  DestinationSelect,
  SearchInput,
  ThemeButton,
} from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useSelector } from "react-redux";

export const DashboardFilters = ({ filters, setFilters, setFilteredLeads }) => {
  const { translate } = useDictionary();
  const { leads } = useSelector((state) => state.leads);
  const onChange = useCallback(
    (e) => {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    },
    [filters, setFilters]
  );
  const onChangeSelect = useCallback(
    (value, name) => {
      setFilters({ ...filters, [name]: value });
    },
    [filters, setFilters]
  );
  const handleClearAll = useCallback(() => {
    setFilteredLeads(leads);
    setFilters({ search: "", destination: "", agent: "" });
  }, [leads, setFilters, setFilteredLeads]);
  if (!leads.length) return null; // If there are no leads, return null
  return (
    <div className="flex items-center justify-between">
      <SearchInput
        className="w-64"
        value={filters.search}
        onChange={onChange}
        name="search"
      />
      <div className="flex items-center gap-3">
        <DestinationSelect
          value={filters.destination || translate("filters.destination")}
          className="w-40"
          onChange={(val) => onChangeSelect(val, "destination")}
        />
        <AgentSelect
          value={filters.agent || translate("filters.agent")}
          className="w-40"
          onChange={(val) => onChangeSelect(val, "agent")}
        />
        <ThemeButton type="warn" onClick={handleClearAll}>
          {translate("filters.clear")}
        </ThemeButton>
      </div>
    </div>
  );
};
