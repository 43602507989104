import PropTypes from "prop-types";

export const SortingIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size * 0.75} // Maintain 3:4 ratio
      height={size}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Sorting icon"
    >
      <title>Sorting icon</title>
      {/* Up and down arrows indicating sort functionality */}
      <path
        d="M3 8.75V3.36875L1.06875 5.3L0 4.25L3.75 0.5L7.5 4.25L6.43125 5.3L4.5 3.36875V8.75H3ZM8.25 15.5L4.5 11.75L5.56875 10.7L7.5 12.6313V7.25H9V12.6313L10.9312 10.7L12 11.75L8.25 15.5Z"
        className={color}
      />
    </svg>
  );
};

SortingIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
