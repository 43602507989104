import { Avatar, Dropdown } from "antd";
import { useDictionary } from "dictionary/dictionaryContext";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export const ProfileDropdown = () => {
  const { user } = useSelector((state) => state.auth ?? {});
  const { translate } = useDictionary();
  const getInitials = (name) => {
    if (!name) return "";
    const parts = name.split(" ");
    return parts
      .filter(Boolean)
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
  };
  const fullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim();
  const initials = getInitials(fullName);
  const handleLogout = () => {
    localStorage.removeItem("token");
  };

  const menuItems = [
    {
      key: "profile",
      path: "/profile",
      label: translate("layout.header.profile"),
    },
    { key: "help", path: "/help", label: translate("layout.header.help") },
    {
      key: "logout",
      path: "/login",
      label: translate("layout.header.logout"),
      onClick: handleLogout,
    },
  ];

  const items = menuItems.map(({ key, path, label, onClick }) => ({
    key,
    label: (
      <NavLink className="textfield" to={path} onClick={onClick}>
        {label}
      </NavLink>
    ),
  }));

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
      aria-label="User profile menu"
    >
      <Avatar
        className="cursor-pointer card-primary bg-cardsBackground border border-cardsStroke text-primary h-10 w-10"
        role="button"
        aria-haspopup="true"
        tabIndex={0}
      >
        {initials}
      </Avatar>
    </Dropdown>
  );
};
