import PropTypes from "prop-types";

export const ActiveHumanFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Active Human icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M5.61018 1.62791C6.0838 1.62791 6.47921 1.25349 6.47921 0.813953C6.47921 0.59808 6.38765 0.391047 6.22467 0.238401C6.0617 0.0857555 5.84066 0 5.61018 0C5.12787 0 4.74115 0.35814 4.74115 0.813953C4.74115 1.25349 5.12787 1.62791 5.61018 1.62791ZM4.04159 7.27674L4.4761 5.49419L5.39292 6.30814V8.75H6.26195V5.69767L5.34513 4.88372L5.61018 3.66279C5.90791 3.98232 6.27523 4.23844 6.68744 4.41394C7.09965 4.58943 7.5472 4.68023 8 4.68023V3.86628C7.17008 3.86628 6.4792 3.4593 6.12725 2.8814L5.69274 2.23837C5.51893 1.98605 5.25822 1.8314 4.95841 1.8314C4.82371 1.8314 4.74115 1.86395 4.60646 1.86395L2.35133 2.7593V4.68023H3.22036V3.28837L3.99814 3.00349L3.30292 6.30814L1.17381 5.90116L1 6.71512L4.04159 7.27674Z" 
        fill={color}
      />
    </svg>
  );
};

ActiveHumanFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
