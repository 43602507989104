import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { getTasks } from "./tasksApi";
import { STATUS } from "constant/constant";
import { handleError } from "helpers/handleError";

// Async thunk for fetching tasks
export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getTasks(); // Call the API
      return data; // Return the tasks data to the thunk
    } catch (error) {
      const userFriendlyMessage = handleError(error, "Failed to fetch tasks");
      return rejectWithValue(userFriendlyMessage);
    }
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    tasks: [],
    status: STATUS.IDLE,
    error: null,
    pendingTasks: [],
  },
  reducers: {
    addTaskOptimistically: (state, action) => {
      const tempTask = {
        ...action.payload,
        id: `temp-${Date.now()}`,
        pending: true,
      };
      state.pendingTasks.push(tempTask);
      state.tasks.push(tempTask);
    },
    removeOptimisticTask: (state, action) => {
      const tempId = action.payload;
      state.tasks = state.tasks.filter((task) => task.id !== tempId);
      state.pendingTasks = state.pendingTasks.filter(
        (task) => task.id !== tempId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.tasks = action.payload; // Store tasks in state
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload || "Failed to fetch tasks"; // Handle the error
      });
  },
});

const selectTasksState = (state) => state.tasks;
export const selectTasks = createSelector(
  [selectTasksState],
  (tasksState) => tasksState.tasks
);

export const selectTasksStatus = createSelector(
  [selectTasksState],
  (tasksState) => tasksState.status
);

export const selectTasksError = createSelector(
  [selectTasksState],
  (tasksState) => tasksState.error
);

export default tasksSlice.reducer;
