import PropTypes from "prop-types";

export const MoveColIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Move column"
    >
      <title>Move column</title>
      <path
        d="M15.0312 6.3125C15.0312 6.53628 14.9424 6.75089 14.7841 6.90912C14.6259 7.06736 14.4113 7.15625 14.1875 7.15625C13.9637 7.15625 13.7491 7.06736 13.5909 6.90912C13.4326 6.75089 13.3438 6.53628 13.3438 6.3125V3.85156L9.15945 8.03586C9.00095 8.19437 8.78596 8.28342 8.5618 8.28342C8.33763 8.28342 8.12265 8.19437 7.96414 8.03586C7.80563 7.87735 7.71658 7.66237 7.71658 7.4382C7.71658 7.21404 7.80563 6.99906 7.96414 6.84055L12.1484 2.65625H9.6875C9.46372 2.65625 9.24911 2.56736 9.09088 2.40912C8.93265 2.25089 8.84375 2.03628 8.84375 1.8125C8.84375 1.58872 8.93265 1.37411 9.09088 1.21588C9.24911 1.05764 9.46372 0.96875 9.6875 0.96875H14.1875C14.4113 0.96875 14.6259 1.05764 14.7841 1.21588C14.9424 1.37411 15.0312 1.58872 15.0312 1.8125V6.3125ZM11.9375 8C11.7137 8 11.4991 8.0889 11.3409 8.24713C11.1826 8.40536 11.0938 8.61997 11.0938 8.84375V13.3438H2.65625V4.90625H7.15625C7.38003 4.90625 7.59464 4.81736 7.75287 4.65912C7.91111 4.50089 8 4.28628 8 4.0625C8 3.83872 7.91111 3.62411 7.75287 3.46588C7.59464 3.30764 7.38003 3.21875 7.15625 3.21875H2.375C2.00204 3.21875 1.64435 3.36691 1.38063 3.63063C1.11691 3.89435 0.96875 4.25204 0.96875 4.625V13.625C0.96875 13.998 1.11691 14.3556 1.38063 14.6194C1.64435 14.8831 2.00204 15.0312 2.375 15.0312H11.375C11.748 15.0312 12.1056 14.8831 12.3694 14.6194C12.6331 14.3556 12.7812 13.998 12.7812 13.625V8.84375C12.7812 8.61997 12.6924 8.40536 12.5341 8.24713C12.3759 8.0889 12.1613 8 11.9375 8Z"
        className={color}
      />
    </svg>
  );
};

MoveColIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
