import PropTypes from "prop-types";

export const CarFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Car icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M8.7 3.85901H8.295L7.25325 1.52982C7.20611 1.42444 7.12922 1.3349 7.03191 1.27207C6.93461 1.20923 6.82105 1.17578 6.705 1.17578H2.295C2.17895 1.17578 2.0654 1.20923 1.96809 1.27207C1.87078 1.3349 1.79389 1.42444 1.74675 1.52982L0.705 3.85901H0.3C0.220435 3.85901 0.144129 3.89042 0.087868 3.94633C0.031607 4.00224 0 4.07808 0 4.15715C0 4.23622 0.031607 4.31205 0.087868 4.36796C0.144129 4.42387 0.220435 4.45528 0.3 4.45528H0.6V7.43665C0.6 7.59479 0.663214 7.74646 0.775736 7.85828C0.888258 7.9701 1.04087 8.03292 1.2 8.03292H2.1C2.25913 8.03292 2.41174 7.9701 2.52426 7.85828C2.63679 7.74646 2.7 7.59479 2.7 7.43665V7.13851H6.3V7.43665C6.3 7.59479 6.36321 7.74646 6.47574 7.85828C6.58826 7.9701 6.74087 8.03292 6.9 8.03292H7.8C7.95913 8.03292 8.11174 7.9701 8.22426 7.85828C8.33679 7.74646 8.4 7.59479 8.4 7.43665V4.45528H8.7C8.77956 4.45528 8.85587 4.42387 8.91213 4.36796C8.96839 4.31205 9 4.23622 9 4.15715C9 4.07808 8.96839 4.00224 8.91213 3.94633C8.85587 3.89042 8.77956 3.85901 8.7 3.85901ZM2.7 5.64783H1.8C1.72044 5.64783 1.64413 5.61642 1.58787 5.56051C1.53161 5.5046 1.5 5.42876 1.5 5.34969C1.5 5.27062 1.53161 5.19479 1.58787 5.13888C1.64413 5.08297 1.72044 5.05156 1.8 5.05156H2.7C2.77957 5.05156 2.85587 5.08297 2.91213 5.13888C2.96839 5.19479 3 5.27062 3 5.34969C3 5.42876 2.96839 5.5046 2.91213 5.56051C2.85587 5.61642 2.77957 5.64783 2.7 5.64783ZM7.2 5.64783H6.3C6.22044 5.64783 6.14413 5.61642 6.08787 5.56051C6.03161 5.5046 6 5.42876 6 5.34969C6 5.27062 6.03161 5.19479 6.08787 5.13888C6.14413 5.08297 6.22044 5.05156 6.3 5.05156H7.2C7.27957 5.05156 7.35587 5.08297 7.41213 5.13888C7.46839 5.19479 7.5 5.27062 7.5 5.34969C7.5 5.42876 7.46839 5.5046 7.41213 5.56051C7.35587 5.61642 7.27957 5.64783 7.2 5.64783ZM1.36163 3.85901L2.295 1.77205H6.705L7.63838 3.85901H1.36163Z" 
        fill={color}
      />
    </svg>
  );
};

CarFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
