import React from "react";
import { Flex } from "antd";
import PropTypes from "prop-types";
import { ContentWithIcon } from "./contentWithIcon";
import { Adult, Child, Infant } from "assets/icons/icons";

const commonStyles = {
  gap: 4,
  textStyle: 'text-cardsCaption4 card-primary',
  iconStyle: 'text-cardsCaption3'
};

export const TravelersInfo = React.memo(({ adults = 0, children = 0, infants = 0 }) => (
  <Flex gap={8}>
    <ContentWithIcon 
      icon={<Adult size={16}/>}
      content={adults}
      {...commonStyles}
    />

    <ContentWithIcon 
      icon={<Child size={16}/>}
      content={children}
      {...commonStyles}
    />

    <ContentWithIcon 
      icon={<Infant size={16}/>}
      content={infants}
      {...commonStyles}
    />
  </Flex>
));

TravelersInfo.propTypes = {
  adults: PropTypes.number,
  children: PropTypes.number,
  infants: PropTypes.number,
};