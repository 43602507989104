import PropTypes from "prop-types";

/**
 * Edit icon component
 * @param {Object} props - The component props
 * @param {string} [props.color="currentColor"] - The icon color
 * @param {number} [props.size=24] - The icon size in pixels
 * @returns {JSX.Element} An SVG edit icon element
 */

export const EditIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Edit"
    >
      <title>Edit</title>
      <path
        d="M0 11V9H10V11H0ZM2 7H2.7L6.6 3.1125L5.8875 2.4L2 6.3V7ZM1 8V5.875L6.6 0.2875C6.69167 0.195833 6.79792 0.125 6.91875 0.075C7.03958 0.025 7.16667 0 7.3 0C7.43333 0 7.5625 0.025 7.6875 0.075C7.8125 0.125 7.925 0.2 8.025 0.3L8.7125 1C8.8125 1.09167 8.88542 1.2 8.93125 1.325C8.97708 1.45 9 1.57917 9 1.7125C9 1.8375 8.97708 1.96042 8.93125 2.08125C8.88542 2.20208 8.8125 2.3125 8.7125 2.4125L3.125 8H1Z"
        className={color}
      />
    </svg>
  );
};

EditIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
