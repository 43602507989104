import { Table } from "antd";
import "./themeTable.module.css";

/**
 * @typedef {Object} ExpandableConfig
 * @property {Function} expandedRowRender - Render content in expanded row
 * @property {Function} [rowExpandable] - Whether row can be expanded
 * @property {boolean} [defaultExpandAllRows] - Expand all rows by default
 */

/**
 * @param {Object} props
 * @param {Array} props.data - Table data source
 * @param {Array} props.columns - Table columns configuration
 * @param {ExpandableConfig} [props.expandable] - Configuration for expandable rows
 */

export const ThemeTable = ({
  data,
  columns,
  expandable,
  paginationThreshold = 10,
  paginationConfig = {
    position: ["bottomRight"],
    showSizeChanger: true,
    showQuickJumper: true,
  },
}) => {
  return (
    <Table
      className="textfield"
      rowClassName={(record, index) =>
        index % 2 === 0
          ? "!bg-cellBg1 !text-primary hover:!bg-hover"
          : "!bg-cellBg2 !text-primary hover:!bg-hover"
      }
      columns={columns}
      dataSource={data?.map((item, index) => ({ ...item, key: index })) ?? []}
      components={{
        header: {
          cell: (props) => (
            <th {...props} className="!bg-cellBg1 !text-primary">
              {props.children}
            </th>
          ),
        },
      }}
      bordered
      pagination={data.length > paginationThreshold ? paginationConfig : false}
      rowHoverable={false}
      expandable={expandable}
    />
  );
};
