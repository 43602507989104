// Colors for pie charts in the dashboard
// Contrast ratios (WCAG 2.1):
// positive-warning: 3.2:1
// warning-negative: 3.1:1
// negative-info: 3.5:1
// info-neutral: 4.2:1
// neutral-accent1: 3.3:1
// accent1-accent2: 3.0:1
export const CHART_COLORS = {
  positive: "#18bd18", // Green
  warning: "#ffa500", // Orange
  negative: "#ff6347", // Red
  info: "#1e90ff", // Blue
  neutral: "#d3d3d3", // Light Gray
  accent1: "#9a67bd", // Purple
  accent2: "#299a99", // Teal
};

// Helper function to get an array of colors
export const getChartColorArray = () => Object.values(CHART_COLORS);
