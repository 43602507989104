import { WalletIcon } from "assets/icons/icons"; // Importing WalletIcon component from assets/icons
import { useDictionary } from "dictionary/dictionaryContext"; // Importing useDictionary hook from dictionaryContext
import { HandleIcon } from "helpers/handleIcons";
import PropTypes from "prop-types";

// Commissions component definition
export const Commissions = ({ amount }) => {
  const { translate } = useDictionary(); // Using the translate function from useDictionary hook
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    // Container div with styling classes
    <div className="commission-container">
      <HandleIcon
        IconComponent={WalletIcon}
        iconColor={"off"}
        iconSize={"small"}
        state="simple"
      />
      {/* Wallet icon with styling */}
      <p className="text-primary textfield">
        {translate("layout.header.commission")}:
      </p>{" "}
      {/* Translated "commission" label */}
      <p className="text-primary commission-card">
        {formatter.format(amount)}
      </p>{" "}
      {/* Commission amount */}
    </div>
  );
};

Commissions.propTypes = {
  // Custom validator to ensure non-negative amount
  amount: function (props, propName, componentName) {
    if (props[propName] < 0) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Expected a non-negative number.`
      );
    }
  },
};
