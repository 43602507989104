import React from "react";
import {
  ChatIcon,
  ConfirmationIcon,
  DashboardIcon,
  PipelineIcon,
  ProposalIcon,
  HelpCenterIcon,
  SettingsIcon,
} from "assets/icons/icons";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";

// Function to create menu item
const createMenuItem = (key, IconComponent, label, selectedKey) => ({
  key,
  icon: (
    <div>
      <HandleIcon
        IconComponent={IconComponent}
        iconColor={selectedKey === key ? "on" : "off"}
        iconSize={"small"}
        state="simple"
      />
    </div>
  ),
  label,
});

// Sidebar menu items component
export const SidebarMenuItems = ({ selectedKey }) => {
  const { translate } = useDictionary();
  // Function to translate dictionary keys
  return [
    createMenuItem(
      "1",
      DashboardIcon,
      translate("layout.sidebar.dashboard"),
      selectedKey
    ),
    createMenuItem(
      "2",
      PipelineIcon,
      translate("layout.sidebar.pipeline"),
      selectedKey
    ),
    createMenuItem(
      "3",
      ChatIcon,
      translate("layout.sidebar.chat"),
      selectedKey
    ),
    createMenuItem(
      "4",
      ProposalIcon,
      translate("layout.sidebar.proposal"),
      selectedKey
    ),
    createMenuItem(
      "5",
      ConfirmationIcon,
      translate("layout.sidebar.confirmations"),
      selectedKey
    ),
  ];
};

// Sidebar support items component
export const SidebarSupportItems = ({ selectedKey }) => {
  const { translate } = useDictionary();
  return [
    createMenuItem(
      "6",
      HelpCenterIcon,
      translate("layout.sidebar.helpCenter"),
      selectedKey
    ),
    createMenuItem(
      "7",
      SettingsIcon,
      translate("layout.sidebar.settings"),
      selectedKey
    ),
  ];
};
