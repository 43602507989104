import PropTypes from "prop-types";
import { useContext } from "react";
import { Avatar, Layout, Menu, Skeleton } from "antd";
import { SidebarMenuItems, SidebarSupportItems } from "constant/constant";
import Context from "context/context";
import { useDictionary } from "dictionary/dictionaryContext";
import LottieLogo from "./lottieLogo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;

const ROUTE_MAPPINGS = {
  1: "/",
  2: "/pipeline",
  3: "/chat",
  4: "/proposal",
  5: "/confirmations",
  6: "/help-center",
  7: "/settings",
};

export const MySidebar = ({ activeKey }) => {
  const navigate = useNavigate();
  const { user = {}, status } = useSelector((state) => state.auth);
  // Get the active theme from the context
  const { activeTheme, menuCollapsed } = useContext(Context);
  // State to keep track of the selected menu item
  // Get the translate function from the dictionary context
  const { translate } = useDictionary();

  // Handle menu item click
  const handleMainMenuClick = (e) => {
    const route = ROUTE_MAPPINGS[e.key];
    if (route) navigate(route);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const parts = name.split(" ");
    return parts
      .filter(Boolean)
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
  };

  const organisationName = user?.organisation?.organisation_name ?? "";
  const initials = getInitials(organisationName);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={menuCollapsed}
      width={264}
      aria-label={translate("sidebar.label")}
      className="sticky top-0 left-0 h-screen"
    >
      <div className="bg-cardsBackground h-screen flex flex-col justify-between">
        <div>
          {/* Logo */}
          <LottieLogo theme={activeTheme} collapsed={menuCollapsed} />
          {/* Menu primary */}
          <p className="text-primary textfield ml-5 pb-1">
            {translate("layout.sidebar.label")}
          </p>
          {/* Main menu */}
          <Menu
            mode="inline"
            theme={activeTheme}
            className="bg-cardsBackground textfield"
            defaultSelectedKeys={[activeKey]}
            items={SidebarMenuItems({ selectedKey: activeKey })}
            selectedKeys={[activeKey]}
            onClick={handleMainMenuClick}
          />
        </div>
        <div>
          <div>
            {/* Support menu primary */}
            <p className="text-primary textfield ml-5 pb-1">
              {translate("layout.sidebar.support")}
            </p>
            {/* Support menu */}
            <Menu
              mode="inline"
              theme={activeTheme}
              className="bg-cardsBackground textfield"
              defaultSelectedKeys={[activeKey]}
              items={SidebarSupportItems({ selectedKey: activeKey })}
              selectedKeys={[activeKey]}
              onClick={handleMainMenuClick}
            />
          </div>
          {/* Profile section */}
          <div className="flex items-center gap-3 px-5 pt-3 mb-5 border-t">
            <Avatar
              className="cursor-pointer card-primary bg-background border border-cardsStroke text-primary h-10 w-10"
              role="button"
              aria-haspopup="true"
              tabIndex={0}
            >
              {initials}
            </Avatar>
            {!menuCollapsed && (
              <div className="text-primary sub-heading">
                {status === "loading" ? (
                  <Skeleton.Input active size="small" style={{ width: 120 }} />
                ) : (
                  <p>{user?.organisation?.organisation_name}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Sider>
  );
};

MySidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  activeKey: PropTypes.string.isRequired,
};
