import PropTypes from "prop-types";

export const Hashtag = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 5 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Hashtag icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M2.39159 4.73672H1.82234L1.29528 6.31341H0.522406L1.04947 4.73672H0.230469L0.353656 4.03359H1.28009L1.62209 3.01828H0.807031L0.934156 2.31459H1.85666L2.39103 0.714844H3.16053L2.62616 2.31459H3.19878L3.73316 0.714844H4.50603L3.97166 2.31459H4.82103L4.69841 3.01772H3.74103L3.39903 4.03303H4.24109L4.11791 4.73672H3.16447L2.64134 6.31341H1.86847L2.39159 4.73672ZM2.05297 4.03359H2.62222L2.96816 3.01828H2.39497L2.05297 4.03359Z" 
        fill={color}
      />
    </svg>
  );
};

Hashtag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
