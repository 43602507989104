import PropTypes from "prop-types";

/**
 * Search icon component
 * @param {Object} props
 * @param {string} props.color - CSS class name for icon color (e.g., 'text-primary', 'text-secondary')
 * @param {number} props.size - Icon size in pixels
 */

export const SearchIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Search icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1652 13.1544C10.9752 14.1258 9.45534 14.7084 7.79938 14.7084C3.98378 14.7084 0.890625 11.6152 0.890625 7.79953C0.890625 3.98385 3.98378 0.890625 7.79938 0.890625C11.615 0.890625 14.7081 3.98385 14.7081 7.79953C14.7081 9.45499 14.1259 10.9745 13.1551 12.1643L14.9479 13.9577C15.2212 14.2311 15.2212 14.6743 14.9478 14.9476C14.6744 15.221 14.2311 15.2209 13.9578 14.9475L12.1652 13.1544ZM13.3081 7.79953C13.3081 10.842 10.8418 13.3084 7.79938 13.3084C4.75701 13.3084 2.29062 10.842 2.29062 7.79953C2.29062 4.75702 4.75701 2.29062 7.79938 2.29062C10.8418 2.29062 13.3081 4.75702 13.3081 7.79953Z"
        className={color}
      />
    </svg>
  );
};

SearchIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number.isRequired,
};
