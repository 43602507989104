import PropTypes from "prop-types";

export const StopsFilled = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Stops icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M6.92447 3.42317C6.92447 5.03862 4.5013 6.92331 4.5013 6.92331C4.5013 6.92331 2.07812 5.03862 2.07812 3.42317C2.07812 2.10335 3.18148 1 4.5013 1C5.82112 1 6.92447 2.10335 6.92447 3.42317Z" 
        fill={color} 
        stroke={color} 
        stroke-width="0.586348" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path d="M6.69553 6.1155H7.19255L8.00028 8.00019H1L1.80772 6.1155H2.30474M4.50014 4.23081C4.71436 4.23081 4.91981 4.14571 5.07129 3.99423C5.22276 3.84275 5.30786 3.6373 5.30786 3.42308C5.30786 3.20886 5.22276 3.00341 5.07129 2.85193C4.91981 2.70046 4.71436 2.61536 4.50014 2.61536C4.28592 2.61536 4.08047 2.70046 3.92899 2.85193C3.77751 3.00341 3.69242 3.20886 3.69242 3.42308C3.69242 3.6373 3.77751 3.84275 3.92899 3.99423C4.08047 4.14571 4.28592 4.23081 4.50014 4.23081Z" 
        stroke={color} 
        stroke-width="0.586348" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  );
};

StopsFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
