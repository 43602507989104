import { memo } from "react";
import { Card } from "antd";
import { graph_up } from "assets/icons/icons";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";
import PropTypes from "prop-types";

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.title === nextProps.title &&
    prevProps.counts === nextProps.counts &&
    prevProps.stats === nextProps.stats &&
    prevProps.Icon === nextProps.Icon
  );
};

export const StatisticsCard = memo(({ Icon, title, counts, stats }) => {
  const { translate } = useDictionary();
  return (
    <Card
      hoverable
      style={{ height: 124 }}
      className="bg-cardsBackground stats rounded-2xl border border-cardsStroke flex flex-col justify-between"
      component="section"
      aria-labelledby="stats-card-title"
    >
      <div className="flex justify-between">
        <p className="text-cardsHeaders card-primary">{title}</p>
        <HandleIcon
          IconComponent={Icon}
          iconColor={"on"}
          iconSize={"medium"}
          state="simple"
          hoverable
          layout
        />
      </div>
      <div className="relative -top-3">
        <span className="text-cardsCaption2 card-number">{counts}</span>
        <div className="flex items-center gap-1 textfield">
          <img
            src={graph_up}
            alt={`${
              stats > 0 ? "Upward" : "Downward"
            } trending graph showing ${stats}% change`}
          />
          <p className="text-cardsCaption2">
            <span className="text-cardsCaption1">{stats}</span>{" "}
            {translate("dashboard.cards.lastMonth")}
          </p>
        </div>
      </div>
    </Card>
  );
}, arePropsEqual);

StatisticsCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  counts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  stats: PropTypes.string.isRequired,
};
