import {
  logo_dark,
  logo_dark_collapsed,
  logo_light,
  logo_light_collapsed,
} from "assets/images/images";
import { lazy } from "react";

const Lottie = lazy(() => import("lottie-react"));

/**
 * LottieLogo Component
 * 
 * Renders a Lottie animation logo that changes based on the theme and collapsed state.
 * 
 * @param {Object} props
 * @param {'light'|'dark'} props.theme - The current theme
 * @param {boolean} props.collapsed - Whether the logo should be in a collapsed state
 * @returns {React.Component} A Lottie animation of the logo
 */
const LottieLogo = ({ theme, collapsed }) => {
  const logoData = theme === 'dark' ? 
    (collapsed ? logo_dark_collapsed : logo_dark) :
    (collapsed ? logo_light_collapsed : logo_light);

  return (
    <Lottie
      className={`${theme === 'dark' ? 'hidden dark:block' : 'dark:hidden'} my-4`}
      style={{ width: "auto", height: 70 }}
      animationData={logoData.animationData}
      loop={logoData.loop}
      autoplay={logoData.autoplay}
    />
  );
};

export default LottieLogo;