/**
 * Menu keys used for navigation and active menu item tracking
 * @readonly
 * @enum {string}
 */

export const MENU_KEYS = Object.freeze({
  DASHBOARD: "1",
  PIPELINE: "2",
  CHAT: "3",
  PROPOSAL: "4",
  CONFIRMATIONS: "5",
  HELP_CENTER: "6",
  SETTINGS: "7",
});
