import PropTypes from "prop-types";

/**
 * ContractEditIcon component renders an SVG icon for contract editing functionality
 * @param {Object} props - Component props
 * @param {string} [props.color="currentColor"] - The color of the icon
 * @param {number} [props.size=24] - The size of the icon in pixels
 * @returns {JSX.Element} A contract edit icon SVG
 */

export const ContractEditIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Contract Edit"
      role="img"
    >
      <path
        d="M5.375 6.375V4.625H13.25V6.375H5.375ZM5.375 9V7.25H13.25V9H5.375ZM8 17.75H2.75C2.02083 17.75 1.40104 17.4948 0.890625 16.9844C0.380208 16.474 0.125 15.8542 0.125 15.125V12.5H2.75V0.25H15.875V8.14688C15.5833 8.11771 15.288 8.12865 14.9891 8.17969C14.6901 8.23073 14.4021 8.32187 14.125 8.45312V2H4.5V12.5H9.75L8 14.25H1.875V15.125C1.875 15.3729 1.95885 15.5807 2.12656 15.7484C2.29427 15.9161 2.50208 16 2.75 16H8V17.75ZM9.75 17.75V15.0594L14.5844 10.2469C14.7156 10.1156 14.8615 10.0208 15.0219 9.9625C15.1823 9.90417 15.3427 9.875 15.5031 9.875C15.6781 9.875 15.8458 9.90781 16.0062 9.97344C16.1667 10.0391 16.3125 10.1375 16.4438 10.2688L17.2531 11.0781C17.3698 11.2094 17.4609 11.3552 17.5266 11.5156C17.5922 11.676 17.625 11.8365 17.625 11.9969C17.625 12.1573 17.5958 12.3214 17.5375 12.4891C17.4792 12.6568 17.3844 12.8062 17.2531 12.9375L12.4406 17.75H9.75ZM11.0625 16.4375H11.8938L14.5406 13.7688L14.1469 13.3531L13.7312 12.9594L11.0625 15.6063V16.4375ZM14.1469 13.3531L13.7312 12.9594L14.5406 13.7688L14.1469 13.3531Z"
        className={color}
      />
    </svg>
  );
};

ContractEditIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number.isRequired,
};
