import { Dropdown } from "antd";
import {
  AgentIcon,
  delete_icon,
  EditIcon,
  MenuHorizontal,
  MoveIcon,
} from "assets/icons/icons";
import { useDictionary } from "dictionary/dictionaryContext";
import { HandleIcon } from "helpers/handleIcons";

export const PipelineLeadDropdown = () => {
  const { translate } = useDictionary();
  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2 py-1">
          <HandleIcon
            IconComponent={MoveIcon}
            iconColor="on"
            iconSize="small"
            state="simple"
          />
          <p className="textfield text-iconsActive">
            {translate("dropdowns.pipelineLead.move")}
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2 py-1">
          <HandleIcon
            IconComponent={AgentIcon}
            iconColor="off"
            iconSize="small"
            state="simple"
          />
          <p className="textfield text-iconsDefault">
            {translate("dropdowns.pipelineLead.changeAgent")}
          </p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-2 py-1">
          <HandleIcon
            IconComponent={EditIcon}
            iconColor="off"
            iconSize="small"
            state="simple"
          />
          <p className="textfield text-iconsDefault">
            {translate("dropdowns.pipelineLead.edit")}
          </p>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="flex items-center gap-2 py-1">
          <img src={delete_icon} alt="delete" />
          <p className="textfield text-cardsDanger">
            {translate("dropdowns.pipelineLead.delete")}
          </p>
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomLeft"
    >
      <div>
        <HandleIcon
          IconComponent={MenuHorizontal}
          iconColor="off"
          iconSize="small"
          state="simple"
        />
      </div>
    </Dropdown>
  );
};
