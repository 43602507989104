import PropTypes from "prop-types";

/**
 * Close icon component
 * @param {Object} props - Component props
 * @param {string} [props.color="currentColor"] - Icon color
 * @param {number} [props.size=24] - Icon size in pixels
 * @returns {JSX.Element} Close icon SVG
 */

export const CloseIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Close"
    >
      <title>Close</title>
      <path
        d="M1.4 10.0004L0 8.60039L3.6 5.00039L0 1.42539L1.4 0.0253906L5 3.62539L8.575 0.0253906L9.975 1.42539L6.375 5.00039L9.975 8.60039L8.575 10.0004L5 6.40039L1.4 10.0004Z"
        className={color}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(["currentColor"]),
  ]),
  size: PropTypes.number.isRequired,
};

CloseIcon.defaultProps = {
  color: "currentColor",
};
