/**
 * Handles drag and drop movement of items within and between containers
 * @param {Object} event - The drag event containing active and over elements
 * @param {Function} findValueOfItems - Function to find container by item/container id
 * @param {Array} containers - Array of containers with their items
 * @param {Function} arrayMove - Function to reorder array elements
 * @param {Function} setContainers - State setter for containers
 */

export const handleDragMove = (
  event,
  findValueOfItems,
  containers,
  arrayMove,
  setContainers
) => {
  const { active, over } = event;

  const isValidContainerDrop = (active, over) => {
    return (
      active.id.toString().includes("item") &&
      over?.id.toString().includes("container") &&
      active &&
      over &&
      active.id !== over.id
    );
  };

  // Handle Items Sorting
  if (isValidContainerDrop(active, over)) {
    // Find the active container and over container
    const activeContainer = findValueOfItems(active.id, "item");
    const overContainer = findValueOfItems(over.id, "item");

    // If the active or over container is not found, return
    if (!activeContainer || !overContainer) return;

    // Find the index of the active and over container
    const activeContainerIndex = containers.findIndex(
      (container) => container.id === activeContainer.id
    );
    const overContainerIndex = containers.findIndex(
      (container) => container.id === overContainer.id
    );

    // Find the index of the active and over item
    const activeItemIndex = activeContainer.items.findIndex(
      (item) => item.id === active.id
    );
    const overItemIndex = overContainer.items.findIndex(
      (item) => item.id === over.id
    );
    // In the same container
    if (activeContainerIndex === overContainerIndex) {
      const newItems = containers.map((container, idx) => {
        if (idx !== activeContainerIndex) return container;
        return {
          ...container,
          items: arrayMove(container.items, activeItemIndex, overItemIndex),
        };
      });

      setContainers(newItems);
    } else {
      // In different containers
      let newItems = [...containers];
      const [removeditem] = newItems[activeContainerIndex].items.splice(
        activeItemIndex,
        1
      );
      newItems[overContainerIndex].items.splice(overItemIndex, 0, removeditem);
      setContainers(newItems);
    }
  }

  // Handling Item Drop Into a Container
  if (isValidContainerDrop(active, over)) {
    // Find the active and over container
    const activeContainer = findValueOfItems(active.id, "item");
    const overContainer = findValueOfItems(over.id, "container");

    // If the active or over container is not found, return
    if (!activeContainer || !overContainer) return;

    // Find the index of the active and over container
    const activeContainerIndex = containers.findIndex(
      (container) => container.id === activeContainer.id
    );
    const overContainerIndex = containers.findIndex(
      (container) => container.id === overContainer.id
    );

    // Find the index of the active and over item
    const activeitemIndex = activeContainer.items.findIndex(
      (item) => item.id === active.id
    );

    // Remove the active item from the active container and add it to the over container
    const removedItem = containers[activeContainerIndex].items[activeitemIndex];
    const newItems = containers.map((container, idx) => {
      if (idx === activeContainerIndex) {
        return {
          ...container,
          items: container.items.filter((_, i) => i !== activeitemIndex),
        };
      }
      if (idx === overContainerIndex) {
        return {
          ...container,
          items: [...container.items, removedItem],
        };
      }
      return container;
    });
    setContainers(newItems);
  }
};
