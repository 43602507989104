import { MyLayout } from "components/components";
import { useDictionary } from "dictionary/dictionaryContext";
import { useSelector } from "react-redux";
import { Header } from "./components/header";
import { PersonalInformation } from "./components/personalInformation";
import { Address } from "./components/address";

export const Profile = () => {
  const { translate } = useDictionary();
  const { status } = useSelector((state) => state.auth);
  return (
    <MyLayout
      title={translate("profile.main.title")}
      isLoading={status === "loading"}
    >
      <Header />
      <PersonalInformation />
      <Address />
    </MyLayout>
  );
};
