/**
 * Filters an array of leads based on search criteria
 * @param {Array<Object>} leads - Array of lead objects to filter
 * @param {Object} filters - Filter criteria
 * @param {string} filters.search - Search term for surname or email
 * @param {string} [filters.destination] - Destination country filter
 * @param {string} [filters.agent] - Booking agent filter
 * @returns {Array<Object>} Filtered array of leads
 */

export const filterLeads = (leads, filters) => {
  if (!Array.isArray(leads)) {
    return [];
  }

  const { search = "", destination = "", agent = "" } = filters || {};

  // Convert filter terms to lowercase once, outside the loop
  const searchLower = search.toLowerCase();
  const destinationLower = destination.toLowerCase();
  const agentLower = agent.toLowerCase();

  return leads.filter((lead) => {
    if (!lead || typeof lead !== "object") {
      return false;
    }

    // Cache lowercase values to avoid repeated conversions
    const surname = lead.surname?.toLowerCase() || "";
    const email = lead.email?.toLowerCase() || "";
    const leadDestination = (lead.destination_country || "").toLowerCase();
    const leadAgent = (lead.booking_agent || "").toLowerCase();

    const matchesSurnameOrEmail =
      surname.includes(searchLower) || email.includes(searchLower);

    const matchesDestination = filters.destination
      ? leadDestination.includes(destinationLower)
      : true; // No destination selected, so include all

    const matchesAgent = filters.agent ? leadAgent.includes(agentLower) : true; // No agent selected, so include all

    return matchesSurnameOrEmail && matchesDestination && matchesAgent;
  });
};
