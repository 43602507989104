import PropTypes from "prop-types";

export const Airplane = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Airplane icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M9.74066 0.255057C10.0864 0.600997 10.0864 1.15802 9.74066 1.49809L7.46081 3.77895L8.7033 9.1674L7.87692 10L5.60293 5.64351L3.31722 7.93022L3.52821 9.37848L2.9011 10L1.8696 8.13544L0 7.09762L0.621245 6.46438L2.08645 6.68132L4.35458 4.4122L0 2.11961L0.832235 1.29288L6.21832 2.53591L8.49817 0.255057C8.82637 -0.085019 9.41245 -0.085019 9.74066 0.255057Z" 
        fill={color}
      />
    </svg>
  );
};

Airplane.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
