import React from "react";
import { message, Upload } from "antd";
import { useDictionary } from "dictionary/dictionaryContext";
import { ThemeButton } from "components/components";

const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const UploadButton = () => {
  const { translate } = useDictionary();
  return (
    <Upload {...props}>
      <ThemeButton type="simple">
        {translate("profile.main.upload")}
      </ThemeButton>
    </Upload>
  );
};
