import PropTypes from "prop-types";

export const FlightDetailsArrow = ({ color = 'currentColor'}) => {
  return (
    <svg
      width="6"
      height="53" 
      viewBox="0 0 6 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Flight Details Arrow icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <circle cx="3" cy="2" r="2" fill={color}/>
      <path d="M3 53L5.02073 49.5L0.979273 49.5L3 53ZM2.65 7L2.65 7.95833L3.35 7.95833L3.35 7L2.65 7ZM2.65 9.875L2.65 11.7917L3.35 11.7917L3.35 9.875L2.65 9.875ZM2.65 13.7083L2.65 15.625L3.35 15.625L3.35 13.7083L2.65 13.7083ZM2.65 17.5417L2.65 19.4583L3.35 19.4583L3.35 17.5417L2.65 17.5417ZM2.65 21.375L2.65 23.2917L3.35 23.2917L3.35 21.375L2.65 21.375ZM2.65 25.2083L2.65 27.125L3.35 27.125L3.35 25.2083L2.65 25.2083ZM2.65 29.0417L2.65 30.9583L3.35 30.9583L3.35 29.0417L2.65 29.0417ZM2.65 32.875L2.65 34.7917L3.35 34.7917L3.35 32.875L2.65 32.875ZM2.65 36.7083L2.65 38.625L3.35 38.625L3.35 36.7083L2.65 36.7083ZM2.65 40.5417L2.65 42.4583L3.35 42.4583L3.35 40.5417L2.65 40.5417ZM2.65 44.375L2.65 46.2917L3.35 46.2917L3.35 44.375L2.65 44.375ZM2.65 48.2083L2.65 50.125L3.35 50.125L3.35 48.2083L2.65 48.2083Z" 
        fill={color}
      />

    </svg>
  );
};

FlightDetailsArrow.propTypes = {
  color: PropTypes.string,
};
