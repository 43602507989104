import { TASKS } from "helpers/endpoints";
import api from "../../app/api"; // Axios instance

const isValidTask = (task) => {
  if (!task || typeof task !== "object") return false;
  if (!task.task_id || !task.task_name || !task.task_due_date) return false;
  const date = new Date(task.task_due_date);
  return !Number.isNaN(date.getTime());
};

// Function to get tasks
export const getTasks = async () => {
  try {
    const response = await api.get(TASKS.GET_LIST);
    const data = response.data; // Extract tasks from the response
    if (!Array.isArray(data)) throw new Error("Tasks data must be an array");
    if (!data.every(isValidTask)) {
      throw new Error("Invalid task structure or date format in response");
    }
    return data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(
        `API error: ${error.response.status} - ${
          error.response.data.message || "Unknown error"
        }`
      );
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error(`Network error: No response received from server`);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};
