import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { PlusIcon } from "assets/icons/icons";
import { HandleIcon } from "helpers/handleIcons";
import { UnassignedActions } from "../helpers/unassignedActions";
import { PipelineStageDropdown } from "components/components";

export const KanbanContainer = ({
  id,
  children,
  title,
  onAddItem,
  count,
  budget,
  color,
}) => {
  const { attributes, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: id,
      data: {
        type: "container",
        // Example additional metadata:
        position: title,
        allowedMoves: ["forward", "backward"],
      },
    });
  return (
    <div
      {...attributes}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      className={clsx(
        "w-full h-full px-2 mb-4 bg-transparent flex flex-col gap-y-4 transition-opacity duration-200",
        isDragging && "opacity-50"
      )}
    >
      <div
        className="flex items-center justify-between w-[350px] pb-4"
        style={{
          borderBottom: `4px solid ${color}`,
        }}
      >
        <div className="flex items-center gap-4">
          <p className="text-primary card-caption-medium">{title}</p>
          <div className="text-cardsHeaders border-cardsHeaders border card-caption rounded-lg w-7 h-7 flex items-center justify-center">
            {count}
          </div>
          <p className="text-primary card-caption-medium">${budget}</p>
        </div>
        <div className="flex items-center gap-4">
          <HandleIcon
            IconComponent={PlusIcon}
            iconColor="fill-ctaBg"
            iconSize="small"
            state="simple"
            onClick={() => {
              /* Add your handler */
            }}
            aria-label="Add new item"
            role="button"
          />
          <PipelineStageDropdown />
        </div>
      </div>
      {children}
      {title === "Unassigned" && <UnassignedActions onAdd={onAddItem} />}
    </div>
  );
};

KanbanContainer.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onAddItem: PropTypes.func,
  count: PropTypes.number.isRequired,
  budget: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
