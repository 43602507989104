import PropTypes from "prop-types";

export const MoveIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Move"
    >
      <title>Move</title>
      <path
        d="M0.458008 14.3333V4.83333H9.30488L6.45488 2.00312L7.58301 0.875L12.333 5.625L7.56322 10.3948L6.45488 9.26667L9.30488 6.41667H2.04134V14.3333H0.458008Z"
        className={color}
      />
    </svg>
  );
};

MoveIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
