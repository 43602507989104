import PropTypes from "prop-types";

export const Diamond = ({ color = 'currentColor', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Diamond icon"
      aria-hidden={true}
      style={{display: 'block'}}
    >
      <path d="M1.25891 2.57144H7.99109M1.05532 2.76904L4.41737 7.12975C4.4418 7.1616 4.47324 7.1874 4.50924 7.20516C4.54525 7.22292 4.58485 7.23216 4.625 7.23216C4.66515 7.23216 4.70475 7.22292 4.74076 7.20516C4.77676 7.1874 4.8082 7.1616 4.83263 7.12975L8.19468 2.76904C8.22773 2.726 8.24691 2.67392 8.24966 2.61972C8.2524 2.56552 8.23859 2.51176 8.21005 2.4656L7.06963 0.624772C7.04615 0.586677 7.01332 0.555216 6.97426 0.533378C6.9352 0.51154 6.89121 0.50005 6.84646 0.5H2.40354C2.35879 0.50005 2.3148 0.51154 2.27574 0.533378C2.23668 0.555216 2.20385 0.586677 2.18037 0.624772L1.03995 2.4656C1.01141 2.51176 0.997596 2.56552 1.00034 2.61972C1.00309 2.67392 1.02226 2.726 1.05532 2.76904Z" 
        stroke={color} 
        stroke-width="0.517861" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  );
};

Diamond.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
